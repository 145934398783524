import {React, useState, useEffect} from 'react';
import { Redirect, useLocation} from 'react-router';
import { Formik, Form, Field } from 'formik';
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import {currentURL} from '../../Config';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {useSignInWithEmailAndPassword} from '../../hooks/Firebase';
import Loading from '../Loading';
import {validateLoginData} from '../../validators/validator';
import './Login.css';
import cateratLogo from '../../assets/caterat_logo.svg';
import {ErrorText} from '../Error';
import {Footer} from '../home/Home';

function Login(){
  const history = useHistory();
  const [
      signInWithEmailAndPassword,
      user,
      loading,
      error,
    ] = useSignInWithEmailAndPassword();

  return (
      <LoginForm signInWithEmailAndPassword={signInWithEmailAndPassword} error={error}/> 
  );
}

const LoginForm = ({ signInWithEmailAndPassword}) => {
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const history = useHistory();
  const directToSignUp = () => {
    history.push('/signUp');
  }
  const directToResetPassword = () => {
    history.push({pathname: '/resetPassword', state: {actionType: "reset"}});
  }
  const directToResendVerification = () => {
    history.push({pathname: '/resendVerification', state: {actionType: "resend"}});
  }
  return (
    <div className="app-login c:row">
        <header className="login">
            <img className="company-logo" src={cateratLogo} alt="Logo" />
        </header>
        <div className="login-body">
            <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validate={(values)=>{
              
              const [valid, localError] = validateLoginData(values);
              return {...localError};
            }}
            onSubmit={async (values, {setSubmitting, setErrors}) => {
              setSubmitting(true);
              const [user, error] = await signInWithEmailAndPassword(values.email, values.password);
              if(error){
                // console.log(error);
                switch(error.code){
                  case 'auth/user-not-found':
                    setErrors(({email: "找不到此用戶"}));
                    break;
                  case 'auth/email-already-in-use':
                    setErrors(({email: "電郵已用於其他帳戶"}));
                    break;
                  case "auth/invalid-email":
                    setErrors(({email: "無效電郵"}));
                    break;
                  case "auth/invalid-password":
                    setErrors({password: "無效密碼"});
                    break;
                  case "auth/wrong-password":
                    setErrors({password: "無效密碼"});
                    break;
                  case "email-not-verified":
                    setErrors({email: "請前往電郵信箱查閱已發送的驗證碼以激活帳戶"});
                    setEmailNotVerified(true);
                  default: 
                    setErrors({password: error.message});
                } 
                setSubmitting(false);
              }
              if(user){
                const res = await axios.post(currentURL+'/checkIsAdmin', {},{headers: { Authorization: `Bearer ${user.idToken}`}});

                if(res.data.isAdmin){
                  setSubmitting(false);
                  history.push({pathname: "/userList"});
                }else{
                  setSubmitting(false);
                  history.push({pathname: "/home"});
                }
              }
            }}
            >
              {({errors, touched, dirty, isValid, handleChange, handleBlur, submitForm, isSubmitting})=>(
                <Form>
                <div className="login-card c:row">
                  <div className="c:col flex:1">
                    <div className="login-card-left">
                      <h3 className="login-card-title">
                        登入
                      </h3>
                      
                      <input 
                        className="login-input-field"
                        type="text"
                        id="email" 
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="電郵"
                      />
                      <div className="login-input-error">
                        {touched.email && errors.email && <ErrorText msg={errors.email}/>}
                      </div>
                        
                      <input 
                          className="login-input-field"
                          id="password" 
                          name="password"
                          type="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="密碼"
                        />
                      <div className="login-input-error">
                      {touched.password && errors.password && <ErrorText msg={errors.password}/>}
                      </div>
                      <div className="link-wrap">
                        <div className="login-card-link" onClick={directToResetPassword}>忘記密碼</div>
                        {emailNotVerified&&" | "}
                        {emailNotVerified&&<div className="login-card-link" onClick={directToResendVerification}>重新發送驗證碼</div>}
                      </div>
                        
                      <button
                      type="button"
                      className="login-btn"
                      disabled={isSubmitting}
                      onClick = {submitForm}
                      >
                        登入
                      </button>
                        {/* <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting||!isValid||!dirty}
                          onClick = {submitForm}
                        >登入</Button> */}
                      {/* <Link to="/signUp">
                        <h6 className="register-link touch">還沒有帳號？ 立即註冊！</h6>
                      </Link> */}

                      
                    </div>
                    <div className="login-card-right">
                        <h1>加入Caterat！</h1>
                        <h6>立即開展你的餐廳業務</h6>
                        <button onClick={directToSignUp} className="signup-btn" type="button">註冊</button>
                    </div>
                  </div>
                </div>
                </Form>
                )}
            </Formik>
        </div>
        <Footer myClass="login" />
        {/* <footer className="login">
              
            <span>Support</span>
            <span>Terms</span>
            <span>Privacy</span>
        </footer> */}
    </div>
  );
}



export default Login;