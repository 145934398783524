import React, {useState, useCallback, useEffect, useContext} from 'react';
import { Redirect } from 'react-router';
import { useHistory, useLocation, Link, useParams } from "react-router-dom";
import axios from 'axios';
import { v4 as uuidv4} from 'uuid';
import './Home.css';
import {currentURL} from '../../Config';
// import CreatProj from '../creatProj/CreateProj';
import useOverlay, {OverlayContext} from '../../hooks/useOverlay';
import Loading from '../Loading';
import CreateRestaurant from '../createRestaurant/CreateRestaurant';
import RestaurantCard from '../restaurantCard.js/RestaurantCard';
import { FirebaseUserContext, TargetUserContext, getIdToken} from '../../hooks/Firebase';
import {signOut} from '../../hooks/Firebase';
import cateratLogo from '../../assets/caterat_logo.svg';


function Home({isUpdated, toggleIsUpdated}){

  //use history as a navigation base
  const history = useHistory();

  //store user login status 
  const userStatus = useContext(FirebaseUserContext);

  const user = userStatus.user;
  const [isLoading, setIsLoading] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const location = useLocation();
  const [isOverlayOpen, toggleOverlay] = useOverlay();

  // To make sure the home page is at the right scale after login when using mobile phone
  // Because mobile will zoom the input field e.g. password field, and change the scale
  document.addEventListener('touchmove', function (event) {
    if (event.scale !== 1) { event.preventDefault(); }
  }, false);

  useEffect(() => {
    const viewportmeta = document.getElementById('myViewPort');
    viewportmeta.setAttribute('content', "width=1000, initial-scale=0.3, maximum-scale=0.5");
  },[]);

  // Load all the admin's restaurants
  useEffect(async () => {
    if (userStatus.loggedIn) {
      setIsLoading(true);
      const res = await axios.post(currentURL+'/getAdminRestaurants', {uid: user.uid}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
      setRestaurants(res.data);
      setIsLoading(false);
    }
  }, [isUpdated, userStatus]);

  //delete the restaurant in local array
  const deleteRestaurantInLocal = useCallback((restaurantId)=>setRestaurants(origin=>[...origin].filter(obj=>obj.restaurantId!==restaurantId)),[]);
  
  if (!userStatus.loggedIn||!restaurants) {
      return(
        <Loading/>
      );
  }
  
  const createRestaurant = ()=>{
    history.push({
      pathname: `/home/createRestaurant`,
      state: {background: location}
    });
  };
 return (
  <div>
  <OverlayContext.Provider value={isOverlayOpen}>
    <div className="app-root bg:grey">
      {/* <div className="app-bg-img"/> */}
      <AppBar userStatus={userStatus} setIsLoading={setIsLoading}/>
      <div className="app">
        <div style={{height: "50px"}}></div>
        <div className="app-body bg:trans">
          <div 
          id="create-restaurant-card" 
          onClick={createRestaurant} 
          className="restaurant-card c:row c:center c:middle" 
          style={restaurants.length? {height: "80px", flexBasis:"unset"}:{height: "200px"}}>
              <div className="c:col c:middle">
                <i className="f:2xl material-icons-outlined">
                  add
                </i>
                <h5>
                  創建餐廳
                </h5>
              </div>
          </div>
            {restaurants.map((restaurant, index)=>{
              return (
                <React.Fragment key={`restaurant-card-${index}`}>
                  <RestaurantCard index={index} deleteRestaurantInLocal={deleteRestaurantInLocal} restaurant={restaurant} user={user} isUpdated={isUpdated}/>
                </React.Fragment>
              );
            })} 
            <div style={{height: "20px"}}></div>
        </div>
          <Footer/>
      
      </div>
    </div>
  </OverlayContext.Provider>
  </div>
 );
}

export const AppBar = ({userStatus, setIsLoading}) => {
  const history = useHistory();
  // const { targetEmail } = useParams();
  const directToUserSetting = () => history.push(`/userProfile`);
  const directToUserList = () => history.push(`/userList`);
  const directToContactUs = () => history.push(`/contactUs`);
  const directToHome = () => history.push(`/home`);
  const logout = () => {
    setIsLoading(true);
    setTimeout(()=>{
      setIsLoading(false);
      signOut();
      history.push(`/login`);
    }, 1000);
    // history.push(`/login`);
  };
  return (
    <div className="app-bar c:col c:between">
      <div onClick={directToHome} className="caterat-logo-wrap touch">
          {/* <h4 className="f:light-grey f:600 f:medium">Caterat</h4> */}
          <img className="company-logo" src={cateratLogo} alt="Logo" />
      </div>
      {userStatus?.isAdmin&&<div className="target-email">{userStatus.user.email}</div>}
      <div className="c:col c:middle">
        {userStatus?.isAdmin?
        <div onClick={directToUserList} className="app-bar-btn">
            <i className="f:xl f:white material-icons-outlined">
            account_circle
            </i>
            <span>
              返回帳戶列表
            </span>
        </div>
        :<div onClick={directToUserSetting} className="app-bar-btn">
            <i className="f:xl f:white material-icons-outlined">
            account_circle
            </i>
            <span>
              帳戶設定
            </span>
        </div>}
        <div onClick={directToContactUs} className="app-bar-btn">
            <i className="f:xl f:white material-icons-outlined">
              email
            </i>
            <span>
              支援中心
            </span>
        </div>
        <div onClick={logout} className="app-bar-btn">
            <i className="f:xl f:white material-icons-outlined">
              logout
            </i>
            <span>
              登出
            </span>
        </div>
      </div>
    </div>
  );
}
  
export const Footer = ({myClass}) => {
  const directToPrivacy = () => window.location.href = `/privacy`;

  return (
    <footer className={myClass}>
      {/* <a href="/">Support</a> */}
      <a href="/terms" target="_blank">Terms</a>
      <a href="/privacy" target="_blank">Privacy</a>
      {/* <span>Copyright © 2021, All Right Reserved R Cube App Studio</span> */}
    </footer>
  );
}

export default Home;