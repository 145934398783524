import React, {useCallback, useEffect, useState} from 'react';
import { Route, Switch, useLocation, Redirect} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
// import { faMastercard, faVisa } from '@fortawesome/free-solid-svg-icons'
import './components/root.css';
import './App.css';
import Home from './components/home/Home';
import ContactUs from './components/contactUs/ContactUs';
import Login from './components/login/Login';
import SignUp from './components/signUp/SignUp';
import SendEmail from './components/signUp/SendEmail';
import CreateRestaurant from './components/createRestaurant/CreateRestaurant';
import {FirebaseUserContext, onAuthStateChange} from './hooks/Firebase';
import useWindowsWidth, {widthContext} from './hooks/useWindowWidth';
import ChatModal from './components/contactUs/ChatModal';
import UserProfile from './components/userProfile/UserProfile';
import Privacy from './components/privacy/Privacy';
import Terms from './components/terms/Terms';
import UserList from './components/userList/UserList';
import WebOrderTerms from './components/terms/WebOrderTerms';

library.add(fab);
const theme = createTheme({
  palette: {
    primary: {
      main: '#96d2dc',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#c62828',
      contrastText: '#FFFFFF'
    },
  },
  typography:{
    htmlFontSize: 15,
    button: {
      fontWeight: 600,
    }
  }
});
function App() {
  const [userStatus, setUserStatus] = useState( {loggedIn: false} );
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const toggleIsUpdated = useCallback(() => {setIsUpdated((isUpdated)=>!isUpdated)}, []);
  const isWebView = useWindowsWidth(633);
  const location = useLocation();
  const background = location.state && location.state.background;
  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUserStatus);
    return () => {
      unsubscribe();
    }
  }, []);

  // console.log("re-render");
  return (
    <widthContext.Provider value={isWebView}>

    {/* Share login status using provider */}
    <FirebaseUserContext.Provider value={userStatus}>
    
    <MuiThemeProvider theme={theme}>
      <Switch location={background || location}>
        {/* <Route exact path="/" render={() => <Redirect to="/login" />} /> */}
        <Route exact path="/login">
          <Login setIsFirstLogin={setIsFirstLogin} />
        </Route>
        <Route exact path="/signUp">
          <SignUp/>
        </Route>
        <Route exact path="/userList">
          <UserList setUserStatus={setUserStatus}/>
        </Route>
        <Route exact path="/resetPassword">
          <SendEmail/>
        </Route>
        <Route exact path="/resendVerification">
          <SendEmail/>
        </Route>
        <Route exact path="/home">
          <Home isFirstLogin={isFirstLogin} toggleIsUpdated={toggleIsUpdated} isUpdated={isUpdated}/>
        </Route>
        <Route exact path="/home/:targetEmail">
          <Home isFirstLogin={isFirstLogin} toggleIsUpdated={toggleIsUpdated} isUpdated={isUpdated}/>
        </Route>
        <Route exact path="/contactUs">
          <ContactUs toggleIsUpdated={toggleIsUpdated} isUpdated={isUpdated}/>
        </Route>
        <Route exact path="/userProfile">
          <UserProfile toggleIsUpdated={toggleIsUpdated} isUpdated={isUpdated}/>
        </Route>
        <Route exact path="/privacy">
          <Privacy/>
        </Route>
        <Route exact path="/terms">
          <Terms/>
        </Route>
        <Route exact path="/webOrderTerms">
          <WebOrderTerms/>
        </Route>
        <Route path="/" render={() => <Redirect to="/login" />} />
        
        {/* <Route exact path="/home/createRestaurant">
          <CreateRestaurant/>
        </Route> */}
      </Switch>
      {background && <Route path="/home/createRestaurant/:restaurantId?" children={<CreateRestaurant toggleIsUpdated={toggleIsUpdated} />} />}
      {background && <Route path="/home/restaurantSetting/:restaurantId?" children={<CreateRestaurant toggleIsUpdated={toggleIsUpdated} />} />}
      {background && <Route path="/contactUs/:caseId" children={<ChatModal toggleIsUpdated={toggleIsUpdated}/>}/>}
    </MuiThemeProvider>
    </FirebaseUserContext.Provider>
    </widthContext.Provider>
  );
}

export default App;
