export const isEmpty = (string) => {
    if (/\A\s*\z/.test(string)) return true;
	if (string&&`${string}`.trim() === '') return true;
    if (string === '') return true;
	else return false;
};
export const isEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const isWhiteSpace = (string) => /\s/.test(string);
export const isDigit = (string) => /^\d+$/.test(string);


const emailMaxLen = 40;
const passwordMinLen = 6;
const usernameMinLen = 6;

export const validateLoginData = (data) => {
    let errors = {};

    if (isEmpty(data.email)) errors.email = '請填寫電郵';
    if (isEmpty(data.password)) errors.password = '請填寫密碼';

    if (!isEmail(data.email)) errors.email = '請填寫有效電郵';
    if (isWhiteSpace(data.password)) errors.password = '請不要包含空白鍵';

    if (data.email.length > emailMaxLen) errors.email = '請填寫有效電郵';
    if (data.password.length < passwordMinLen) errors.password = '請填寫至少六個位的密碼';

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
 };

export const validateChangePassword = (data) => {
    let errors = {};

    if (isEmpty(data.newPassword)) errors.newPassword = '請填寫密碼';
    if (isWhiteSpace(data.newPassword)) errors.newPassword = '請不要包含空白鍵';
    if (data.newPassword.length < passwordMinLen) errors.newPassword = '請填寫至少六個位的密碼';

    if (isEmpty(data.confirmNewPassword)) errors.confirmNewPassword = '請填寫確認密碼';
    if (data.newPassword !== data.confirmNewPassword) errors.confirmNewPassword = '確認密碼無效';
    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
};
//  export const validateEmail = (string) => {
//      let error;
//     if (isEmpty(string)) error = 'Email is required';
//     if (!isEmail(string)) error = 'Please enter a valid email';
//     if (isLongerThanMaxLength(string, emailMaxLen)) error = 'Please enter a valid email';

//     return error;
//  };
 export const validateSignUpData = (data) => {
    let errors = {};

    if (isEmpty(data.email)) errors.email = '請填寫電郵';
    if (isEmpty(data.password)) errors.password = '請填寫密碼';
    if (isEmpty(data.companyName)) errors.companyName = '請填寫公司名稱';
    if (isEmpty(data.contactName)) errors.contactName = '請填寫聯絡姓名';
    if (isEmpty(data.contactTel)) errors.contactTel = '請填寫聯絡電話';

    if (!isEmail(data.email)) errors.email = '請填寫有效電郵';
    if (isWhiteSpace(data.password)) errors.password = '請不要包含空白鍵';

    if (data.email.length > emailMaxLen) errors.email = '請填寫有效電郵';
    if (data.password.length < passwordMinLen) errors.password = '請填寫至少六個位的密碼';

    if (isEmpty(data.confirmPassword)) errors.confirmPassword = '請填寫確認密碼';
    if (data.password !== data.confirmPassword) errors.confirmPassword = '確認密碼無效';

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
 };
 export const validateUserInfo = (data) => {
    let errors = {};

    if (isEmpty(data.companyName)) errors.companyName = '請填寫公司名稱';
    if (isEmpty(data.contactName)) errors.contactName = '請填寫聯絡姓名';
    if (isEmpty(data.contactTel)) errors.contactTel = '請填寫聯絡電話';

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
 };
 export const validateStep1 = (data) => {
    let errors = {};

    if (isEmpty(data.restaurantName)) errors.restaurantName = '請填寫餐廳名稱';
    if (isEmpty(data.address)) errors.address = '請填寫餐廳地址';
    if (isEmpty(data.tel)) errors.tel = '請填寫餐廳電話';
    // const numberPattern = new RegExp(/^-?\d*\.?\d*$/);
    // if (numberPattern.test(data.tel)) {
    //     errors.tel = '請填寫有效電話';
    // }
    // if(data.cuisineTags.length === 0) errors.cuisineTags = '請選擇至少一種食品 / 餐廳類型';

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
 }

 export const validateStep2 = (data) => {
    let errors = {};

    if (isEmpty(data.contactEmail)) errors.contactEmail = '請填寫帳單聯絡電郵';
    if (!isEmail(data.contactEmail)) errors.contactEmail = '請填寫有效電郵';

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
 }

 export const validateRestaurantCode = (restaurantCode) => {
    let errors = {};

    if (isEmpty(restaurantCode)) errors.restaurantCode = '請填寫餐廳識別碼';
    // var pattern = new RegExp(/[./ ]/); //unacceptable chars
    const pattern = new RegExp(/[^A-Za-z0-9]+/); //only number and letters
    const spacePattern = new RegExp(/[ ]/);
    if (pattern.test(restaurantCode)) {
        // errors.restaurantCode = 'Special character . / and whitespace are not allowed';
        // errors.restaurantCode = '請不要填寫特殊符號 . / 及 空白鍵';
        errors.restaurantCode = '請填寫英文字母或數字';
    }
    if (spacePattern.test(restaurantCode)) {
        // errors.restaurantCode = 'Special character . / and whitespace are not allowed';
        // errors.restaurantCode = '請不要填寫特殊符號 . / 及 空白鍵';
        errors.restaurantCode = '請不要填寫空白鍵';
    }
    if (restaurantCode.length < 6) {
        // errors.restaurantCode = 'Restaurant code must be at least 6 characters long';
        errors.restaurantCode = '餐廳識別碼需至少6個字';
    }

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
 }

 export const validateContactUsCase = (data) => {
    let errors = {};

    if (data.title&&isEmpty(data.title)) errors.title = '請填寫標題';
    if (data.title&&data.title.length > 80)  errors.title = '標題已達上限';

    if (data.content&&isEmpty(data.content)) errors.content = '請填寫內容';
    if (data.content&&data.content.length > 1500)  errors.content = '內容已達上限';

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
 }


export const validateStep3 = (data) => {
    let errors = {};
    const textPattern = new RegExp(/[./\[\]*` ]/); //unacceptable chars
    const pinPattern = new RegExp(/^\d+$/); //only numbers
    //name
    if (isEmpty(data.name)) errors.name = '請填寫經理姓名';
    if (new RegExp(/[./\[\]*`]/).test(data.name)) {
        errors.name = '請不要填寫特殊符號 . / [ ] * `';
    }
    //username
    if (isEmpty(data.username)) errors.username = '請填寫帳號名';
    if (textPattern.test(data.username)) {
        errors.username = '請不要填寫特殊符號 . / [ ] * ` 及 空白鍵';
    }
    if (checkIsDuplicate(data.managers)) errors.username = "請不要填寫相同帳號名";
    if (data.username.length < 6) errors.username = '請填寫至少六個位的帳號名';
    //pin
    if (isEmpty(data.pin)) errors.pin = '請填寫PIN碼';
    if (!pinPattern.test(data.pin)) {
        errors.pin = 'PIN碼只能包含數字';
    }
    if (!RegExp(/^\d{4}$/).test(data.pin)) errors.pin = '請填寫4個位的PIN碼';
    
    //password
    if (isEmpty(data.password)) errors.password = '請填寫密碼';
    if (isWhiteSpace(data.password)) errors.password = '請不要包含空白鍵';
    if (data.password.length < 6) errors.password = '請填寫至少六個位的密碼';

    //password2
    if (data.password2!==data.password) errors.password2 = '確認密碼無效';

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
}

export const validateManagerAccount = (data) => {
    let errors = {};

    if (isEmpty(data.username)) errors.username = 'Username is required';
    var pattern = new RegExp(/[./\[\]*` ]/); //unacceptable chars
    if (pattern.test(data.username)) {
        errors.username = 'Special character . / [ ] * ` and whitespace are not allowed';
    }
    if (checkIsDuplicate(data.managers)) errors.username = "Username need to be uniqued";
    if (data.username.length < 6) errors.username = 'Username must be at least 6 characters long';

    if (isEmpty(data.password)) errors.password = 'Password is required';
    if (isWhiteSpace(data.password)) errors.password = 'Whitespace is not allowed';
    if (data.password.length < 6) errors.password = 'Password must be at least 6 characters long';

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
}

export const validateEditManagerAccount = (data) => {
    let errors = {};

    if (isEmpty(data.username)) errors.username = 'Username is required';
    var pattern = new RegExp(/[./\[\]*` ]/); //unacceptable chars
    if (pattern.test(data.username)) {
        errors.username = 'Special character . / [ ] * ` and whitespace are not allowed';
    }
    if (checkIsDuplicate(data.managers)) errors.username = "Username need to be uniqued";
    if (data.username === data.origin.username) delete errors.username;
    if (data.username.length < 6) errors.username = 'Username must be at least 6 characters long';

    if (isEmpty(data.pin)) errors.pin = 'Pin is required';
    if (isWhiteSpace(data.pin)) errors.pin = 'Whitespace is not allowed';
    if (data.pin.length !== 4) errors.pin = 'Pin must be 4 characters long';

    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
}

export const validateEditUserAccount = (data) => {
    let errors = {};

    if(data.email){
        if (isEmpty(data.email)) errors.email = '請填寫電郵';
        if (!isEmail(data.email)) errors.email = '請填寫有效電郵';
        if (data.email.length > emailMaxLen) errors.email = '請填寫有效電郵';
    }
    
    if(data.password||data.confirmPassword){
        if (isEmpty(data.password)) errors.password = '請填寫密碼';
        if (isWhiteSpace(data.password)) errors.password = '請不要包含空白鍵';
        if (data.password.length < passwordMinLen) errors.password = '請填寫至少六個位的密碼';

        if (isEmpty(data.confirmPassword)) errors.confirmPassword = '請填寫確認密碼';
        if (data.password !== data.confirmPassword) errors.confirmPassword = '確認密碼無效';
    }
    // console.log(errors);
    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
}

export const validatePassword = (data) => {
    let errors = {};

    if (isEmpty(data.password)) errors.password = '請填寫密碼';
    if (isWhiteSpace(data.password)) errors.password = '請不要包含空白鍵';
    if (data.password.length < passwordMinLen) errors.password = '請填寫至少六個位的密碼';
    
    return [
        Object.keys(errors).length === 0 ? true : false,
        errors
    ];
}


function checkIsDuplicate(array) {
    return new Set(array.map((ele)=>ele.username)).size !== array.length;
}
 