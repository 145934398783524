import {React, useState} from 'react';
import { Redirect, useLocation,  } from 'react-router';
import { Formik, Form, Field } from 'formik';
import axios from "axios";
import { useHistory, Link } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import cateratLogo from '../../assets/caterat_logo.svg';
import {useCreateUserWithEmailAndPassword} from '../../hooks/Firebase';
import Loading from '../Loading';
import {validateSignUpData} from '../../validators/validator';
import './SignUp.css';
import {ErrorText} from '../Error';
import {Footer} from '../home/Home';
import {SuccessText} from '../Success';

function SignUp(){
  const history = useHistory();
  const [
    createUserWithEmailAndPassword,
    registeredUser,
    loading,
    error,
    ] = useCreateUserWithEmailAndPassword();

  // if(loading){
  //     return <Loading/>;
  // }
  // if(registeredUser){
  //     history.push("/login");
  // }

  return (
      <SignUpForm createUserWithEmailAndPassword={createUserWithEmailAndPassword} error={error}/> 
  );
}

const SignUpForm = ({createUserWithEmailAndPassword}) => {
  const history = useHistory();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const directToLogin = () => {
    history.push('/login');
  }
  
  return (
  <div className="app-login c:row">
    <header className="login">
        <img className="company-logo" src={cateratLogo} alt="Logo" />
    </header>
    <div className="login-body">
        <Formik
        initialValues={{
            contactName: '',
            companyName: '',
            contactTel: '',
            email: '',
            password: '',
            confirmPassword: '',
        }}
        validate={(values)=>{
          const [valid, localError] = validateSignUpData(values);
          return {...localError};
        }}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          setIsLoading(true);
          setSubmitting(true);
            const [user, error] = await createUserWithEmailAndPassword(values);
            if(error){
                switch(error.code){
                  case 'auth/email-already-in-use':
                    setErrors(({email: "電郵已用於其他帳戶"}));
                    break;
                  case "auth/invalid-email":
                    setErrors(({email: "無效電郵"}));
                    break;
                  case "auth/invalid-password":
                    setErrors({password: "無效密碼"});
                    break;
                  default: 
                    setErrors({confirmPassword: error.message});
                }  
              setSubmitting(false);
              setIsLoading(false);
            }else{
              setTimeout(()=>{
                  setIsLoading(false);
                  setSubmitting(false);
                  setIsCodeSent(true);
              }, 1000);
            }
        }}
        >
          {({errors, touched, dirty, isValid, handleChange, handleBlur, submitForm, isSubmitting})=>{
            
            {/* console.log(errors); */}
            return(
            
            <Form>
            <div className="login-card c:row">
              <div className="c:col flex:1">
                <div className="login-card-right">
                    <h1>歡迎回來Caterat！</h1>
                    <h6>立即登入查看你的餐廳業績</h6>
                    <button onClick={directToLogin} className="signup-btn" type="button">登入</button>
                </div>
                <div className="login-card-left">
                  <h3 className="login-card-title">
                        註冊
                  </h3>
                  <input 
                    className="login-input-field"
                    type="text"
                    id="contactName" 
                    name="contactName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="聯絡姓名"
                  />
                  <div className="login-input-error">
                    {touched.contactName && errors.contactName && <ErrorText msg={errors.contactName}/>}
                  </div>
                  <input 
                    className="login-input-field"
                    type="text"
                    id="companyName" 
                    name="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="公司名稱"
                  />
                  <div className="login-input-error">
                    {touched.companyName && errors.companyName && <ErrorText msg={errors.companyName}/>}
                  </div>
                  <input 
                    className="login-input-field"
                    type="text"
                    id="contactTel" 
                    name="contactTel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="聯絡電話"
                  />
                  <div className="login-input-error">
                    {touched.contactTel && errors.contactTel && <ErrorText msg={errors.contactTel}/>}
                  </div>
                  <input 
                    className="login-input-field"
                    type="text"
                    id="email" 
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="電郵"
                  />
                  <div className="login-input-error">
                    {touched.email && errors.email && <ErrorText msg={errors.email}/>}
                  </div>
                  <input 
                      className="login-input-field"
                      id="password" 
                      name="password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="密碼"
                    />
                  <div className="login-input-error">
                  {touched.password && errors.password && <ErrorText msg={errors.password}/>}
                  </div>
                  <input 
                      className="login-input-field"
                      id="confirmPassword" 
                      name="confirmPassword"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="確認密碼"
                    />
                  <div className="login-input-error">
                  {touched.confirmPassword && errors.confirmPassword && <ErrorText msg={errors.confirmPassword}/>}
                  {isCodeSent && <SuccessText msg="驗證碼已發送至你的電郵信箱，激活後即可啟用服務"/>}
                  </div>
                  <button
                    type="button"
                    className="register-btn"
                    disabled={isSubmitting}
                    onClick = {submitForm}
                    >
                      {isLoading? <Loading/>:"註冊"}
                      
                    </button>
                </div>
              </div>
            </div>
            {/* <div className="login c:row gap:sm">
                <div>
                  <Field 
                    component={TextField}
                    id="email" 
                    name="email"
                    style={{width: "100%"}}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Enter your email"
                    />
                  {touched.email && errors.email && <ErrorText msg={errors.email}/>}
                </div>
                <div className="w:full">
                  <Field 
                    id="password" 
                    name="password"
                    type="password"
                    style={{width: "100%"}}
                    component={TextField}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Enter your password"
                  />
                  {touched.password && errors.password && <ErrorText msg={errors.password}/>}
                </div>
                <div className="w:full">
                  <Field 
                    id="confirmPassword" 
                    name="confirmPassword"
                    type="password"
                    style={{width: "100%"}}
                    component={TextField}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Confirm your password"
                  />
                  {touched.confirmPassword && errors.confirmPassword && <ErrorText msg={errors.confirmPassword}/>}
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting||!isValid||!dirty}
                  onClick = {submitForm}
                >Sign up</Button>
                <Link to="/login">
                  <h6 className="register-link touch">Already have an account? Sign in</h6>
                </Link>
            </div> */}
            </Form>
            )}}
        </Formik>
    </div>
    <Footer myClass="login" />
  </div>
  );
}



export default SignUp;