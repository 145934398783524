import { useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import date from "date-and-time";
import axios from 'axios';
import { FirebaseUserContext, getIdToken} from '../../hooks/Firebase';
import { currentURL } from '../../Config';
import Loading from '../Loading';
import '../root.css';
import './UserList.css';

function UserList({setUserStatus}){
    const userStatus = useContext(FirebaseUserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState(null);
    const history = useHistory();
    const csURL = process.env.REACT_APP_ENV === "development"? 
                        process.env.REACT_APP_HOST === "local"? "http://localhost:5001/caterat-pos-test/asia-east2/cs":"https://asia-east2-caterat-pos-test.cloudfunctions.net/cs"
                        :process.env.REACT_APP_HOST === "local"? "http://localhost:5001/caterat-pos/asia-east2/cs":"https://asia-east2-caterat-pos.cloudfunctions.net/cs";
    const directToUserById = async ({id, email}) => {
        setIsLoading(true);
        try{
            const res = await axios.patch(csURL + '/admins/claim', {uid: userStatus.realUser.uid, targetAdminId: id});

            if(res.status===200){
                const idToken = await getIdToken();
                setUserStatus(x=>({...x, user: {email: email, uid: id}, idToken}));
                setIsLoading(false);
                history.push({pathname: "/home"});

            }else{
                throw "Somethings wrong";
            }
            // history.push({pathname: `/home/${email}`});
        }catch (error){
            setIsLoading(false);
            alert(error);
        }
    }
    useEffect(async ()=>{
        try{
            const usersResponse = await axios.get(csURL + '/users');
            setUsers(usersResponse.data.users);
        }catch(error){  
            alert(error);
        }
        setIsLoading(false);
    }, []);
    if (!userStatus.loggedIn||isLoading) {
        return(
          <Loading/>
        );
    }
    return (
    <div className="user-list">
        <div className="table-container">
            <table className="user-table">
                <tr className="user-table-header">
                    <th>註冊日期</th>
                    <th>電郵</th>
                    <th>負責人姓名</th>
                    <th>負責人電話</th>
                </tr>
                {users&&users.map((user,index)=>{
                    const createdAt = date.format(new Date(user.createAt._seconds*1000), 'YYYY/MM/DD');
                    return (
                        <tr key={`user-row-${index}`} onClick={()=>directToUserById({id: user.adminId, email: user.email})}>
                            <td>{createdAt}</td>
                            <td>{user.email}</td>
                            <td>{user.contactName?? ""}</td>
                            <td>{user.contactTel?? ""}</td>
                        </tr>
                    );
                })}
            </table>
        </div>
    </div>
    );
}

export default UserList;