import React, {useState, useCallback, useEffect, useContext} from 'react';
import {Field} from 'formik';
import TextField from '@material-ui/core/TextField';
import {useParams, useHistory, useLocation} from "react-router-dom";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import '../root.css';
import './CreateRestaurant.css';
import './BillingDetail.css';
import {ErrorText} from '../Error';
import { isEmpty } from '../../validators/validator';
import {loadStripe} from '@stripe/stripe-js';
import { currentURL } from '../../Config';
import { FirebaseUserContext, getIdToken } from '../../hooks/Firebase';
import ConfirmChangesDialog from './ConfirmChangesDialog';
import Loading from '../Loading';
import date from 'date-and-time';

function fixed(value, precision) {
    return parseFloat(Number.parseFloat(value).toFixed(precision));
}

function BillingDetail ({ mode, isLoadingRestautantData, restaurantData, closeModal}){
    const [billings, setBillings] = useState([]);
    const [upcomings, setUpcomings] = useState([]);
    const [webOrderStatus, setWebOrderStatus] = useState(false);
    const [numberOfWebTakeaway, setNumberOfWebTakeaway] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;
    const {restaurantId} = useParams();
    // console.log(restaurantData.billings);
    // console.log(restaurantData.upcomings);
    const statusMap = {
        "paid" : "已繳費",
        "cancelled": "已取消訂閱",
        "upcoming": "未到期",
    };
    useEffect(async () => {
        try{
            const billingHistoryResponse = await axios.post(currentURL+'/getBillingHistory', {restaurantId: restaurantId}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
            setBillings(billingHistoryResponse.data.billings);
            const upcomingBillingResponse = await axios.post(currentURL+'/getUpcomingBilling', {restaurantId: restaurantId}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
            setUpcomings(upcomingBillingResponse.data);
            const secretResponse = await axios.post(currentURL+'/getWebOrderSecret', {restaurantId: restaurantId}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
            setWebOrderStatus(secretResponse.data.secret.isEnabledWebOrder);
            // setUpcomings(billingHistoryResponse.data.upcomings);

            // if(billingHistoryResponse.data?.upcomings[1]!==''){
            //     const numberOfWebTakeawayResponse = await axios.post(currentURL+'/getNumebrOfWebTakeaway', {restaurantId: restaurantId, unixTimeStamp: billingHistoryResponse.data.upcomings[1].current_period_start}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
            //     setNumberOfWebTakeaway(numberOfWebTakeawayResponse.data.numberOfWebTakeaway);
            // }
            setIsLoading(false);
        }catch(error){
            console.log(error);
        }
    }, [])
    return (
        <div className="billing-detail h:full c:row">
            {isLoading? <Loading/>:<div className="c:row h:full">
                <div className="c:col c:between c:middle">
                    <h3 className="f:black f:400">帳單詳情</h3>
                </div>
                <div className="billing-detail-body flex:1">
                    <table className="billing-table">
                        <tr className="billing-table-header">
                            <th>服務計算週期</th>
                            <th>詳情</th>
                            <th>金額</th>
                            <th>繳費狀態</th>
                            <th>繳費日期</th>
                            <th></th>
                        </tr>
                        {upcomings.upcomingStandard&&<tr>
                            <td>{`${upcomings.upcomingStandard.period_start} - ${upcomings.upcomingStandard.period_end}`}</td>
                            <td>{upcomings.upcomingStandard.description}{upcomings.upcomingStandard.coupon&&` (${upcomings.upcomingStandard.coupon})`}</td>
                            <td>${upcomings.upcomingStandard.discount_amount? fixed(upcomings.upcomingStandard.amount_due - upcomings.upcomingStandard.discount_amount, 1) > 0? fixed(upcomings.upcomingStandard.amount_due - upcomings.upcomingStandard.discount_amount, 1):0:upcomings.upcomingStandard.amount_due}</td>
                            <td>{statusMap["upcoming"]}</td>
                            <td>{upcomings.upcomingStandard.due_date}</td>
                            <td></td>
                        </tr>}
                        {webOrderStatus&&upcomings.upcomingMetered&&<tr>
                            <td>{`${upcomings.upcomingMetered.period_start} - ${upcomings.upcomingMetered.period_end}`}</td>
                            <td>{upcomings.upcomingMetered.description}{` (目前收款次數: ${upcomings.upcomingMetered.current_usage})`}{upcomings.upcomingMetered.coupon&&` (${upcomings.upcomingMetered.coupon})`}{!!upcomings.upcomingMetered.discounted_quota&&` (已豁免${upcomings.upcomingMetered.discounted_quota}次)`}{!!upcomings.upcomingMetered.remaining_quota&&` (尚有${upcomings.upcomingMetered.remaining_quota}次豁免)`}</td>
                            <td>${upcomings.upcomingMetered.discount_amount? fixed(upcomings.upcomingMetered.amount_due - upcomings.upcomingMetered.discount_amount, 1) > 0? fixed(upcomings.upcomingMetered.amount_due - upcomings.upcomingMetered.discount_amount, 1):0:upcomings.upcomingMetered.amount_due}</td>
                            {/* <td>${upcomings.upcomingMetered.amount_due}</td> */}
                            <td>{statusMap["upcoming"]}</td>
                            <td>{upcomings.upcomingMetered.due_date}</td>
                            <td></td>
                        </tr>}
                        {billings.map(billing=>{
                            return (
                                <tr>
                                    <td>{`${billing.period_start} - ${billing.period_end}`}</td>
                                    <td>{billing.description}{billing.coupon&&` (${billing.coupon})`}</td>
                                    <td>${billing.amount_paid}</td>
                                    <td>{statusMap[billing.status]}</td>
                                    <td>{billing.paid_at}</td>
                                    <td><a href={`${billing.hosted_invoice_url}`} target="_blank">收據</a></td>
                                </tr>);
                        })}
                        {/* {
                            billings.map(billing=>{
                                const metered_period_end_str = date.format(date.addDays(new Date(billing.current_period_end * 1000), -1), 'DD/MM/YYYY');
                                const period_start_str = date.format(new Date(billing.current_period_start * 1000), 'DD/MM/YYYY');
                                const period_end_str = date.format(new Date(billing.current_period_end * 1000), 'DD/MM/YYYY');
                                const paidAt = date.format(new Date(billing.created * 1000), 'DD/MM/YYYY');
                                return (
                                    <tr  onClick={()=>{}}>
                                        <td>{`${period_start_str} - ${billing.isMetered? metered_period_end_str:period_end_str}`}</td>
                                        <td>{billing.name}{billing.discount&&` (${billing.discount})`}</td>
                                        <td>${billing.amount/100}</td>
                                        <td>{statusMap[billing.status]}</td>
                                        <td>{billing.status==="cancelled"? "":paidAt}</td>
                                        <td>{billing.status==="cancelled"? "":<a href={`${billing.hosted_invoice_url}`} target="_blank">收據</a>}</td>
                                    </tr>);
                            })
                        } */}
                    </table>
                </div>
                {/* <div className="c:col c:between mt:xs">
                    <Button  
                        className="btn step-btn my-outlined-btn"
                        onClick={closeModal}
                        color="primary"
                        variant="outlined" 
                    >
                    返回
                    </Button>
                    <div/>
                </div> */}
            </div>}
    </div>
    );
}

export default BillingDetail;