import '../privacy/Privacy.css';

function Terms(){
    return (
        <div className="privacy">
            <h1>服務條款</h1>
            <p>
            感謝你使用R Cube App Studio Limited  (以下簡稱 “本公司” 、“我們”) 其下的Caterat POS，本服務條款 (以下簡稱「條款」) 範圍涵蓋我們所提供的服務、用戶端軟體及網站 (以下簡稱「服務」) 之存取及使用。我們的隱私權政策說明我們如何蒐集及使用您的資訊。使用我們的服務，即代表您同意遵循本條款、我們的隱私權政策。
            <br/><br/>如果您是 18 歲以下的未成年人，您必須在您的父母或法定監護人的直接監督下使用服務，您的父母或法定監護人必須閱讀並同意條款並同意為您的行為承擔責任，在您使用服務之前。
            <br/><br/>服務條款如下：

            </p>

            <h2>1. 訂閱條款</h2>
            <p>
            首期付款受理完成後，您的訂閱就會開始生效。除非您取消，否則訂閱會根據服務計劃，每月或每年自動續約，不另行發出續約通知。您授權我們存儲您的付款方式，並依照您的付款方式每月或每年自動收款，至您取消為止。
            <br/><br/>您可以隨時透過帳戶頁面或是聯絡我們來取消您的訂閱計劃，但已收款項不會被退還，而您的服務將會繼續直到該計劃的計費期間結束為止。
            <br/><br/>我們可能在每個年度續約期間變更您的計劃費用，如果費用有任何變動，我們將會通知您並提供取消選項。
            <br/><br/>如果您的付款方式無效，我們可能會暫停您的訂閱。

            </p>

            <h2>2. 啟用網上外賣自取平台</h2>
            <p>每筆成功在本平台進行的線上收款，本公司會收取 HK$ 1.00* 作服務費。
            <br/>另外，信用卡支付平台會向商戶額外收取服務費，詳情請參閱<a href="https://stripe.com/en-hk/pricing" target="_blank">Stripe官方網站</a>。
            <br/><br/><small>*每筆信用卡收費會根據Stripe收費而釐定，而本公司每月份的網上外賣自取平台服務費會於下一個月的第一日收取。</small></p>

            <h2>3. 服務內容</h2>
            <p>
            服務內容受版權及可能存在的其他知識產權保護。除非您獲明確授權，否則不得自行或容許任何其他人士：
                <ol>
                    <li><p>未經我們事先書面同意，銷售、轉載、分發、傳輸、修改、展示、公開演出、製作以本內容為基礎的衍生產品、轉貼、轉播、轉發或以任何方式將內容用作商業、宣傳或公共用途；或</p></li>
                    <li><p>為任何目的在任何其他網站上或網絡電腦環境中使用任何內容；或</p></li>
                    <li><p>對包含可下載軟件的任何內容進行逆向工程；或</p></li>
                    <li><p>以其他方式侵犯在內容中存在的知識產權。</p></li>
                </ol>
            除明文規定外，您對或就服務作出的行動一概不會獲轉讓任何知識產權，亦不會讓您獲特許行使任何知識產權。

            </p>
            <h2>4. 服務使用限制</h2>
            <p>
                使用本公司的服務時，您不能：
                <ol>
                    <li><p>中斷或干擾服務或連接或途經服務的伺服器或其他軟件、硬件或設備；</p></li>
                    <li><p>製造可用病毒或任何其他電腦代碼、檔案、目錄或指定內容以中斷、破壞或限制服務的功能或影響其他用戶；</p></li>
                    <li><p>違反與您使用服務有關的任何法例及法規；</p></li>
                    <li><p>以任何非法用途或不符合任何使用條款的任何方式使用服務；</p></li>
                    <li><p>為任何商業用途及利益使用有關服務，或向任何其他人士或實體轉售或轉讓或出售有關服務，或由任何其他人士或實體使用有關服務或為任何其他人士或實體的利益使用有關服務；</p></li>
                    <li><p>收集或儲存有關服務的其他用戶個人資料；</p></li>
                    <li><p>透過服務複製、翻譯、重新發布、上載、張貼、傳輸或分發可用的任何內容，包括文字、圖像、插圖、聲音片段及視頻片段；或</p></li>
                    <li><p>從服務或任何有關或相關的應用程式、系統或服務就任何資料、源代碼或相關HTML進行複製、修改、逆向工程或摘錄，惟根據適用法例所允許或經我們事先書面同意所進行者除外。</p></li>
                </ol>
                在某些情況下，您可能向服務提供內容，您不能：
                <ol>
                    <li><p>提供您並無權利或侵犯或違反他人權利（包括知識產權）或以其他方式違反法例的任何內容；</p></li>
                    <li><p>提供具淫穢、誹謗、損害名譽、失實陳述、粗俗、歧視或令人反感的任何內容；或</p></li>
                    <li><p>在未經事先書面允許的情況下，張貼含有廣告或任何教唆性質的內容。</p></li>
                </ol>
                如您向服務提供內容，即表示您向我們授出非獨家、永久、可轉讓、可再授權、免版稅、全球性特許權，以使用（包括但不限於接收、儲存、複製、修訂、改編為衍生作品（如翻譯、改編、概要或其他更改）、傳達、發表、演出、展示及分發）有關內容。
                <br/><br/>您確認已明白如我們發現您向服務提供的內容違反任何此等使用條款或與任何此等使用條款造成衝突，我們有全權酌情刪除有關內容。
            </p>
            <h2>5. 第三方供應商</h2>
            <p>
            您確認已明白部分服務（包括內容）可能由第三方供應商（「第三方供應商」）而本公司提供或保存。我們或會移除違反適用法例及／或規例的內容，但此並不表示我們先監察或審查內容方提供服務。
            <br/><br/>由第三方供應商提供可經服務獲取的網站連結（「第三方網站」）可能經服務提供。如您使用任何第三方網站，您將離開服務，該等第三方網站受其服務條款及私隱政策所規管。
            <br/><br/>您與任何第三方供應商的通訊或交易只涉及您與該名第三方供應商，且您同意我們將不會就因有關交易而導致的任何形式損失或損害承擔或負上責任；
            <br/><br/>我們並無審查第三方網站的所有內容，亦不監控任何有關第三方網站或其內容或就其負責。第三方網站的連結並無隱含我們與第三方網站內容聯屬、或贊助、認可或任何其他參與第三方網站內容。我們概不就第三方網站任何內容或違反任何適用法律及／或規例或侵犯可能於第三方網站出現的第三方權利負上及承擔責任。

            </p>
            <h2>6. 連結</h2>
            <p>
            我們不就連結至服務的任何站外網頁或任何其他網站（包括但不限於透過廣告或透過任何搜尋器連結的網站）的內容或準確性負責。
            <br/><br/>服務上出現的某些連結為自動產生，可能冒犯或不適合某些人士。包含任何連結並不表示我們認可所連結的網站或任何與之相關的產品或服務，您須自行承擔使用該等連結的風險。
            <br/><br/>在某些情況下，我們或會收集您向其他網站提供的資料及內容，以於服務顯示及使用。

            </p>
            <h2>7. 使用服務的風險</h2>
            <p>
            您須自行承擔使用服務的風險，且您確認已明白我們不就您獲取服務的權利及使用服務及／或內容而導致的損失或損害負責。您必須評估及承擔與使用任何內容有關的一切風險，包括依賴任何內容的時間性、準確性、完整性或有用性。所有在或經服務提供的資料僅供參考。您應就任何內容自行尋求獨立意見。
            <br/><br/>我們盡力提供資料豐富及實用的服務，但我們不能保證任何內容為及時和完全無誤，或服務或操作服務的伺服器完全沒有病毒或其他有害成分。
            <br/><br/>儘管我們會盡合理努力保存內容，但我們並不承諾為內容提供支援或保養服務。
            <br/><br/>如您因使用服務而導致需要維修或更換財產、材料、設備或數據，我們將不會負責有關費用。
            <br/><br/>在不局限上述條文下，一切服務按其現狀及其供應狀況向您提供，並不包含任何形式（不論明示或暗示）保證，包括但不限於有關可銷性、對特定用途適用性或適合性的保證、不侵犯行為，或使用或依賴服務引致的後果。我們盡量在任何適用法律允許的情況下，免除所有聲明及保證。

            </p>
            <h2>8. 彌償</h2>
            <p>您同意就本公司因服務產生或與之有關或於服務內任何連結而引致的任何及所有索償、損害、責任、費用及開支（包括合理法律及專家費用）向本公司作出彌償、為其抗辯及使其免受損害，費用由您自行承擔，與服務有關的事宜包括但不限於：(i)您使用或他人使用您的電腦使用服務；(ii)您或使用您的電腦的任何人士違反任何使用條款。您同意支付任何及所有費用、損害及開支，包括但不限於任何有關索償、訴訟、法律行動或因有關索償引起的法律程序裁定須支付或以其他方式產生或與之有關或衍生的合理法律收費及費用。</p>
            <h2>9. 免責聲名</h2>
            <p>無論在任何情況下，即使我們已明確通知使用或未能使用服務或服務上的任何連結或任何使用條款的條款可能引起損失或損害（例如但不限於失去收益或失去預期利潤），我們概不會就您的任何損失或損害（例如但不限於有關直接、間接、意外、後果、特殊、刑罰或懲罰性損害的責任）承擔責任。</p>
            <h2>10. 其他條款</h2>
            <p>我們可在未經您同意下隨時轉讓此等條款及條件的權利及責任予任何人士或實體。我們也可委任代理或分包商履行我們的責任。
            <br/><br/>如此等使用條款任何部分被認定為不可強制執行，則不可強制執行的部分將盡可能生效，且如從此等使用條款分割屬適當，則餘下部分將仍然具有十足效力及作用。
            <br/><br/>此等使用條款受香港法例規管。您不可撤銷地接受香港法院專屬司法管轄權管轄。
            <br/><br/>您同意就任何索償、法律行動或要求（包括但不限於因您獲取服務及／或使用內容或違反此等使用條款而合理產生的法律及會計費用）向我們、我們的董事、僱員及代理作出彌償並使他們免受損害。
            </p>
            <h2>11. 服務條款修正</h2>
            <p>服務條款將因應需求隨時進行修正，修正後的條款將刊登於網站上</p>

            <h2>聯繫方式</h2>
            <p>
                如果您對本隱私權政策有任何疑問，請聯繫我們：<br/>
                R Cube App Studio Limited<br/>
                電郵：support@rcubestudio.com.hk<br/>
                電話：+852 5662 9356<br/>

                版本日期：2021年10月９日<br/>
            </p>
        </div>
    );
}

export default Terms;
