import {useHistory} from 'react-router-dom';
import React, {useState, useCallback, useEffect, useContext} from 'react';
import { Route, useParams} from "react-router-dom";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import {
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement,
    CardElement,
    useStripe, 
    useElements,
    Elements
} from '@stripe/react-stripe-js';
import {OverlayContext} from '../../hooks/useOverlay';
import { FirebaseUserContext, getIdToken } from '../../hooks/Firebase';
import {currentURL} from '../../Config';
import {ErrorText} from '../Error';
import '../root.css';
import './CreateRestaurant.css';
import './EditSubscription.css';
import Loading from '../Loading';

function EditCreditCard({closeModal, closeEditCreditCardDialog, upcomingStandard, updatePageInfo, dialogNotification, adminId, loading, restaurantData, subscriptionPlans, selectedPlanId, options, cardStyle}){
    const userStatus = useContext(FirebaseUserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [cardComplete, setCardComplete] = useState(false);
    const [cardInputError, setCardInputError] = useState(false);
    const [changedPlanId, setChangedPlanId] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const cardElement = elements.getElement(CardElement);
    const isCancelled = !!!upcomingStandard;


    const confirm = async () => {
        try{
            await handleUpdateCreditCard();
        }catch(err){
            setErrorMsg(`${err}`);
        }
    };

    const handleUpdateCreditCard = async (e) => {
        try{
            if (!stripe || !elements) {
                return;
            }
            cardElement.update({disabled: true});
            if (cardComplete) {
                loading(true);
            }
            // Use your card Element with other Stripe.js APIs
            const payload = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (payload.error) {
                // console.log(payload.error);
                setCardInputError(payload.error);
                loading(false);
                cardElement.update({disabled: false});
                return;
            } else {
                setCardInputError(false);
            }

            const intentSecretRes = await axios.post(currentURL + '/updateRestaurantCreditCardDetailIntent', {
                customerId: restaurantData.customerId,
                restaurantId: restaurantData.restaurantId,
            }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});
            if(intentSecretRes.status === 200){
                const clientSecret = intentSecretRes.data.clientSecret;
                const paymentIntentId = intentSecretRes.data.paymentIntentId;
                const confirmCardSetUpRes = await stripe.confirmCardSetup(clientSecret,{
                    // receipt_email: userStatus.user.email,
                    payment_method: {
                        card: cardElement,
                        // billing_details: {
                        //     email: userStatus.user.email
                        // }
                    }
                });
                if (confirmCardSetUpRes.error) {
                    setCardInputError(confirmCardSetUpRes.error);
                    cardElement.update({disabled: false});
                }

                if (confirmCardSetUpRes.setupIntent.status === 'succeeded') {
                    console.log(confirmCardSetUpRes.setupIntent.payment_method);
                    const updateRestaurantCreditCardDetailResponse = await axios.post(currentURL + '/updateRestaurantCreditCardDetail', {
                        adminId: adminId,
                        isSetupIntent: true,
                        paymentMethodId: confirmCardSetUpRes.setupIntent.payment_method,
                        paymentIntentId: paymentIntentId,
                        contactEmail: userStatus.user.email,
                        customerId: restaurantData.customerId,
                        restaurantId: restaurantData.restaurantId
                    }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});
                    // console.log("called createRestaurant");
                    if(updateRestaurantCreditCardDetailResponse.status===200){
                        updatePageInfo();
                        alert("Update credit card successfully!");
                        // loadingRestautantDataAfterCreate(restaurantId);
                        // history.replace({
                        //     pathname: `/home/createRestaurant/${restaurantId}`,
                        //     state: {background: { pathname: "/home" }, restaurant:{isDemo:false, isFree: false}}
                        // });
                    }
                }
                
            }

            // const response = await stripe.redirectToCheckout({ sessionId: intentSecretRes.data.checkoutSession.id });

            // const confirmPaymentRes = await stripe.confirmCardPayment(intentSecretRes.data.clientSecret,{
            //     // By Ding
            //     receipt_email: userStatus.user.email,
            //     // receipt_email: restaurantData.contactEmail,
            //     payment_method: {card: cardElement}
            // });
            // if (confirmPaymentRes.error) {
            //     // await axios.post(currentURL + '/cancelMeteredSubscription', {meteredSubscriptionId, adminId: adminId, restaurantId: restaurantData.restaurantId});
            //     cardElement.update({disabled: false});
            //     throw confirmPaymentRes.error;
            // }
            // if (confirmPaymentRes.paymentIntent.status === 'succeeded') {
            //     const updateSubscriptionRes = await axios.post(currentURL + '/updateSubscriptionPlan', {
            //         contactEmail: userStatus.user.email,
            //         customerId: restaurantData.customerId,
            //         planId: changedPlanId,
            //         subscriptionId: restaurantData.subscriptionId,
            //         restaurantId: restaurantData.restaurantId,
            //         customerId: restaurantData.customerId,
            //         newSubscriptionId: intentSecretRes.data.newSubscriptionId,
            //         paymentMethodId: confirmPaymentRes.paymentIntent.payment_method,
            //     }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});
            //     updatePageInfo()
            //     // await updateSubscriptionInfo(adminId, restaurantData.restaurantId);
            //     alert("你的訂閱已經更新完成！");
            // }
            loading(false);
            closeEditCreditCardDialog();
            // submitForm();
        }catch(error){
            if(error.response){
                if(error.response.data.error === "The maximum number of trial period days is 730 (2 years)."||error.response.data.error.param==="trial_end"){
                    alert("你的訂閱將會超過兩年，因此交易未能完成。");
                }else{
                    setCardInputError(error.response.data.error.raw);
                }
                updatePageInfo();
                // await updateSubscriptionInfo(adminId, restaurantData.restaurantId);
            }else{
                // alert(`error: ${JSON.stringify(error)}`);
                // console.log(error);
                // await updateSubscriptionInfo(adminId, restaurantData.restaurantId);
                updatePageInfo();
                setCardInputError(error);
            }
            loading(false);
        }
    }
    // console.log(restaurantData);
    return(
    <div>
        
        <div id="edit-subscription-backdrop" className="modal-backdrop confirm-backdrop" style={{display: "block"}}/>
        <div id="edit-subscription-container" className="modal-container" style={{display: "block",}}>
            <div className="modal-wrap edit-subscription-wrap edit-credit-card c:row gap:xs">
                <div className="c:col c:between c:middle mb:xs">
                    <h3 class="f:black f:400">更改信用卡資料</h3>
                </div>

                <div className="form-input-wrap">
                        <label for="discountCode" style={{width: "80px"}}>信用卡</label>
                        <div className="normal-input-field-wrap">
                            <div class="credit-card-input-wrap c:row bg:white gap:sm">
                                <CardElement options={options} onChange={(e) => {
                                            setCardInputError(e.error);
                                            setCardComplete(e.complete);
                                }}/>
                            </div>
                            <div className="normal-input-error">
                            {cardInputError&& <ErrorText msg={cardInputError.message}/>}
                            </div>
                        </div>
                </div>  
                {!isLoading&&<div id="confirm-changes-foot" className="modal-foot c:col c:around p:xs">
                    <Button
                        className="btn step-btn"
                        color="primary"
                        variant="outlined" 
                        onClick={(e)=>closeEditCreditCardDialog(e)}>
                    取消
                    </Button>
                    <Button
                        className="btn step-btn"
                        color="primary"
                        variant="contained" 
                        onClick={confirm}>
                    確認
                    </Button>
                </div>}
            </div>
        </div>
    </div>
    );
}

export default EditCreditCard;