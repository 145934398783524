import React, {useState, useCallback, useEffect, useContext} from 'react';
import {Field} from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import '../root.css';
import './CreateRestaurant.css';
import './Step1.css';
import {ErrorText} from '../Error';
import { SuccessText } from '../Success';
import { currentURL } from '../../Config';
import Loading from '../Loading';
import { v4 as uuidv4} from 'uuid';
import ConfirmChangesDialog from './ConfirmChangesDialog';
import {validateRestaurantCode, isEmpty} from '../../validators/validator';
import { FirebaseUserContext, TargetUserContext, getIdToken } from '../../hooks/Firebase';


function Step1 ({mode, isDemo, formik, isLoadingRestautantData, closeModal, updateRestaurantData, restaurantData, handleStep, loading}){
    const userStatus = useContext(FirebaseUserContext);
    // const user = useContext(TargetUserContext);
    const user = userStatus.user;
    const [customCheckboxInput, setCustomCheckboxInput] = useState("");
    const [customCheckboxError, setCustomCheckboxError] = useState(false);
    const [codeErrors, setCodeErrors] = useState({});
    const [isCheckingCode, setIsCheckingCode] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogNotification, setDialogNotification] = useState([]);
    const [file, setFile] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [imageError, setImageError] = useState(false);

    //To upload image
    const onFileChange = event => {
        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
            return;
        }
        const validExts = ["svg", "png", "jpeg", "jpg"];
        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
      
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);
      
        //10MB = 10485760 bytes
        if(event.target.files[0].size > 10485760){
            setImageError(true);
            return;
        }
        if(!validExts.includes(ext)){
            setImageError(true);
            return;
        }
        if(event.target.files[0].size <= 10485760){
            setFile({ selectedFile: event.target.files[0] });
            setImageError(false);
        }
    };

    const handleCustomCheckboxInput = (e) => setCustomCheckboxInput(e.target.value);
    const closeDialog = useCallback(()=>setIsDialogOpen(false),[]);
    
    let tagCollection = [
        { id: 1, value: "港式", label: "港式" , isCustomized: false},
        { id: 2, value: "泰國菜", label: "泰國菜", isCustomized: false },
        { id: 3, value: "日本菜", label: "日本菜", isCustomized: false },
        { id: 4, value: "韓國菜", label: "韓國菜", isCustomized: false }
    ];
    const [tags, setTags] = useState(tagCollection);

    const {step, nextStep} = handleStep;
    const {values, setFieldValue, isSubmitting, errors, handleBlur, handleChange, touched, isValid, dirty} = formik;
    const is2ArrayEquals = (a, b) => a?.length === b?.length && a.every((v, i) => v === b[i]);

    useEffect(()=>{
        loading(false);
    }, []);

    useEffect(()=>{
        let notification = [];
        if(values.tel!==restaurantData.tel) notification.push(`餐廳電話更新為 ${values.tel}`);
        if(values.restaurantName!==restaurantData.restaurantName) notification.push(`餐廳名稱更新為 ${values.restaurantName}`);
        if(values.address!==restaurantData.address) notification.push(`餐廳地址更新為 ${values.address}`);
        // if(!is2ArrayEquals(values.cuisineTags, restaurantData.cuisineTags)){
        //      notification.push(`食品 / 餐廳類型更新為 ${values.cuisineTags.join(', ')}`);
        // };
        if(values.address1!==restaurantData.address1||values.address2!==restaurantData.address2||values.address3!==restaurantData.address3) {
            notification.push(`餐廳地址更新為 ${values.address1}, ${values.address2}, ${values.address3}`);
        };
        if(file){
            notification.push(`餐廳標誌更新為 ${file.selectedFile.name}`);
        }
        // console.log("refreshed");
        // console.log(notification);
        setDialogNotification(notification);
    },[values, restaurantData, file]);
    
    const updateBasicInfo = async () => {
        const myformData = new FormData();
        if(file){
            // Update the formData object
            myformData.append(
                "file",
                file.selectedFile,
                file.selectedFile.name
            );
        }
        const newValues =  {
            restaurantName: values.restaurantName, 
            tel: values.tel,
            address1: values.address1,
            address2: values.address2,
            address3: values.address3,
            cuisineTags: values.cuisineTags,
            iconName: file? file.selectedFile.name: "",
        };
        // const updateResponse = await axios.post(currentURL+'/updateRestaurantBasicInfo', {
        //     values: newValues,
        //     restaurantId: restaurantData.restaurantId,
        //     adminId: user.uid,
        // });
        myformData.append("adminId", user.uid);
        myformData.append("restaurantId", restaurantData.restaurantId);
        myformData.append("values", JSON.stringify(newValues));
        const config = { 
            headers: { 'Content-Type': `multipart/form-data; boundary=${myformData._boundary}`, Authorization: `Bearer ${userStatus.idToken}`}
        };
        const updateResponse = await axios.post(currentURL + "/uploadRestaurantIcon", myformData, config);
        return new Promise((resolve, reject)=>{
            if(updateResponse.status === 200){
                updateRestaurantData(newValues);
                setFile(null);
                resolve(true);
            }else{
                reject(updateResponse.data.error);
            }
        });
    }
    const checkRestaurantCode = async(e) => {
        setIsCheckingCode(true);
        try{
            const [isValid, err] = validateRestaurantCode(e.target.value);
            if(!isValid) setCodeErrors(err);
            if(isValid) {
                const res = await axios.post(currentURL+'/checkRestaurantMap', {code: e.target.value});
                // console.log("called checkRestaurantMap");
                if(res.data.suggestedCode){
                    setCodeErrors({restaurantCode: `餐廳識別碼已存在，建議改為${res.data.suggestedCode}`});
                }else{
                    setCodeErrors({});
                }
            };
        }catch(err){
            setCodeErrors({restaurantCode: err});
        }
        setIsCheckingCode(false);
    }
    const addCustomCheckBox = () => {
        // tagCollection.push({label: customCheckboxInput, value: customCheckboxInput});
        if(isEmpty(customCheckboxInput)){
            setCustomCheckboxError("請填寫有效名稱");
            return;
        }
        if(tags.some(obj=>obj.value === customCheckboxInput)){
            setCustomCheckboxError("食品 / 餐廳類型已存在");
            return;
        }
        if(!isEmpty(customCheckboxInput)&&!tags.some(obj=>obj.value === customCheckboxInput)){
            // console.log(isEmpty(customCheckboxInput));
            setTags(origin=>[...origin, {id: uuidv4().split('-')[0],label: customCheckboxInput, value: customCheckboxInput, isCustomized: true}]);
            setCustomCheckboxInput("");
            setCustomCheckboxError(false);
        }
        setFieldValue("cuisineTags", [...values.cuisineTags, customCheckboxInput]);
    }
    const deleteCustomCheckBox = (id) => {
        setTags(origin=>origin.filter(tag=>tag.id != id));
    }
    return (
        <div className="step1 h:full c:row">
            {isDialogOpen&&<ConfirmChangesDialog closeDialog={closeDialog} dialogNotification={dialogNotification} updateFunction={updateBasicInfo} />}
            <div className="c:col c:between c:middle">
                <h3 className="f:black f:400">餐廳基本設定</h3>
            </div>
            <div className="basic-info-form-body c:row flex:1 py:md">
                {isDemo? 
                <div className="form-info-wrap">
                    <label htmlFor="restaurantCode">餐廳名稱</label>
                    <h6>{values.restaurantName}</h6>
                </div>
                :<div className="form-input-wrap">
                    <label htmlFor="restaurantName">餐廳名稱</label>
                    <div className="normal-input-field-wrap">
                        <input 
                            className="normal-input-field"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.restaurantName}
                            id="restaurantName" 
                            name="restaurantName"
                            placeholder="例：Caterat Demo"
                        />
                        <div className="normal-input-error">
                        {touched.restaurantName && errors.restaurantName && <ErrorText msg={errors.restaurantName}/>}
                        </div>
                    </div>
                </div>}
               
                {mode==="CREATE"&&<div className="form-input-wrap">
                    <label htmlFor="restaurantCode">餐廳識別碼</label>
                    <div className="normal-input-field-wrap">
                        <input 
                            className="normal-input-field"
                            type="text"
                            onChange={handleChange}
                            onBlur={(e)=>{
                                handleBlur(e);
                                checkRestaurantCode(e);
                            }}
                            value={values.restaurantCode}
                            id="restaurantCode" 
                            name="restaurantCode"
                            placeholder="例：cateratdemo"
                        />
                        <div className="normal-input-hints">
                            <p>*識別碼在確認創建餐廳後將永遠不得更改。</p>
                            <p>*識別碼會作用於餐廳經理登入POS裝置，以及顧客搜尋閣下餐廳的網上外賣自取平台，因此請填寫一個簡潔的識別碼。</p>
                        </div>
                        <div className="normal-input-error">
                        {/* {isCheckingCode? <Loading/>} */}
                        {touched.restaurantCode && codeErrors.restaurantCode && <ErrorText msg={codeErrors.restaurantCode}/>}
                        {touched.restaurantCode && !codeErrors.restaurantCode && <SuccessText msg="可行的餐廳識別碼"/>}
                        </div>
                    </div>
                </div>}
                {mode==="EDIT"&&<div className="form-info-wrap">
                    <label htmlFor="restaurantCode">餐廳識別碼</label>
                    <h6>{values.restaurantCode}</h6>
                </div>}
                
                {mode==="EDIT"&&<div className="form-input-wrap">
                    <label>餐廳標誌</label>
                    <div className="normal-input-field-wrap">
                        <input type="file" id="restaurantIcon" name="file" onChange={onFileChange} />
                        {/* <div className="normal-input-hints"/> */}
                        <div className="normal-input-error">
                        {imageError && <ErrorText msg={"上傳圖片大小限制為10MB，類型只限svg、png、jpeg、jpg"}/>}
                        </div>
                    </div>
                </div>}
                {/* <RestaurantCodeDialog offAutoGen={offAutoGen} updateCode={updateCode} isOpen={codeDialog} toggleCodeDialog={toggleCodeDialog} code={code} /> */}
                <div className="form-input-wrap">
                    <label htmlFor="tel">餐廳電話</label>
                    <div className="normal-input-field-wrap">
                        <input 
                            className="normal-input-field"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tel}
                            id="tel" 
                            name="tel"
                            placeholder="例：9123 4321"
                        />
                        <div className="normal-input-error">
                        {touched.tel && errors.tel && <ErrorText msg={errors.tel}/>}
                        </div>
                    </div>
                </div>
                <div className="form-input-wrap">
                    <label htmlFor="address1">餐廳地址</label>
                    <div id="address1" className="normal-input-field-wrap">
                        <input 
                            className="normal-input-field"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address1}
                            id="address1" 
                            name="address1"
                            placeholder="例：室號、樓層、座號"
                        />
                    </div>
                </div> 
                <div className="form-input-wrap">
                    <label htmlFor="address2"></label>
                    <div id="address2" className="normal-input-field-wrap">
                        <input 
                            className="normal-input-field"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address2}
                            id="address2" 
                            name="address2"
                            placeholder="例：大廈、街道"
                        />
                    </div>
                </div> 
                <div className="form-input-wrap">
                    <label htmlFor="address3"></label>
                    <div id="address3" className="normal-input-field-wrap">
                        <input 
                            className="normal-input-field"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address3}
                            id="address3" 
                            name="address3"
                            placeholder="例：地區"
                        />
                        <div className="normal-input-error"></div>
                    </div>
                </div> 
                {/* <div className="form-input-wrap">
                    <label htmlFor="cuisineTags" style={{marginTop: "var(--xs)"}}>食品 / 餐廳類型</label>
                    <div className="normal-input-field-wrap">
                        <div className="checkbox-grid">
                        {tags.map((tag, index) => (
                            <div key={`tag-${index}`} className="checkbox-wrap">
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    id={`tag-${index}`}
                                    name="cuisineTags"
                                    value={tag.value}
                                    checked={values.cuisineTags.includes(tag.value)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <h6 className="f:400 flex:1">{tag.label}</h6>
                            </div>
                        ))}
                        </div>
                        {touched.cuisineTags && errors.cuisineTags && <ErrorText style={{paddingLeft: "var(--xs)"}} msg={errors.cuisineTags}/>}
                        {customCheckboxError && <ErrorText style={{paddingLeft: "var(--xs)"}} msg={customCheckboxError}/>}
                        <div key={`tag-custom`} className="c:col c:middle pl:xs gap:xs mt:sm">
                            <input
                                onChange={handleCustomCheckboxInput}
                                value={customCheckboxInput}
                                className="checkbox-input-field"
                                type="text"
                                placeholder="自訂類別"
                            >
                            </input>
                            <div className="button" onClick={addCustomCheckBox}>
                                <i className="checkbox-add-btn material-icons-outlined">
                                    add
                                </i>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="c:col c:between">
                <Button
                className="btn step-btn my-outlined-btn"
                color="Primary"
                variant="outlined" 
                onClick={closeModal}>
                    {mode==="CREATE"? "取消":"返回"}
                </Button>
            
                {mode==="CREATE"? <Button
                className="btn step-btn my-outlined-btn"
                disabled={(codeErrors.restaurantCode||!(isValid&&dirty))}
                color="Primary"
                variant="contained" 
                onClick={nextStep}
                >
                下一步
                </Button>:
                <Button
                    disabled={!dialogNotification.length||isLoadingRestautantData}
                    onClick={()=>{setIsDialogOpen(true)}}
                    className="save-btn my-primary-btn"
                    color="primary" 
                    variant="contained" >
                    <i className="f:xl material-icons-outlined mr:xs">
                    save
                    </i>
                    儲存
                </Button>}
            </div>
            
            {/* <Button  
                onClick={()=>{
                    nextStep();
                }}>
                    Next
            </Button> */}
        </div>
    );
}

export default Step1;