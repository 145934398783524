import React, {useState, useCallback, useEffect, useContext} from 'react';
import {Field} from 'formik';
import TextField from '@material-ui/core/TextField';
import {useParams, useHistory, useLocation} from "react-router-dom";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import '../root.css';
import './CreateRestaurant.css';
import './Step5.css';
import {ErrorText} from '../Error';
import { isEmpty } from '../../validators/validator';
import {loadStripe} from '@stripe/stripe-js';
import { currentURL } from '../../Config';
import { FirebaseUserContext, getIdToken } from '../../hooks/Firebase';
import ConfirmChangesDialog from './ConfirmChangesDialog';
import Loading from '../Loading';

function Step5 ({ mode, isLoadingRestautantData, restaurantData, closeModal}){
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;
    const {restaurantId} = useParams();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const closeDialog = useCallback(()=>setIsDialogOpen(false),[]);
    const dialogNotification = ["確認刪除餐廳?"];
    const deleteRestaurant = async (e) => {
        const deleteResponse = await axios.post(currentURL+'/deleteAdminRestaurant', {
            restaurantId: restaurantId,
        }, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        return new Promise((resolve, reject)=>{
            if(deleteResponse.status === 200){
                resolve(true);
            }else{
                reject(deleteResponse.data.error);
            }
        });
    };
    return (
        <div className="step4 h:full c:row">
            {isDialogOpen&&<ConfirmChangesDialog closeModal={closeModal} closeDialog={closeDialog} showConfirmSentence={false} dialogNotification={dialogNotification} updateFunction={deleteRestaurant}/>}
            {isLoadingRestautantData? <Loading/>
            :<div className="c:row h:full">
                <div className="c:col c:between c:middle">
                    <h3 className="f:black f:400">刪除餐廳</h3>
                </div>
                <div className="step5-body flex:1">
                    <div className="c:row step5-content-wrap">
                        <span>
                            此操作將不能逆轉，餐廳所有資料將會被永久刪除，經理帳號會立即停止運作，POS系統的資料亦會遭刪除，我們將會停止向你收取下期款項，但已繳付款將不獲退還。<br/>
                            <br/>
                            請在確認刪除時細閱以上文字，假如已清楚了解此操作的後果，請按下刪除鍵繼續以完成刪除餐廳。
                        </span>
                        <Button 
                            type="button" 
                            id="delete-restaurant-btn"
                            color="secondary" 
                            variant="contained"
                            onClick={()=>{setIsDialogOpen(true)}}> 
                            刪除餐廳
                        </Button>
                    </div>
                </div>
                <div className="c:col c:between mt:xs">
                    <Button  
                        className="btn step-btn my-outlined-btn"
                        onClick={closeModal}
                        color="primary"
                        variant="outlined" 
                    >
                    返回
                    </Button>
                    <div/>
                </div>
            </div>
            }
            
    </div>
    );
}

export default Step5;