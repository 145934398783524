import {React, useState, useContext, useEffect} from 'react';
import { Redirect, useLocation,  } from 'react-router';
import { Formik, Form, Field } from 'formik';
import axios from "axios";
import firebase from "firebase/app";
import { useHistory, Link } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FirebaseUserContext } from '../../hooks/Firebase';
import {useCreateUserWithEmailAndPassword} from '../../hooks/Firebase';
import Loading from '../Loading';
import {isEmail} from '../../validators/validator';
import './SignUp.css';
import {ErrorText} from '../Error';
import {SuccessText} from '../Success';

function SendEmail(){
  const history = useHistory();
  const [
    createUserWithEmailAndPassword,
    registeredUser,
    loading,
    error,
    ] = useCreateUserWithEmailAndPassword();

  return (
      <RequestEmailForm createUserWithEmailAndPassword={createUserWithEmailAndPassword} error={error}/> 
  );
}

const RequestEmailForm = ({createUserWithEmailAndPassword}) => {
  const userStatus = useContext(FirebaseUserContext);
  const user = userStatus.user;
  const history = useHistory();
  const location = useLocation();
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successes, setSuccesses] = useState({});
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState("");
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(origin=>origin - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  useEffect(() => {
    if(userStatus.loggedIn){
      setEmail(user.email);
    }
  }, [userStatus]);

  const handleEmail = e => setEmail(e.target.value);
  const action =  location.state.actionType;
  const directToLogin = () => {
    history.push('/login');
  }

  const validateEmail = () => {
    let emailError = {};
    if(email==='') emailError.email = "請填寫電郵";
    if(!isEmail(email)) emailError.email ="請填寫有效電郵" ;
    return [
      Object.keys(emailError).length === 0 ? true : false,
      emailError
    ];
  }

  const resendVerificationEmail = () => {
    const[isValid, emailErrors] = validateEmail();
    if(!isValid){
      setErrors(emailErrors);
    }
    if(isValid){
      setErrors({});
      setIsLoading(true);
      firebase.auth().currentUser.sendEmailVerification()
      .then(() => {
        setCounter(30);
        
        setSuccesses({verificationCode: "驗證碼已經發送，請查閱你的電郵信箱"});
      }).catch(error=>{
        if(error.code === "auth/too-many-requests") setErrors({email: "短期內太多發送請求，請稍後再試"});
        setSuccesses({});
      });
      setIsLoading(false);
    }
  }
  const sendResetPasswordEmail = () => {
    const[isValid, emailErrors] = validateEmail();
    if(!isValid){
      setErrors(emailErrors);
    }
    if(isValid){
      setErrors({});
      setIsLoading(true);
      firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        setCounter(30);
        setSuccesses({resetPassword: "重設密碼電郵已經發送，請查閱你的電郵信箱"});
      }).catch(error=>{
        if(error.code === "auth/too-many-requests") setErrors({email: "短期內太多發送請求，請稍後再試"});
        setSuccesses({});
      });
      setIsLoading(false);
    }
  }
  return (
  <div className="app-login c:row">
    <div className="login-header c:row c:middle">
      <h2>Caterat POS Restaurant System</h2>
    </div>
    <div className="login-body w:full flex:1">
            <div className="login-card c:row">
              <div className="c:col flex:1">
                <div className="login-card-right">
                    <h1>歡迎回來Caterat！</h1>
                    <h6>立即登入查看你的餐廳業績</h6>
                    <button onClick={directToLogin} className="signup-btn" type="button">登入</button>
                </div>
                <div className="login-card-left">
                  <h3 className="login-card-title">
                    {action==="reset"? "重設密碼":"驗證電郵"}
                  </h3>
                  <input 
                    className="login-input-field"
                    type="text"
                    id="email" 
                    name="email"
                    onChange={handleEmail}
                    value={email}
                    // onBlur={handleBlur}
                    placeholder="電郵"
                  />
                  <div className="login-input-error">
                    {errors.email && <ErrorText msg={errors.email}/>}
                    {action==="resend" && successes.verificationCode && <SuccessText msg={successes.verificationCode}/>}
                    {action==="reset" && successes.resetPassword && <SuccessText msg={successes.resetPassword}/>}
                  </div>
                  <button
                    id="send-email-btn"
                    type="button"
                    className="login-btn"
                    // disabled={isSubmitting}
                    disabled={isLoading||counter>0}
                    onClick = {action==="reset"? sendResetPasswordEmail:resendVerificationEmail}
                    >
                      {/* {isLoading? <Loading/>:action==="reset"? "重設密碼":"重新發送驗證碼"} */}
                      {action==="reset"&&counter===0&&"發送重設密碼電郵"}
                      {action==="resend"&&counter===0&&"重新發送驗證碼"}
                      {counter>0&&`${counter}秒後可重新發送`}
                    </button>
                </div>
              </div>
            </div>

    </div>
  </div>
  );
}



export default SendEmail;