import React, {useState, useCallback, useEffect, useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Formik, Form, Field} from 'formik';
import { useHistory} from "react-router-dom";
import axios from 'axios';
import firebase from "firebase/app";
import './UserProfile.css';
import {ErrorText} from '../Error';
import {SuccessText} from '../Success';
import Loading from '../Loading';
import { FirebaseUserContext } from '../../hooks/Firebase';
import {validateChangePassword, isEmail, validateUserInfo} from '../../validators/validator';
import { currentURL } from '../../Config';
import { set } from 'harmony-reflect';

function ProfileForm({user}){
    const userStatus = useContext(FirebaseUserContext);
    // const user = userStatus.user;
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdatingEmail, setIsUpdatingEmail] = useState(false);
    const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
    const [isUpdatingUserInfo, setIsUpdatingUserInfo] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isCodeConfirmed, setIsCodeConfirmed] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [passwordForEmail, setPasswordForEmail] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [contactName, setContactName] = useState("");
    const [contactTel, setContactTel] = useState("");
    const [errors, setErrors] = useState({});
    const [userInfoErrors, setUserInfoErrors] = useState({});
    const [changePasswordErrors, setChangePasswordErrors] = useState({});
    const [successes, setSuccesses] = useState({});
    const [counter, setCounter] = useState(0);

    useEffect(() => {
      const timer =
        counter > 0 && setInterval(() => setCounter(origin=>origin - 1), 1000);
      return () => clearInterval(timer);
    }, [counter]);
    useEffect(()=>{
        setNewEmail(user.email);
    }, [user]);
    useEffect(async ()=>{
        setIsLoading(true);
        try{
            const res = await axios.post(currentURL+'/getUserInfo', {},{headers: { Authorization: `Bearer ${userStatus.idToken}`}});
            const userInfo = res.data;
            setCompanyName(userInfo.companyName);
            setContactName(userInfo.contactName);
            setContactTel(userInfo.contactTel);
        }catch(error){
            alert(error);
        }
        setIsLoading(false);
    }, []);

    const handleNewEmail = e => setNewEmail(e.target.value);
    const handleVerificationCode = e => {
        setVerificationCode(e.target.value);
        setIsCodeConfirmed(false);
    };
    const handlePasswordForEmail = e => setPasswordForEmail(e.target.value);
    const handleOldPassword = e => setOldPassword(e.target.value);
    const handleNewPassword = e => setNewPassword(e.target.value);
    const handleConfirmNewPassword = e => setConfirmNewPassword(e.target.value);
    const handleCompanyName = e => setCompanyName(e.target.value);
    const handleContactName = e => setContactName(e.target.value);
    const handleContactTel = e => setContactTel(e.target.value);

    const sendEmailVerification = async () => {
        if(newEmail===user.email) {
            setErrors(origin=>({...origin, newEmail: "請輸入新的電郵"}));
            return;
        };
        if(newEmail==="") return setErrors(origin=>({...origin, newEmail: "請輸入有效電郵"}));
        if(!isEmail(newEmail)){
            setSuccesses(origin=>{
                let copy = {...origin};
                delete copy.newEmail;
                return copy;
            });
            return setErrors(origin=>({...origin, newEmail: "請輸入有效電郵"}));
        } 
        if(isEmail(newEmail)){
            try{
                setIsLoading(true);
                const res = await axios.post(currentURL+'/sendEmailVerificationCode', {targetEmail: newEmail},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                if(res.status === 200){
                    setIsCodeSent(true);
                    setSuccesses(origin=>({...origin, newEmail: "電郵驗證碼已經送出，請前往你的郵箱查閱"}));
                    setCounter(30);
                    setErrors(origin=>{
                        let copy = {...origin};
                        delete copy.newEmail;
                        return copy;
                    });
                }
                if(res.status === 500){
                    console.log(res.data);
                }
            
            }catch(error){
                if(error.response.data.error === "email already exists"){
                    setErrors(origin=>({...origin, newEmail: "電郵已被使用"}));
                }else{
                    setErrors(origin=>({...origin, newEmail: error.response?.data?.error}));
                }
            }
            // console.log(res.data);
            setIsLoading(false);
        }
    }
    const confirmVerificationCode = async () => {
        setIsLoading(true);
        const res = await axios.post(currentURL+'/handleEmailVerification', {verificationCode, newEmail}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        if(res.status === 200){
            if(res.data.success){
                setSuccesses(origin=>({...origin, verificationCode: "驗證碼正確"}));
                setIsCodeConfirmed(true);
                setErrors(origin=>{
                    let copy = {...origin};
                    delete copy.verificationCode;
                    return copy;
                });
            }
            if(res.data.failure){
                setIsCodeConfirmed(false);
                setSuccesses(origin=>{
                    let copy = {...origin};
                    delete copy.verificationCode;
                    return copy;
                });
                setErrors(origin=>({...origin, verificationCode: res.data.failure}));
            }
        }
        setIsLoading(false);
    }
    
    const confirmUpdateEmail = async () => {
        setIsLoading(true);
        setIsUpdatingEmail(true);
        const res = await axios.post(currentURL+'/handleEmailVerification', {verificationCode, newEmail}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        if(res.status === 200){
            if(res.data.success){
                // alert("你的帳戶電郵已被更新");
                // setSuccesses(origin=>({...origin, verificationCode: "你的帳戶電郵已被更新"}));
                setErrors(origin=>{
                    let copy = {...origin};
                    delete copy.verificationCode;
                    return copy;
                });
                firebase.auth().signInWithEmailAndPassword(user.email, passwordForEmail)
                .then((userCredential) => {
                    const currentUser = firebase.auth().currentUser;
                    currentUser.updateEmail(newEmail).then(() => {
                        setErrors(origin=>{
                            let copy = {...origin};
                            delete copy.passwordForEmail;
                            return copy;
                        });
                        setSuccesses(origin=>({...origin, passwordForEmail: "你的帳戶電郵已更新"}));
                        const setEmailRes = axios.post(currentURL+'/setEmailIsVerified', {}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                    });                     
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log(errorCode);
                    setErrors(origin=>({...origin, passwordForEmail: "錯誤密碼"}));
                    setSuccesses(origin=>{
                        let copy = {...origin};
                        delete copy.passwordForEmail;
                        return copy;
                    });
                });

            }
            if(res.data.failure){
                setSuccesses(origin=>{
                    let copy = {...origin};
                    delete copy.verificationCode;
                    return copy;
                });
                setErrors(origin=>({...origin, verificationCode: res.data.failure}));
            }
        }
        // setIsLoading(false);
        setTimeout(()=>{setIsLoading(false)}, 1000);
        setTimeout(()=>{setIsUpdatingEmail(false)}, 1000);
    }
    const updateUserPassword = () => {
        const [isValid, changePasswordErrors] = validateChangePassword({newPassword, confirmNewPassword});

        if(!isValid) {
            setChangePasswordErrors(changePasswordErrors);
            setSuccesses(origin=>{
                let copy = {...origin};
                delete copy.confirmNewPassword;
                return copy;
            });
        };
        if(isValid) {
            setChangePasswordErrors({});
            setIsUpdatingPassword(true);
            firebase.auth().signInWithEmailAndPassword(user.email, oldPassword)
            .then((userCredential) => {
                const currentUser = firebase.auth().currentUser;
                currentUser.updatePassword(newPassword).then(() => {
                    setSuccesses(origin=>({...origin, confirmNewPassword: "你的密碼已更新"}));
                }).catch((error)=>{
                    setChangePasswordErrors({...origin, confirmNewPassword: error.message});
                    setSuccesses(origin=>{
                        let copy = {...origin};
                        delete copy.confirmNewPassword;
                        return copy;
                    });
                });          
            }).catch((error) => {
                setChangePasswordErrors({...origin, oldPassword: "錯誤密碼"});
                setSuccesses(origin=>{
                    let copy = {...origin};
                    delete copy.confirmNewPassword;
                    return copy;
                });
            });
            setTimeout(()=>{setIsUpdatingPassword(false)}, 1000);
        };
    }
    const updateUserInfo = async () => {
        setIsUpdatingUserInfo(true);
        const [isValid, errors] = validateUserInfo({companyName, contactTel, contactName});
        if(!isValid) {
            setUserInfoErrors(errors);
        }
        if(isValid) {
            try{
                const res = await axios.post(currentURL+'/updateUserInfo', {values: {companyName, contactTel, contactName}},{headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                setUserInfoErrors({});
                alert("成功更新帳戶資料");
            }catch(error){
                alert(error);
            }
        }
        setIsUpdatingUserInfo(false);
    }
    // const handleBlur = () => {
    //     const [isValid, changePasswordErrors] = validateChangePassword({newPassword, confirmNewPassword});

    //     if(!isValid) setErrors(origin=>({...origin, ...changePasswordErrors}));
    //     if(isValid) {
    //         setErrors(origin=>{
    //             let copy = {...origin};
    //             delete copy.newPassword;
    //             delete copy.confirmNewPassword;
    //             return copy;
    //         })
    //     };
    // }
    const directToHome = () => history.push(`/home`);
    
    
    return (
    <div className="c:row gap:sm flex:1">
        <div className="mat-card-head c:col c:between c:middle border:bottom">
            <div className="c:col c:between c:middle mb:md">
                <h3 className="f:black f:400">更改帳戶電郵</h3>
            </div>
        </div>
 
        <div className="form-input-wrap">
            <label for="newEmail">電郵</label>
            <div className="normal-input-field-wrap">
            <div className="c:col gap:xs">
                <input 
                    className="normal-input-field"
                    type="text"
                    disabled={isUpdatingEmail}
                    value={newEmail}
                    onChange={handleNewEmail}
                    // onBlur={handleBlur}
                    id="newEmail" 
                    name="newEmail"
                />
                <Button
                    disabled={isLoading||counter>0}
                    className="inline-btn"
                    variant="contained"
                    color="primary"
                    onClick={sendEmailVerification}
                    >
                    {counter>0? `${counter}秒後可重新發送`:"傳送驗證碼"}
                </Button>
            </div>
                <div className="normal-input-error">
                {errors.newEmail && <ErrorText msg={errors.newEmail}/>}
                {successes.newEmail && <SuccessText msg={successes.newEmail}/>}
                </div>
            </div>
        </div> 
        <div className="form-input-wrap">
            <label for="verificationCode">驗證碼</label>
            <div className="normal-input-field-wrap">
                <div className="c:col gap:xs">
                    <input 
                        className="normal-input-field"
                        type="text"
                        disabled={!isCodeSent||isUpdatingEmail}
                        value={verificationCode}
                        onChange={handleVerificationCode}
                        // onBlur={handleBlur}
                        id="verificationCode" 
                        name="verificationCode"
                    />
                    <Button
                        // style={{visibility: "hidden"}}
                        disabled={isLoading||!isCodeSent||isCodeConfirmed}
                        className="inline-btn"
                        variant="contained"
                        color="primary"
                        onClick={confirmVerificationCode}
                    >
                    確認驗證碼
                    </Button>
                </div>
                <div className="normal-input-error">
                {errors.verificationCode && <ErrorText msg={errors.verificationCode}/>}
                {successes.verificationCode && <SuccessText msg={successes.verificationCode}/>}
                </div>
            </div>
        </div> 
        <div className="form-input-wrap">
            <label for="passwordForEmail">帳戶密碼</label>
            <div className="normal-input-field-wrap">
                <div className="c:col gap:xs">
                    <input 
                        disabled={!isCodeSent||!isCodeConfirmed||isUpdatingEmail}
                        className="normal-input-field"
                        type="text"
                        value={passwordForEmail}
                        onChange={handlePasswordForEmail}
                        id="passwordForEmail" 
                        name="passwordForEmail"
                    />
                    <Button
                        disabled={isLoading||!isCodeSent||!isCodeConfirmed}
                        className="inline-btn"
                        variant="contained"
                        color="primary"
                        onClick={confirmUpdateEmail}
                    >
                    {isUpdatingEmail? <Loading/>:"確認更改電郵"}
                    </Button>
                </div>
                <div className="normal-input-error">
                {errors.passwordForEmail && <ErrorText msg={errors.passwordForEmail}/>}
                {successes.passwordForEmail && <SuccessText msg={successes.passwordForEmail}/>}
                </div>
            </div>
        </div> 

        {/* -----------------------------------Change Password Part----------------------------------------------------------------- */}
        <div className="mat-card-head c:col c:between c:middle border:bottom">
            <div className="c:col c:between c:middle mb:md">
                <h3 className="f:black f:400">更改帳戶密碼</h3>
            </div>
        </div>
        <div className="form-input-wrap">
            <label for="oldPassword">舊密碼</label>
            <div className="normal-input-field-wrap">
            <div className="c:col gap:xs">
                <input 
                    className="normal-input-field"
                    type="password"
                    disabled={isUpdatingPassword}
                    value={oldPassword}
                    onChange={handleOldPassword}
                    id="oldPassword" 
                    name="oldPassword"
                    autoComplete="off"
                />
            </div>
                <div className="normal-input-error">
                {changePasswordErrors.oldPassword && <ErrorText msg={changePasswordErrors.oldPassword}/>}
                {successes.oldPassword && <SuccessText msg={successes.oldPassword}/>}
                </div>
            </div>
        </div> 
        <div className="form-input-wrap">
            <label for="newPassword">新密碼</label>
            <div className="normal-input-field-wrap">
                <div className="c:col gap:xs">
                    <input 
                        className="normal-input-field"
                        type="password"
                        // onBlur={handleBlur}
                        disabled={isUpdatingPassword}
                        value={newPassword}
                        onChange={handleNewPassword}
                        id="newPassword" 
                        name="newPassword"
                        autoComplete="off"
                    />
                </div>
                <div className="normal-input-error">
                {changePasswordErrors.newPassword && <ErrorText msg={changePasswordErrors.newPassword}/>}
                {successes.newPassword && <SuccessText msg={successes.newPassword}/>}
                </div>
            </div>
        </div> 
        <div className="form-input-wrap">
            <label for="confirmNewPassword">確認新密碼</label>
            <div className="normal-input-field-wrap">
                <div className="c:col gap:xs">
                    <input 
                        className="normal-input-field"
                        type="password"
                        // onBlur={handleBlur}
                        disabled={isUpdatingPassword}
                        value={confirmNewPassword}
                        onChange={handleConfirmNewPassword}
                        id="confirmNewPassword" 
                        name="confirmNewPassword"
                        autoComplete="off"
                    />
                </div>
                <div className="normal-input-error">
                {changePasswordErrors.confirmNewPassword && <ErrorText msg={changePasswordErrors.confirmNewPassword}/>}
                {successes.confirmNewPassword && <SuccessText msg={successes.confirmNewPassword}/>}
                </div>
            </div>
        </div> 
        <div className="c:col c:between">
            {/* <Button  
                className="btn step-btn my-outlined-btn"
                onClick={directToHome}
                color="primary"
                variant="outlined">
            返回
            </Button> */}
            <div/>
            <Button
                disabled={isUpdatingPassword}
                className="btn step-btn"
                color="primary"
                variant="contained" 
                onClick={updateUserPassword}
                >
            {isUpdatingPassword? <Loading/>:"更改密碼"}
            </Button>
        </div>
        {/* -----------------------------------Change Information Part----------------------------------------------------------------- */}
        <div className="mat-card-head c:col c:between c:middle border:bottom">
            <div className="c:col c:between c:middle mb:md mt:md">
                <h3 className="f:black f:400">更改帳戶資料</h3>
            </div>
        </div>
        <div className="form-input-wrap">
            <label for="oldPassword">公司名稱</label>
            <div className="normal-input-field-wrap">
            <div className="c:col gap:xs">
                <input 
                    className="normal-input-field"
                    type="text"
                    disabled={isUpdatingUserInfo}
                    value={companyName}
                    onChange={handleCompanyName}
                    id="companyName" 
                    name="companyName"
                    autoComplete="off"
                />
            </div>
                <div className="normal-input-error">
                {userInfoErrors.companyName && <ErrorText msg={userInfoErrors.companyName}/>}
                </div>
            </div>
        </div> 
        <div className="form-input-wrap">
            <label for="oldPassword">聯絡姓名</label>
            <div className="normal-input-field-wrap">
            <div className="c:col gap:xs">
                <input 
                    className="normal-input-field"
                    type="text"
                    disabled={isUpdatingUserInfo}
                    value={contactName}
                    onChange={handleContactName}
                    id="companyName" 
                    name="companyName"
                    autoComplete="off"
                />
            </div>
                <div className="normal-input-error">
                {userInfoErrors.contactName && <ErrorText msg={userInfoErrors.contactName}/>}
                </div>
            </div>
        </div> 
        <div className="form-input-wrap">
            <label for="oldPassword">聯絡電話</label>
            <div className="normal-input-field-wrap">
            <div className="c:col gap:xs">
                <input 
                    className="normal-input-field"
                    type="text"
                    disabled={isUpdatingUserInfo}
                    value={contactTel}
                    onChange={handleContactTel}
                    id="companyName" 
                    name="companyName"
                    autoComplete="off"
                />
            </div>
                <div className="normal-input-error">
                {userInfoErrors.contactTel && <ErrorText msg={userInfoErrors.contactTel}/>}
                </div>
            </div>
        </div> 
        <div className="c:col c:between">
            <Button  
                className="btn step-btn my-outlined-btn"
                onClick={directToHome}
                color="primary"
                variant="outlined">
            返回
            </Button>
            <Button
                disabled={isUpdatingUserInfo||isLoading}
                className="btn step-btn"
                color="primary"
                variant="contained" 
                onClick={updateUserInfo}
                >
            {isUpdatingUserInfo? <Loading/>:"更改帳戶資料"}
            </Button>
        </div>
        {/* <div className="form-input-wrap">
            <label for="passwordForEmail">帳戶密碼</label>
            <div className="normal-input-field-wrap">
                <div className="c:col gap:xs">
                    <input 
                        className="normal-input-field"
                        type="text"
                        value={passwordForEmail}
                        onChange={handlePasswordForEmail}
                        id="passwordForEmail" 
                        name="passwordForEmail"
                    />
                    <Button
                        className="inline-btn"
                        variant="contained"
                        color="primary"
                        onClick={()=>{}}
                    >
                    確認更改電郵
                    </Button>
                </div>
                <div className="normal-input-error">
               
                </div>
            </div>
        </div>  */}
        {/* {editMode?
        <div className="form-input-wrap">
            <label>{changePasswordMode||"密碼:"}</label>
            <div className="normal-input-field-wrap">
            <Button
                className="flex:3"
                style={{padding: "0px", fontWeight: "500", boxShadow: "var(--box-shadow)"}}
                type="button"
                variant={changePasswordMode? "outlined":"contained"}
                onClick={toggleChangePasswordMode}
            >
            更改密碼
            </Button>
                <div className="normal-input-error">
                </div>
            </div>
            
        </div> 
        :<div className="form-input-wrap">
            <label for="passwordDemo">密碼</label>
            <div className="normal-input-field-wrap">
                <input 
                    className="normal-input-field"
                    disabled
                    type="text"
                    id="passwordDemo" 
                    name="passwordDemo"
                    value="••••••••"
                />
                <div className="normal-input-error"/>
            </div>
        </div> 
        }
        {changePasswordMode&&
        <div className="form-input-wrap">
            <label for="newPassword">新密碼</label>
            <div className="normal-input-field-wrap">
                <input 
                    className="normal-input-field"
                    disabled={!editMode}
                    type="password"
                    id="newPassword" 
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <div className="normal-input-error">
                {touched.password && errors.password && <ErrorText msg={errors.password}/>}
                </div>
            </div>
        </div> 
        }
        {changePasswordMode&&
        <div className="form-input-wrap">
            <label for="newPassword">確認新密碼</label>
            <div className="normal-input-field-wrap">
                <input 
                    className="normal-input-field"
                    disabled={!editMode}
                    type="password"
                    id="confirmPassword" 
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <div className="normal-input-error">
                {touched.confirmPassword && errors.confirmPassword && <ErrorText msg={errors.confirmPassword}/>}
                </div>
            </div>
        </div> 
        } */}

        {/* <div className="mat-card-foot c:col c:around c:middle">
            <Button 
                className="save-btn my-primary-btn"
                variant="contained"
                color="primary"
                // disabled={isSubmitting||!isValid}
                onClick={async ()=>{
                    // setSubmitting(true);
                    // if(user.email !== values.email){
                    //     const isAuth = await handleUpdateUserEmail(values.email);
                    //     if(isAuth){
                    //         submitForm();
                    //     }
                    // }else{
                    //     //email did not change
                    // }

                    // if(values.newPassword&&values.newPassword === values.confirmPassword){
                    //     const isAuth = await handleUpdatePassword(values.newPassword);
                    //     if(isAuth){
                    //         submitForm();
                    //     }
                    // }else{
                    //     //newPassword field is empty or not equal to confirmPassword
                    // }
                    // setSubmitting(false);
                    
                }}
            >
            <i className="f:xl material-icons-outlined mr:xs">
            save
            </i>
            儲存
            </Button>
        </div> */}
    </div>
    );
}

export default ProfileForm;