import React, { useEffect, useState, useContext } from "react";

const useWindowsWidth = (maxWidth) => {
  const [isWebView, setIsWebView] = useState(false);

  let checkScreenSize = () => {
    setIsWebView(window.innerWidth > maxWidth);
  };
  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    // console.log("checkScreenSize");
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);
  
  return isWebView;
};

export const widthContext = React.createContext();

export default useWindowsWidth;