const productionConfig = {
    apiKey: "AIzaSyCR58bSN2qbb-zObnqK_nx63U376e4lUq0",
    authDomain: "caterat-pos.firebaseapp.com",
    projectId: "caterat-pos",
    storageBucket: "caterat-pos.appspot.com",
    messagingSenderId: "1096043026743",
    appId: "1:1096043026743:web:cfce56b98068c1747ea2b2",
    measurementId: "G-VKZQ76LZYT"
};
const developmentConfig = {
    apiKey: "AIzaSyA3jv9OuDtOei4f53jl9bdihyIMHbqbiyE",
    authDomain: "caterat-pos-test.firebaseapp.com",
    projectId: "caterat-pos-test",
    storageBucket: "caterat-pos-test.appspot.com",
    messagingSenderId: "238141289809",
    appId: "1:238141289809:web:845624ebac8bc0eadb4f70",
    measurementId: "G-7LG5VXK1DB"
  };

export const remoteURL = "https://asia-east2-caterat-pos.cloudfunctions.net/api";
export const localURL = "http://localhost:5000/caterat-pos/asia-east2/api";

const production = {
    local: "http://localhost:5000/caterat-pos/asia-east2/api",
    remote: "https://asia-east2-caterat-pos.cloudfunctions.net/api",
    testPK: "pk_test_51JdUjZIvyVW1OGrHeA4fSCjwES2ZYTNEtjHsrhl3qjdrdtFZga6qLjHdIcJxUBTPoKLDT1dGZkV7kUbUREvMfZI300e7eGYRPx",
    livePk: "pk_live_51JdUjZIvyVW1OGrHxjkoY1dMlT3fbWxfaciY5nzKvnnCkWvSWIu6U9oHaGzj8dAGjT858umFOGZn78IyIvU5QNsf00xwUxFaF3"
};
const development = {
    local: "http://localhost:5000/caterat-pos-test/asia-east2/api",
    remote: "https://asia-east2-caterat-pos-test.cloudfunctions.net/api",
    testPK: "pk_test_51JdUjZIvyVW1OGrHeA4fSCjwES2ZYTNEtjHsrhl3qjdrdtFZga6qLjHdIcJxUBTPoKLDT1dGZkV7kUbUREvMfZI300e7eGYRPx",
    livePk: "pk_live_51JdUjZIvyVW1OGrHxjkoY1dMlT3fbWxfaciY5nzKvnnCkWvSWIu6U9oHaGzj8dAGjT858umFOGZn78IyIvU5QNsf00xwUxFaF3"
};
// export const stripePk = "pk_live_51JdUjZIvyVW1OGrHxjkoY1dMlT3fbWxfaciY5nzKvnnCkWvSWIu6U9oHaGzj8dAGjT858umFOGZn78IyIvU5QNsf00xwUxFaF3";
export const stripePk = process.env.REACT_APP_ENV === "development"?  
                            process.env.REACT_APP_STRIPE_MODE === "live"? development.livePk:development.testPK
                            :process.env.REACT_APP_STRIPE_MODE === "live"? production.livePk:production.testPK;

export const currentURL = process.env.REACT_APP_ENV === "development"? 
                            process.env.REACT_APP_HOST === "local"? development.local:development.remote
                            :process.env.REACT_APP_HOST === "local"? production.local:production.remote;

export const config = process.env.REACT_APP_ENV === "development"?  developmentConfig: productionConfig;