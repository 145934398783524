import React, {useState, useCallback, useEffect, useContext} from 'react';
import {useParams, useHistory, useLocation} from "react-router-dom";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import '../root.css';
import './CreateRestaurant.css';
import './Step4.css';
import {ErrorText} from '../Error';
import { isEmpty } from '../../validators/validator';
import {loadStripe} from '@stripe/stripe-js';
import { currentURL } from '../../Config';
import { FirebaseUserContext, getIdToken } from '../../hooks/Firebase';
import TermsAndConditions from './TermsAndConditions';
import Loading from '../Loading';
// import QRCode from 'qrcode.react';
import QRCode from 'qrcode';
import QRCodeImg from 'qrcode.react';

//validate stripe public key by using stripe client api
//validate secret key using our own api
const validateStripeKeys = async (publicKey = "default", secretKey="default", idToken, restaurantId) => {
    let errors={};
    let isValid = false;
    try{
        const res = await axios.post(currentURL + '/validateStripeSecret', {secretKey: secretKey});
        if(res.status === 200){
            if(res.data.success){
                const stripe = await loadStripe(publicKey, {locale: 'zh-HK'});
                const result = await stripe.retrievePaymentIntent(res.data.paymentIntents.client_secret);
                if(result.error){
                    // console.log(result.error);
                    errors.publicKey = "請填寫正確的公鑰(Publishable Key)"
                }
                if(result.paymentIntent){
                    isValid = true;
                    await axios.post(currentURL + '/updateWebOrderChanges', {publicKey: publicKey, secretKey: secretKey, restaurantId}, {headers: { Authorization: `Bearer ${idToken}`}});
                }
            }else{
                errors.secretKey = "請填寫正確的密鑰(Secret Key)"
            }
        }else{
            throw res.data.error;
        }
    }catch(err){
        errors.unknown = err;
    }

    return [ isValid, errors];
}
function Step4 ({formik, mode, isLoadingRestautantData, restaurantData, handleStep, loading, closeModal}){
    const guideAddress = "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2FstripeGuide.pdf?alt=media&token=917e40e9-59b7-4399-aa45-dd6d022277f6";
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;

    const [errors, setErrors] = useState({});
    const [publicKey, setPublicKey] = useState("");
    const [secretKey, setSecretKey] = useState("");
    const [publicKeyInput, setPublicKeyInput] = useState('');
    const [secretKeyInput, setSecretKeyInput] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [webOrderStatus, setWebOrderStatus] = useState('');
    const [refreshPage, setRefreshPage] = useState(0);
    const handleRefreshPage = ()=>{setRefreshPage(origin=>origin+1)};

    const closeDialog = useCallback(()=>setIsDialogOpen(false),[]);
    const handlePublicKey = (e) => setPublicKeyInput(e.target.value);
    const handleSecretKey = (e) => setSecretKeyInput(e.target.value);
    const { restaurantId } = useParams();
    const [qrURL, setURL] = useState(null);
    
    //load data from server
    useEffect(async ()=>{
        setIsLoadingPage(true);
        const secretResponse = await axios.post(currentURL+'/getWebOrderSecret', {restaurantId: restaurantId}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        if(secretResponse.status === 200){
            setPublicKey(secretResponse.data.secret.publicKey);
            setSecretKey(secretResponse.data.secret.secretKey);
            setWebOrderStatus(secretResponse.data.secret.isEnabledWebOrder);
            setIsLoadingPage(false);
        }
        QRCode.toDataURL(`https://order.caterat.com/restaurant/webOrder/${restaurantData.restaurantCode}`, function (err, url) {
            setURL(url);
        });
    }, []);

    //if there is any changes after the dialog box pop up, load all the data again
    useEffect(async ()=>{
        setIsLoadingPage(true);
        const secretResponse = await axios.post(currentURL+'/getWebOrderSecret', {restaurantId: restaurantId}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        if(secretResponse.status === 200){
            
            setPublicKey(secretResponse.data.secret.publicKey);
            setSecretKey(secretResponse.data.secret.secretKey);
            setWebOrderStatus(secretResponse.data.secret.isEnabledWebOrder);
            setIsLoadingPage(false);
        }
        QRCode.toDataURL(`https://order.caterat.com/restaurant/webOrder/${restaurantData.restaurantCode}`, function (err, url) {
            setURL(url);
        });
    }, [refreshPage]);

    const openTermsAndCondition = ()=>{
        setIsDialogOpen(true);
    };

    const handleValidateStripeKeys = async () => {
        setIsLoading(true);

        if (isEmpty(publicKeyInput)) {
            setErrors(origin=>({...origin, publicKey: '公鑰(Publishable Key)不能為空'}));
            setIsLoading(false);
            return;
        };
        if (isEmpty(secretKeyInput)) {
            setErrors(origin=>({...origin, secretKey: '密鑰(Secret Key)不能為空'}));
            setIsLoading(false);
            return;
        };

        const [isValid, caughtErrors] = await validateStripeKeys(publicKeyInput, secretKeyInput, userStatus.idToken, restaurantId);
        // console.log(`called validateStripeKeys`);
        if(!isValid) {
            setErrors(caughtErrors);
        }else{
            const res = await axios.post(currentURL + '/updateWebOrderChanges', {update: "key", bool: false, restaurantId}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
            if(res.status === 200){
                // setIsStripeAccountValid(true);
                setPublicKey(publicKeyInput);
                setSecretKey(secretKeyInput);
                setPublicKeyInput('');
                setSecretKeyInput('');
                setErrors({});
            }
        }  
        setIsLoading(false);
    }
    
    const openNewTab = () => {
        window.open(guideAddress);
    }

    const directToWebOrder = ()=> window.open(`https://order.caterat.com/restaurant/webOrder/${restaurantData.restaurantCode}`, "_blank");
    return (
        <div className="step4 h:full c:row">
            {isDialogOpen&&<TermsAndConditions handleRefreshPage={handleRefreshPage} closeDialog={closeDialog} webOrderStatus={webOrderStatus}/>}
            {/* <TermsAndConditions style={popUpStyle} toggleTermsAndCondition={toggleTermsAndCondition}/> */}
            {isLoadingPage? <Loading/>:
                <div className="c:row h:full">
                    <div className="c:col c:between c:middle">
                        <h3 className="f:black f:400">網上外賣自取平台</h3>
                    </div>
                    <div className="stripe-key-input-wrap c:row gap:xs flex:1 py:xs pt:md">
                        <div className="new-tab-btn c:col c:middle touch align-self-end" onClick={openNewTab}>
                            <i className="f:xl material-icons-outlined mr:xs">
                            help
                            </i>
                            <h6>
                                註冊Stripe帳號教學
                            </h6>
                        </div>
                        <div className="form-input-wrap">
                            <label id="pk-label" htmlFor="publicKey">公鑰 <br/>(Publishable Key)</label>
                            <div className="normal-input-field-wrap">
                                <input 
                                    className="normal-input-field"
                                    type="text"
                                    onChange={handlePublicKey}
                                    value={publicKeyInput}
                                    id="publicKey" 
                                    name="publicKey"
                                    disabled={isLoading}
                                />
                                <div className="normal-input-error">
                                {errors.publicKey && <ErrorText msg={errors.publicKey}/>}
                                </div>
                            </div>
                        </div> 
                        <div className="form-input-wrap">
                            <label htmlFor="secretKey">密鑰 <br/>(Secret Key)</label>
                            <div className="normal-input-field-wrap">
                                <input 
                                    className="normal-input-field"
                                    type="text"
                                    onChange={handleSecretKey}
                                    value={secretKeyInput}
                                    id="secretKey" 
                                    name="secretKey"
                                    disabled={isLoading}
                                />
                                <div className="normal-input-error">
                                {errors.secretKey && <ErrorText msg={errors.secretKey}/>}
                                </div>
                            </div>
                        </div> 
                        <div className="form-info-wrap">
                            <label>已儲存公鑰</label>
                            <h6 className="show-key">{publicKey? publicKey:"無"}</h6>
                        </div>  
                        <div className="form-info-wrap">
                            <label>已儲存密鑰</label>
                            <h6 className="show-key">{secretKey? secretKey:"無"}</h6>
                        </div>  
                        {webOrderStatus&&
                        <div>
                            <div className="web-order-link">
                                <label>外賣自取平台連結</label>
                                <h6 onClick={directToWebOrder} className="c:col c:middle show-key touch new-tab-btn">
                                <i className="f:xl material-icons-outlined mr:xs">
                                public
                                </i>
                                {`https://order.caterat.com/restaurant/webOrder/${restaurantData.restaurantCode}`}
                                </h6>
                            </div> 
                            <p className="web-order-link-reminder">需要前往POS設定菜單及開啟平台後方可使用，此網站以手機用戶體驗為優先。</p>
                        </div>
                        }
                        {webOrderStatus&&
                        <div>
                            <div className="web-order-link">
                                <label>外賣自取平台二維碼</label>
                                <div className="c:row">
                                    <QRCodeImg id="qr-code-generate" value={`https://order.caterat.com/restaurant/webOrder/${restaurantData.restaurantCode}`} />
                                    <a id="qr-code-download" href={qrURL} target="_blank" download="qrcode.png">下載二維碼</a>
                                </div>
                                {/* <div onClick={downloadQR}>dasadsd</div>  */}
                            </div> 
        
                        </div>
                        }
                        <div id="web-order-status" className="form-info-wrap">
                            <label>外賣自取平台狀態</label>
                            {webOrderStatus?
                            <h6 className="show-key success-font">已啟用</h6>
                            :<h6 className="show-key failure-font">未啟用</h6>
                            }
                        </div> 
                        <div className="form-info-wrap">
                            <label></label>
                            <div>
                            <p>每筆成功在本平台進行的線上收款，本公司會收取 HK$ 1.00* 作服務費。
                            <br/>另外，信用卡支付平台會向商戶額外收取服務費，詳情請參閱<a href="https://stripe.com/en-hk/pricing" target="_blank">Stripe官方網站</a>。
                            <br/><br/><small>*每筆信用卡收費會根據Stripe收費而釐定，而本公司每月份的網上外賣自取平台服務費會於下一個月的第一日收取。</small></p>
                            </div>
                        </div> 

                        <Button 
                            disabled={!secretKey||!publicKey||isLoading}
                            id="enable-web-order-btn"
                            type="button" 
                            color="primary" 
                            variant="contained"
                            onClick={openTermsAndCondition}> 
                            <h6 className="f:white f:600">{`${webOrderStatus?"暫停":"啟用"}網上外賣自取平台`}</h6>
                        </Button>
                    </div>

                    <div className="c:col c:between mt:xs">
                        <Button  
                            className="btn step-btn my-outlined-btn"
                            onClick={closeModal}
                            color="primary"
                            variant="outlined" 
                        >
                        {mode==="CREATE"? "取消":"返回"}
                        </Button>
                        <Button
                        className="save-btn"
                        disabled={isLoading||(!publicKeyInput&&!secretKeyInput)}
                        color="primary" 
                        variant="contained"
                        onClick={handleValidateStripeKeys}
                        >
                        {isLoading? <Loading/>:
                        <div className="c:col align-items-center">
                            <i className="f:xl material-icons-outlined mr:xs">
                            save
                            </i>
                            <span>儲存</span>
                        </div>
                        }
                        </Button>
                    </div>
                </div>
            }
    </div>
    );
}

export default Step4;