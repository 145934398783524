import React, { useState, useEffect, useMemo} from "react";
import firebase from "firebase/app";
import axios from "axios";
import {currentURL} from '../Config';


export const getIdToken = async () => {
  const idToken = await firebase.auth().currentUser.getIdToken(true);
  return idToken;
}
export const getCurrentUser = async () => {
  const currentUser = firebase.auth().currentUser;
  return currentUser;
}

export const onAuthStateChange = (setUserStatus) => {
    return firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const idToken = await firebase.auth().currentUser.getIdToken(true);
        const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
        setUserStatus({loggedIn: true, realUser: user, user: user, idToken, isAdmin: !!idTokenResult.claims.admin});
      } else {
        setUserStatus({loggedIn: false, user: null, idToken: null});
      }
    });
}

export const signOut = () => {
    firebase.auth().signOut();
}

export const useSignInWithEmailAndPassword = () => {
    const [error, setError] = useState(false);
    const [
      loggedInUser,
      setLoggedInUser,
    ] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const signInWithEmailAndPassword = async (email, password) => {
      setLoading(true);
      try {
        const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
        if(!userCredential.user.emailVerified) throw {code: "email-not-verified", message: "請前往電郵信箱查閱已發送的驗證碼以激活帳戶"};
        const idToken = await firebase.auth().currentUser.getIdToken(true);
        setLoggedInUser(userCredential.user);
        setLoading(false);
        return [{user: userCredential.user, idToken}, null];

      } catch (err) {
        setError(err);
        setLoading(false);
        return [null, {code: err.code, message: err.message}];
      }

    };
  
    const resArray = [
      signInWithEmailAndPassword,
      loggedInUser,
      loading,
      error,
    ];
    
    return useMemo(() => resArray, resArray);
  };

export const useCreateUserWithEmailAndPassword = () => {
  const [error, setError] = useState(false);
  const [
    registeredUser,
    setRegisteredUser,
  ] = useState(null);
  const [loading, setLoading] = useState(false);

  const createUserWithEmailAndPassword = async ({email, password, contactName, contactTel, companyName}) => {
    setLoading(true);
    try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const send = await userCredential.user.sendEmailVerification();
      const res = await axios.post(currentURL+'/initUser', {uid: userCredential.user.uid, email: userCredential.user.email, contactName, contactTel, companyName});

      setRegisteredUser(userCredential.user);
      setLoading(false);
      return [userCredential.user, null];

    } catch (err) {
      setError(err);
      setLoading(false);
      return [null, {code: err.code, message: err.message}];
    }

  };

  const resArray = [
    createUserWithEmailAndPassword,
    registeredUser,
    loading,
    error,
  ];
  
  return useMemo(() => resArray, resArray);
};

export const FirebaseUserContext = React.createContext();
export const TargetUserContext = React.createContext();
