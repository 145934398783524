import React, {useState, useCallback, useEffect, useContext} from 'react';
import {Field} from 'formik';
import {useParams} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '@material-ui/core/Input';
import axios from "axios";
import '../root.css';
import './Step3.css';
import { validateStep3 } from '../../validators/validator';
import { ErrorText } from '../Error';
import {currentURL} from '../../Config';
import { FirebaseUserContext, getIdToken } from '../../hooks/Firebase';
import Loading from '../Loading';

function Step3 ({formik, isDemo, mode, closeModal, handleStep}){
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;
    // const {step, prevStep, nextStep} = handleStep;
    // const {values, isSubmitting, submitForm, setFieldValue, isValid, dirty} = formik;
    const [isInputMode, setIsInputMode] = useState(false);
    const [enabledEdit, setEnabledEdit] = useState(true);
    const [saveIsOccured, setSaveIsOccured] = useState(true);
    const [managers, setManagers] = useState([]);
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [restaurantCode, setRestaurantCode] = useState("");
    const [errors, setErrors] = useState({});
    const { restaurantId } = useParams();
    // const [isDialogOpen, setIsDialogOpen] = useState(false);
    // const [dialogNotification, setDialogNotification] = useState([]);

    const toggleIsInputMode = useCallback(() => setIsInputMode(origin =>!origin), []);
    const toggleEnabledEdit= useCallback(() => setEnabledEdit(origin =>!origin), []);

    const addManagers = useCallback((newManager) => {
            setManagers(original=>[{...newManager, isHashed: false}, ...original]);
            setSaveIsOccured(false);
    }, []);
    const removeManager = useCallback((id) => {
        setManagers(original=>original.filter(manager=>manager.id !== id));
        setSaveIsOccured(false);
    }, []);
    const editManager = useCallback((id, newData)=>{
        setManagers(original=>original.map(manager=>manager.id === id? newData:manager));
        setSaveIsOccured(false);
    });

    useEffect(async () => {
        // setManagers(values.managers);
        const getAdminRestaurantResponse = await axios.post(currentURL+'/getAdminRestaurant', {restaurantId: restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        setManagers(getAdminRestaurantResponse.data.restaurant.managers);
        setRestaurantCode(getAdminRestaurantResponse.data.restaurant.restaurantCode);
        // setManagers([{name: "aaaa", username: "asdasdasdas", pin: "1234", password: "aaaaaa"}]);
        setIsLoadingPage(false);
    }, [])

    const saveManagerChanges = async () => {
        // console.log(restaurantId);
        // console.log(user.uid);

        const managersResponse = await axios.post(currentURL+'/updateManagersChanges', {restaurantId: restaurantId, managers: managers}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        if(managersResponse.status === 200){
            // setFieldValue("managers", managers);
            setSaveIsOccured(true);
        }else{
            alert("Unknown error");
        }
    }
    const directToPOSAppStore = () => window.open("https://apps.apple.com/hk/app/caterat-pos/id1589037563?l=en", "_blank");
    const directToHandheldAppStore = () => window.open("https://apps.apple.com/hk/app/caterat-pos-handheld/id1590491869?l=en", "_blank");
    const directToHandheldPlayStore = () => window.open("https://play.google.com/store/apps/details?id=com.rcubestudio.caterat_pos_handheld", "_blank");
    return (
        isLoadingPage? <Loading/>:
        <div className="step3 c:row gap:lg h:full">
            <div className="c:col c:between c:middle">
                <h3 className="f:black f:400">{isDemo? "餐廳測試帳號":"餐廳經理帳號"}</h3>
            </div>
            <div className="flex:1">
                <div className="manager-table-wrap">
                    {isDemo&&<div className="form-info-wrap">
                        <h6>*測試帳號並不支援外賣自取平台收款測試<br/>
                            *測試帳號重新登入後資料將被重設</h6>
                    </div>}
                    <div className="form-info-wrap">
                        <label htmlFor="restaurantCode" style={{width: "80px"}}>餐廳識別碼:</label>
                        <h6>{restaurantCode}</h6>
                    </div>
                    {errors.managers ? <ErrorText msg={errors.managers}/> : !isDemo&&<div style={{height: "28px"}}/>}
                    <div className="mat-card step3-table">
                        <div className="table-title border:bottom p:xs c:middle">
                            <h6>名稱</h6>
                            <h6>帳號</h6>
                            <h6>PIN碼 (4位數)</h6>
                            <h6>密碼</h6>
                            {/* {showPassword2? <h6>確認密碼</h6>:<h6></h6>} */}
                            {enabledEdit?<div></div>:<h6>確認密碼</h6>}
                            {enabledEdit&&!isDemo? 
                            <div onClick={()=>{
                                toggleIsInputMode();
                                toggleEnabledEdit();
                                }} className="touch justify-self-start pr:sm">
                                <i className="f:icon f:xl f:grey material-icons-outlined">
                                person_add
                                </i>
                            </div>:<div></div>}
                        </div>
                        {!isInputMode&&managers.length===0&&<div className="table-row"></div>}
                        {isInputMode&&
                            <Step3InputRow 
                            managers={managers} 
                            isInputMode={isInputMode}
                            toggleIsInputMode={toggleIsInputMode}
                            addManagers={addManagers}
                            enabledEdit={enabledEdit}
                            toggleEnabledEdit={toggleEnabledEdit}
                            />
                        }
                        {managers&&managers.map((manager, index)=>{
                            return (
                                <Step3TableRow 
                                isDemo={isDemo}
                                managers={managers}
                                manager={manager}
                                removeManager={removeManager}
                                editManager={editManager}
                                enabledEdit={enabledEdit}
                                toggleEnabledEdit={toggleEnabledEdit}
                                />
                                );
                        })}
                        
                        {isDemo&&
                        <div className="qr-code-wrap">
                            <h4>立即下載應用程式，體驗 Caterat POS System</h4>
                            {/* <h5>請使用 iPad 掃瞄 QR CODE 下載</h5> */}
                            <div className="">
                                <div className="qr-code-container">
                                    <div className="qr-code" style={{backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fqr-ios-pos.svg?alt=media&token=fff9f33c-4419-4f94-9f99-2a312a3a1cc4")`}}></div>
                                    <h5>Caterat POS Cashier</h5>
                                    <p>iPad Only</p>
                                    <div className="c:col c:center">
                                        <div className="download-on-appstore" onClick={directToPOSAppStore}></div>
                                    </div>
                                    {/* <div className="c:col c:middle c:center">
                                        <i className="fab fa-app-store-ios f:2xl"></i>
                                        <p>iPad Only</p>
                                    </div> */}
                                </div>
                                <div className="qr-code-container">
                                    <div className="qr-code" style={{backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fqr-ios-handheld.svg?alt=media&token=b5d42c13-e18f-46a1-b7ce-4545090b303d")`}}></div>
                                    <h5>Caterat POS Handheld</h5>
                                    <p>iPhone Only</p>
                                    <div className="c:col c:center">
                                        <div className="download-on-appstore" onClick={directToHandheldAppStore}></div>
                                    </div>
                                </div>
                                <div className="qr-code-container">
                                    <div className="qr-code" style={{backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fqr-android-handheld.svg?alt=media&token=d8a166ef-59eb-46c8-932d-51b77806da0c")`}}></div>
                                    <h5>Caterat POS Handheld</h5>
                                    <p>Android Only</p>
                                    <div className="c:col c:center">
                                        <div className="download-on-playstore" onClick={directToHandheldPlayStore}></div>
                                    </div>
                                </div>
                            </div>
                                {/* <img src="https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2Fpos_qrcode.svg?alt=media&token=fbd355ba-f807-4348-9bc2-fe733b0879e3"></img> */}
                                
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div className="c:col c:between">
                <Button
                className="step-btn my-outlined-btn"
                // disabled={!(isValid)}
                color="primary" 
                variant="outlined" 
                onClick={closeModal}
                // disabled={!(isValid && dirty)}
                >{mode==="CREATE"? "取消":"返回"}</Button>
                <Button
                className="save-btn"
                onClick={saveManagerChanges}
                disabled={saveIsOccured}
                color="primary" 
                variant="contained" 
                >
                    <i className="f:xl material-icons-outlined mr:xs">
                    save
                    </i>
                    儲存
                </Button>
            </div>
        </div>
    );
}
const Step3InputRow = ({managers, addManagers, toggleIsInputMode, toggleEnabledEdit}) => {
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState(''); 
    const [password2, setPassword2] = useState(''); 
    const [pin, setPin] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(()=>{
        setName('');
        setUsername('');
        setPassword('');
        setPassword2('');
        setPin('');
    },[]);
    
    const handlePinInput = (e) => {
        const pinInputValue = e.target.value;
        if(pinInputValue.length <=4){
            if(pinInputValue.length > 0 && RegExp(/^\d+$/).test(pinInputValue)){
                setPin(pinInputValue);
            }else if (pinInputValue.length === 0){
                setPin(pinInputValue);
            }
        }
    }

    const handleAddManager = () => {
        const [isValid, errors] = validateStep3({name, username, pin, password, password2, managers: [...managers, {username: username}]});
        if(isValid){
            addManagers({
                id: uuidv4(),
                name: name,
                username: username,
                password: password,
                pin: pin
            });
            toggleIsInputMode();
            toggleEnabledEdit();
        }else{
            setErrors(errors);
        }
    }
    return(
    <div className="table-row border:bottom px:xs align-items-center" >
        <div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                className="manager-input-row"
                name="inputName"
                value={name}
                type='text'
                onChange={(e)=>{
                    setName(e.target.value);
                }}
                autoComplete="off"
                />
            </div>
            {errors.name && <ErrorText msg={errors.name}/>}
        </div>
        <div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                className="manager-input-row"
                name="inputUsername"
                type='text'
                value={username}
                onChange={(e)=>{
                    setUsername(e.target.value);
                }}
                autoComplete="off"
                />
            </div>
            {errors.username && <ErrorText msg={errors.username}/>}
        </div>
        <div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                className="manager-input-row"
                name="inputPin"
                type="password"
                onChange={handlePinInput}
                value={pin}
                autoComplete="off"
                />
            </div>
            {errors.pin && <ErrorText msg={errors.pin}/>}
        </div>
        <div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                className="manager-input-row"
                name="inputPassword"
                value={password}
                type='password'
                onChange={(e)=>{
                    setPassword(e.target.value);
                }}
                autoComplete="off"
                />
            </div>
            {errors.password && <ErrorText msg={errors.password}/>}
        </div>
        <div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                className="manager-input-row"
                name="inputPassword2"
                value={password2}
                type='password'
                onChange={(e)=>{
                    setPassword2(e.target.value);
                }}
                autoComplete="off"
                />
            </div>
            {errors.password2 && <ErrorText msg={errors.password2}/>}
        </div>


        <div className="c:col">
            <div 
            className="touch justify-self-end"
            onClick={handleAddManager}>
                <i className="f:icon f:xl f:grey material-icons-outlined">
                done
                </i>
            </div>
            <div 
            className="touch justify-self-end pr:sm"
            onClick={()=>{
                toggleIsInputMode();
                toggleEnabledEdit();
                }}>
                <i className="f:icon f:xl f:grey material-icons-outlined">
                clear
                </i>
            </div>
        </div>
    </div>
    );
};

const Step3TableRow = ({managers, isDemo, manager, removeManager, editManager, enabledEdit, toggleEnabledEdit}) => {
    const {id, name, username, pin, password, plainText, isHashed} = manager;
    const [isThisEditable, setIsThisEditable] = useState(false);
    const [inputName, setInputName] = useState();
    const [inputUsername, setInputUsername] = useState();
    const [originalPassword, setOriginalPassword] = useState(); 
    const [inputPassword, setInputPassword] = useState(); 
    const [inputPassword2, setInputPassword2] = useState(); 
    const [showPassword2, setShowPassword2] = useState(false);
    const [inputPin, setInputPin] = useState(); 
    const [errors, setErrors] = useState({});

    useEffect(()=>{
        setInputName(name);
        setInputUsername(username);
        isHashed? setInputPassword("hashed"):setInputPassword(password);
        setInputPin(pin);
        setInputPassword2('');
    },[manager]);

    useEffect(()=>{
        if(isThisEditable&&isHashed){
            setInputPassword('');
        }
        if(!isThisEditable&&isHashed){
            // setInputPassword('••••••');
            setInputPassword('hashed');
        }
    },[isThisEditable]);
    const toggleEditable = () => setIsThisEditable(origin=>!origin);
    const handlePinInput = (e) => {
        const pinInputValue = e.target.value;
        if(pinInputValue.length <=4){
            if(pinInputValue.length > 0 && RegExp(/^\d+$/).test(pinInputValue)){
                setInputPin(pinInputValue);
            }else if (pinInputValue.length === 0){
                setInputPin(pinInputValue);
            }
        }
    }
    const handlePasswordInput = (e) => {
        setShowPassword2(true);
        setInputPassword(e.target.value);
    }
    const cancelEdit = () => {
        setInputName(name);
        setInputUsername(username);
        isHashed? setInputPassword("hashed"):setInputPassword(password);
        setInputPin(pin);
        setInputPassword2('');
        setErrors({});
    }
    const handleEditManager = () => {
        // console.log(inputUsername===username);
        const [isValid, errors] = validateStep3({
            name: inputUsername===name? name:inputName,
            username: inputUsername===username? username:inputUsername, 
            password: inputPassword===password? password:inputPassword, 
            password2: inputPassword===password? password:inputPassword2, 
            pin: inputPin===pin? pin:inputPin, 
            managers: [...managers, inputUsername!==username&&{username: inputUsername}]
        });

        if(isValid){
            editManager(id, {
                id: id,
                name: inputName,
                username: inputUsername,
                password: inputPassword,
                pin: inputPin,
                isHashed: inputPassword===""
                // isHashed: inputPassword!==""
            });
            setErrors({});
            toggleEditable();
            setShowPassword2(false);
            toggleEnabledEdit();
        }else{
            setErrors(errors);
        }
    }
    return(
    <div key={`manager-row-${id}`} className="table-row border:bottom p:xs align-items-center">
        <div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                className={`manager-input-row ${isDemo&&"isDemo"}`}
                name="inputName"
                disabled={!isThisEditable}
                type='text'
                onChange={(e)=>{
                    setInputName(e.target.value);
                }}
                value={inputName}
                autoComplete="off"
                />
            </div>
            {errors.name && <ErrorText msg={errors.name}/>}
        </div>
         <div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                className={`manager-input-row ${isDemo&&"isDemo"}`}
                name="inputUsername"
                disabled={!isThisEditable}
                type='text'
                disabled
                value={inputUsername}
                autoComplete="off"
                />
            </div>
            {errors.username && <ErrorText msg={errors.username}/>}
        </div>
        <div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                disabled={!isThisEditable}
                className={`manager-input-row ${isDemo&&"isDemo"}`}
                name="inputPin"
                type={isDemo?"text":"password"}
                onChange={handlePinInput}
                value={inputPin}
                autoComplete="off"
                />
            </div>
            {errors.pin && <ErrorText msg={errors.pin}/>}
        </div>
        <div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                disabled={!isThisEditable}
                className={`manager-input-row ${isDemo&&"isDemo"}`}
                name="inputPassword"
                type={isDemo?"text":"password"}
                onChange={(e)=>{
                    handlePasswordInput(e);
                }}
                value={isDemo? plainText:inputPassword}
                autoComplete="off"
                />
            </div>
            {errors.password && <ErrorText msg={errors.password}/>}
        </div>
        {/* {showPassword2?<div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                disabled={!isThisEditable}
                className="manager-input-row"
                name="inputPassword2"
                type='password'
                onChange={(e)=>{
                    setInputPassword2(e.target.value);
                }}
                value={inputPassword2}
                autoComplete="off"
                />
            </div>
            {errors.password2 && <ErrorText msg={errors.password2}/>}
        </div>
        :<div></div>
        } */}
        {isThisEditable?
        <div className="input-row h:full">
            <div className="c:col align-items-end">
                <Input
                disabled={!isThisEditable}
                className="manager-input-row"
                name="inputPassword2"
                type='password'
                onChange={(e)=>{
                    setInputPassword2(e.target.value);
                }}
                value={inputPassword2}
                autoComplete="off"
                />
            </div>
            {errors.password2 && <ErrorText msg={errors.password2}/>}
        </div>
        :<div></div>
        }
        <div className="c:col">
            {!enabledEdit&&isThisEditable&&<div 
                className="touch justify-self-end"
                onClick={handleEditManager}>
                <i className="f:icon f:xl f:grey material-icons-outlined">
                    done
                </i>
            </div>}
            {enabledEdit&&!isThisEditable&&!isDemo&&<div 
                className="touch justify-self-end"
                onClick={()=>{
                    toggleEditable();
                    toggleEnabledEdit();
                }}>
                <i className="f:icon f:xl f:grey material-icons-outlined">
                edit
                </i>
            </div>}
            {isThisEditable&&!isDemo&&<div 
            className="touch justify-self-end"
            onClick={()=>{
                toggleEditable();
                setShowPassword2(false);
                cancelEdit();
                toggleEnabledEdit();
                }}>
                <i className="f:icon f:xl f:grey material-icons-outlined">
                clear
                </i>
            </div>}
            {enabledEdit&&!isDemo&&<div 
            className="touch justify-self-end pr:sm"
            onClick={()=>{
                removeManager(id);
                }}>
                <i className="f:icon f:xl f:grey material-icons-outlined">
                delete
                </i>
            </div>}
        </div>
        {/* <div 
        className="justify-self-end touch pr:sm" 
        onClick={()=>removeManager(id)}>
            <i className="f:icon f:xl f:grey material-icons-outlined">
                delete
            </i>
        </div> */}
    </div>
    );
}

export default Step3;