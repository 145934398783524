import React, {useState, useCallback} from "react";

const useOverlay = () => {
    const [isOverlayOpen, setIsOverlay] = useState(false);

    const toggleOverlay = useCallback(() => {setIsOverlay((isOverlay)=>!isOverlay)}, []);
    
    return [isOverlayOpen, toggleOverlay];
};

export const OverlayContext = React.createContext();

export default useOverlay;