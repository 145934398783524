import {useHistory, useLocation, useParams} from 'react-router-dom';
import React, {useState, useCallback, useEffect, useContext} from 'react';
import { Formik, Form} from 'formik';
import {
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement,
    CardElement,
    useStripe, 
    useElements,
    Elements
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
    Route,
} from "react-router-dom";
import axios from 'axios';
// import '../creatProj/CreateProj.css';
import Step1 from './Step1';
import Step2, {CreateStripe} from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import BillingDetail from './BillingDetail';
import {OverlayContext} from '../../hooks/useOverlay';
import { FirebaseUserContext, TargetUserContext, getIdToken } from '../../hooks/Firebase';
import {currentURL, stripePk} from '../../Config';
import {validateStep1} from '../../validators/validator';
import {validateStep2} from '../../validators/validator';
import '../root.css';
import './CreateRestaurant.css';
import { getRestaurantSubscriptionInfo } from '../../apis/restaurantAPI';

function CreateRestaurant({toggleIsUpdated}){
    const history = useHistory();
    const location = useLocation();
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;
    const isOverlayOpen = useContext(OverlayContext);
    const [mode, setMode] = useState("CREATE");
    const [step, setStep] = useState('1');
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingRestautantData, setIsLoadingRestautantData] = useState(false);
    // const [subscriptionDetail, setSubscriptionDetail] = useState(false);
    const [subscriptionPlans, setSubscriptionPlans] = useState(false);
    const [restaurantData, setRestaurantData] = useState(false);
    const [initValues, setInitValues] = useState({
        restaurantName: '',
        restaurantCode: '',
        cuisineTags: [],
        managers: [],
        address1: '',
        address2: '',
        address3: '',
        tel: '',
        restaurantCode: '',
        discountCode: '',
    });
    const { restaurantId } = useParams();
    const stripePromise = loadStripe(stripePk, {locale: 'zh-HK'});

    const updateRestaurantData = useCallback((newData) => setRestaurantData(origin=>({...origin, ...newData})), []);
    const loading = useCallback((bool) => setIsLoading(bool), []);
    const resetStep = useCallback(() => setStep('1'), []);
    const prevStep = useCallback(() => setStep((step)=>`${parseInt(step)-1}`), []);
    const nextStep = useCallback(() => setStep((step)=>`${parseInt(step)+1}`), []);
    const tab2step = (e) => setStep(e.target.value);
    const handleStep = {step, prevStep, nextStep};

    const loadingStyle = {
        display: isLoading?'block':'none'
    };

    // useEffect();
    useEffect(() => {
        setStep('1');
    }, [isOverlayOpen]);

    useEffect(async ()=>{
        const res = await axios.get(currentURL+'/getAllSubcriptionPlans');
        // console.log("called getAllSubcriptionPlans");
        if(res.status === 200){
            setSubscriptionPlans(res.data.plans.filter(obj=>!obj.isMetered));
        }
    }, []);

    useEffect(async ()=>{
        if(location.state.isEditMode){
            toggleIsUpdated();
            setIsLoadingRestautantData(true);
            const data = location.state.restaurant;
            setInitValues({
                restaurantName: data.restaurantName,
                restaurantCode: data.restaurantCode,
                cuisineTags: [...data.cuisineTags],
                managers: [...data.managers],
                tel: data.tel? data.tel:'',
                address1: data.address1,
                address2: data.address2,
                address3: data.address3,
                selectedPlanId: data.selectedPlanId,
            });
            setMode("EDIT");
            // if(!location.state.restaurant.isDemo&&!location.state.restaurant.isFree){
            //     // console.log("useEffect");
            //     const [result, getSubscriptionInfoErrors] = await getRestaurantSubscriptionInfo({restaurantId: data.restaurantId, idToken: userStatus.idToken});
            //     if(!getSubscriptionInfoErrors){
            //         setRestaurantData({...data, 
            //             subscriptionInfo: result, 
            //         });
            //     }else{
            //         setRestaurantData(data);
            //     }
            // }else{
            //     setRestaurantData(data);
            // }
            setRestaurantData(data);
            
            setIsLoadingRestautantData(false);
        }
    }, [location]);

    // const updateSubscriptionInfo = useCallback(async (aId, rId) => {
    //     // console.log(`updateSubscriptionInfo ${rId}`);
    //     setIsLoadingRestautantData(true);
    //     const [result, getSubscriptionInfoErrors] = await getRestaurantSubscriptionInfo({restaurantId: rId, idToken: userStatus.idToken});
    //     if(!getSubscriptionInfoErrors){
    //         setRestaurantData(origin=>({...result.restaurantData, subscriptionInfo: result}));
    //     }
    //     setIsLoadingRestautantData(false);
    // },[]);

    const loadingRestautantDataAfterCreate = useCallback(async (restaurantId) => {
        setIsLoadingRestautantData(true);
        // console.log(location);
        const getAdminRestaurantResponse = await axios.post(currentURL+'/getAdminRestaurant', {restaurantId: restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        // console.log("called getAdminRestaurant");
        if(getAdminRestaurantResponse.status === 200){
            const data = getAdminRestaurantResponse.data.restaurant;
            setInitValues({
                restaurantName: data.restaurantName,
                restaurantCode: data.restaurantCode,
                cuisineTags: [...data.cuisineTags],
                managers: [...data.managers],
                tel: data.tel? data.tel:'',
                address1: data.address1,
                address2: data.address2,
                address3: data.address3,
                selectedPlanId: data.selectedPlanId
            });
            setMode("EDIT");
            setStep('3');
            // if(!data.isDemo&&!data.isFree){
            //     const [result, getSubscriptionInfoErrors] = await getRestaurantSubscriptionInfo({restaurantId: data.restaurantId, idToken: userStatus.idToken});
            //     if(!getSubscriptionInfoErrors){
            //         setRestaurantData({
            //             ...data, 
            //             subscriptionInfo: result, 
            //         });
            //     }else{
            //         setRestaurantData(data);
            //     }
            // }else{
            //     setRestaurantData(data);
            // }
            setRestaurantData(data);
            setIsLoadingRestautantData(false);
        }else{
            // console.log(getAdminRestaurantResponse.data.error);
            setIsLoadingRestautantData(false);
            closeModal();
        }
    },[]);
    
    if (!userStatus.loggedIn) {
        return(
        <div>
            Loading
        </div>
        );
    }

    const closeModal = e => {
        e.stopPropagation();
        e.preventDefault();
        history.push("/home");
        toggleIsUpdated();
    };
    const saveModal = e => {
        e.stopPropagation();
        e.preventDefault();
        toggleIsUpdated();
    };

    return(
    <div>
        <div className="loading" style={{...loadingStyle, zIndex: "1000"}} />
        <div className="dialog-backdrop" style={{...loadingStyle, zIndex: "900"}}/>
        <div onClick={closeModal} className="modal-backdrop" style={{display: "block"}}/>
        <div className="modal-container" style={{display: "block"}}>
            {/* <div className="modal-control-bar">
                <div onChange={closeModal} className="button modal-close-btn">
                    <i className="f:xl material-icons-outlined">
                    close
                    </i>
                </div>
            </div> */}
            <div className="modal-wrap c:row ">
                {mode==="CREATE"?
                <div style={{height: "40px"}}/>
                :location.state.restaurant?.isDemo?
                <div className="c:col">
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab1" name="tab" className="tab" value={'1'} onChange={tab2step} checked={step==='1'}/>
                        <label className="tab-label first-label" htmlFor="tab1">基本設定</label>
                    </div>
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab3" name="tab" className="tab" value={'3'} onChange={tab2step} checked={step==='3'}/>
                        <label className="tab-label last-label" htmlFor="tab3">測試帳號</label>
                    </div>
                </div>
                :location.state.restaurant?.isFree?
                <div className="c:col">
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab1" name="tab" className="tab" value={'1'} onChange={tab2step} checked={step==='1'}/>
                        <label className="tab-label first-label" htmlFor="tab1">基本設定</label>
                    </div>
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab2" name="tab" className="tab" value={'2'} onChange={tab2step} checked={step==='2'}/>
                        <label className="tab-label" htmlFor="tab2">訂閱計劃</label>
                    </div>
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab3" name="tab" className="tab" value={'3'} onChange={tab2step} checked={step==='3'}/>
                        <label className="tab-label" htmlFor="tab3">經理帳號</label>
                    </div>
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab4" name="tab" className="tab" value={'4'} onChange={tab2step} checked={step==='4'}/>
                        <label className="tab-label" htmlFor="tab4">網上外賣自取平台</label>
                    </div>
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab5" name="tab" className="tab" value={'5'} onChange={tab2step} checked={step==='5'}/>
                        <label className="tab-label last-label" htmlFor="tab5"> 刪除餐廳</label>
                    </div>
                </div>
                :<div className="c:col">
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab1" name="tab" className="tab" value={'1'} onChange={tab2step} checked={step==='1'}/>
                        <label className="tab-label first-label" htmlFor="tab1">基本設定</label>
                    </div>
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab2" name="tab" className="tab" value={'2'} onChange={tab2step} checked={step==='2'}/>
                        <label className="tab-label" htmlFor="tab2">訂閱計劃</label>
                    </div>
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab3" name="tab" className="tab" value={'3'} onChange={tab2step} checked={step==='3'}/>
                        <label className="tab-label" htmlFor="tab3">經理帳號</label>
                    </div>
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab4" name="tab" className="tab" value={'4'} onChange={tab2step} checked={step==='4'}/>
                        <label className="tab-label" htmlFor="tab4">網上外賣自取平台</label>
                    </div>
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab6" name="tab" className="tab" value={'6'} onChange={tab2step} checked={step==='6'}/>
                        <label className="tab-label" htmlFor="tab6">帳單詳情</label>
                    </div>
                    <div className="tab-wrap flex:1">
                        <input type="radio" id="tab5" name="tab" className="tab" value={'5'} onChange={tab2step} checked={step==='5'}/>
                        <label className="tab-label last-label" htmlFor="tab5"> 刪除餐廳</label>
                    </div>
                </div>
                }
                <div className="modal-content c:row">
                <Elements stripe={stripePromise}>
                {/* actually there is no reason to use formik anymore */}
                <Formik
                initialValues={initValues}
                enableReinitialize={true}
                validate={(values)=>{
                    
                    const [, step1Errors] = validateStep1(values);
                    return {...step1Errors};
                }}
                onSubmit= {async (e, values, {setSubmitting, resetForm }) => {
                }}
                >
                {(formik) => (
                    <Form className="h:full">
                        <Elements stripe={stripePromise}>
                        {/* <FormHeader resetStep={resetStep} resetForm={formik.resetForm} closeModal={closeModal} step={step}/> */}
                            {{
                            '1': <Step1 isDemo={mode==="CREATE"? false:location.state.restaurant.isDemo} closeModal={closeModal} saveModal={saveModal} isLoadingRestautantData={isLoadingRestautantData} updateRestaurantData={updateRestaurantData} restaurantData={restaurantData} mode={mode} formik={formik} handleStep={handleStep} loading={loading}/>,
                            '2': <Step2 
                                    isLoadingRestautantData={isLoadingRestautantData} 
                                    closeModal={closeModal} 
                                    saveModal={saveModal}
                                    updateRestaurantData={updateRestaurantData} 
                                    loadingRestautantDataAfterCreate={loadingRestautantDataAfterCreate}
                                    restaurantData={restaurantData} 
                                    mode={mode} 
                                    stripePromise={stripePromise} 
                                    subscriptionPlans={subscriptionPlans} 
                                    formik={formik} 
                                    // updateSubscriptionInfo={updateSubscriptionInfo}
                                    handleStep={handleStep}
                                    loading={loading}/>,
                            '3': <Step3 isDemo={mode==="CREATE"? false:location.state.restaurant.isDemo} mode={mode} closeModal={closeModal} saveModal={saveModal} formik={formik} handleStep={handleStep} loading={loading}/>,
                            '4': <Step4 
                                    mode={mode} 
                                    isLoadingRestautantData={isLoadingRestautantData} 
                                    restaurantData={restaurantData} 
                                    closeModal={closeModal} 
                                    saveModal={saveModal}
                                    formik={formik} 
                                    handleStep={handleStep} 
                                    loading={loading}/>,
                            '5': <Step5 
                                    mode={mode} 
                                    isLoadingRestautantData={isLoadingRestautantData} 
                                    restaurantData={restaurantData} 
                                    closeModal={closeModal} 
                                    saveModal={saveModal}
                                    />,
                            '6': <BillingDetail 
                                    mode={mode} 
                                    isLoadingRestautantData={isLoadingRestautantData} 
                                    restaurantData={restaurantData} 
                                    closeModal={closeModal} 
                                    saveModal={saveModal}
                                    />,
                            }[step] || <div/>}
                        </Elements>
                    </Form>

                )}
                </Formik>
                </Elements>
                </div>
            </div>
        </div>
    </div>
    );
}

const FormHeader = React.memo(({resetForm, resetStep, closeModal, step}) => {
    return (
    <div className="overlay-head c:col c:middle">
        <div onChange={(e)=>{
            closeModal(e);
            resetForm();
            resetStep();
            }}  className="btn">
            <i className="f:icon f:2xl f:400 f:grey material-icons-outlined py:sm">
                close
            </i>
        </div>
        <h4 className="f:400"> 
            Create a restaurant (Step {step} of 3)
        </h4>
    </div>
    );
});


export default CreateRestaurant;