import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import firebase from "firebase/app";
import { FirebaseUserContext, getIdToken } from '../hooks/Firebase';
import {currentURL} from '../Config';

axios.defaults.baseURL = currentURL;

/**
 fixed :
  - no need to JSON.stringify to then immediatly do a JSON.parse
  - don't use export defaults, because default imports are hard to search for
  - axios already support generic request in one parameter, no need to call specialized ones
**/
export const useAxios = ({ url, method, data=null, headers = null}, source=null) => {
    const userStatus = useContext(FirebaseUserContext);
    const [response, setResponse] = useState(undefined);
    const [requestError, setRequestError] = useState('');
    const [isLoadingRequest, setIsLoadingRequest] = useState(true);

    
    const fetchData = async (params) => {
        // console.log(`called ${url}`);
        try {
            const result = await axios.request(params);
            setResponse(result.data);
       } catch( error ) {
            setRequestError(error);
       } finally {
            setIsLoadingRequest(false);
       }
    };

    useEffect(async () => {
        
        await fetchData({ url, method, data: {...data}, headers: { Authorization: `Bearer ${await getIdToken()}`}});

    }, [source]); // execute only when the source change

    return {response, requestError, isLoadingRequest};
};