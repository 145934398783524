import {useHistory} from 'react-router-dom';
import React, {useState, useCallback, useEffect, useContext} from 'react';
import { Route, useParams} from "react-router-dom";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import {OverlayContext} from '../../hooks/useOverlay';
import { FirebaseUserContext, getIdToken } from '../../hooks/Firebase';
import {currentURL} from '../../Config';
import Terms from '../terms/Terms';
import '../root.css';
import './CreateRestaurant.css';
import './TermsAndConditions.css';

function TermsAndConditions({closeDialog, webOrderStatus, handleRefreshPage}){
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;
    const adminId = user.uid;
    const [agreeTerms, setAgreeTerms] = useState(false);
    const toggleAgreeTerms = () => setAgreeTerms(origin=>!origin);
    const { restaurantId } = useParams();
    
    const confirm = async (e) => {
        try{
            if(agreeTerms&&webOrderStatus!==''){
                const res = await axios.post(currentURL + '/updateWebOrderChanges', {update: "status", bool: !webOrderStatus, restaurantId}, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                if(res.status === 200){
                    alert(`網上外賣平台已${webOrderStatus?"暫停":"啟用成功"}`);
                    //refresh the page after changing web order status
                    handleRefreshPage();
                    closeDialog();
                }
            }
        }catch (err) {
            alert("未知錯誤，請稍後再嘗試");
            closeDialog();
        }
    };
    const cancel = (e) => {
        closeDialog();
    }


    return(
    <div>
        <div className="modal-backdrop" style={{display: "block"}}/>
        <div id="terms-and-conditions" className="modal-container" style={{display: "block",}}>
            <div className="modal-wrap c:row ">
                <div id="terms-content" className="modal-content privacy c:row p:xs c:between">
                    <Terms/>
                    <div className="c:col c:center c:middle mt:md">
                        <input 
                        type="radio" 
                        id="web-terms-confirmation" 
                        name="terms-confirmation"
                        onClick={toggleAgreeTerms}

                        checked={agreeTerms}
                        ></input>
                        <p>本人已詳細閱讀及瞭解本網站使用條款，亦同意受有關條款及細則所約束</p>
                    </div>
                </div>
                <div className="modal-foot c:col c:around p:xs">
                    <Button
                        className="btn step-btn"
                        color="primary"
                        variant="outlined" 
                        onClick={cancel}>
                    取消
                    </Button>
                    <Button
                        disabled={!agreeTerms}
                        className="btn step-btn"
                        color="primary"
                        variant="contained" 
                        onClick={confirm}>
                    {`確認${webOrderStatus?"暫停":"啟用"}`}
                    </Button>
                </div>
            </div>
        </div>
    </div>
    );
}



export default TermsAndConditions;