import React, {useState, useCallback, useEffect,useContext} from 'react';
import axios from "axios";
import { useHistory, useLocation, useParams} from "react-router-dom";
import {
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement,
    CardElement,
    useStripe, 
    useElements,
    Elements
} from '@stripe/react-stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {loadStripe} from '@stripe/stripe-js';
import {Field} from 'formik';
import  { Redirect } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import date from 'date-and-time';
import Button from '@material-ui/core/Button';
import {v4 as uuidv4} from 'uuid';
import '../root.css';
import './Step2.css';
import '../restaurantCard.js/RestaurantCard.css';

import {ErrorText} from '../Error';
import Loading from '../Loading';
import { SuccessText } from '../Success';
import {currentURL} from '../../Config';
import { FirebaseUserContext, getIdToken } from '../../hooks/Firebase';
import ConfirmChangesDialog from './ConfirmChangesDialog';
import EditSubscription from './EditSubscription';
import EditCreditCard from './EditCreditCard';
import { getRestaurantSubscriptionInfo } from '../../apis/restaurantAPI';

function fixed(value, precision) {
    return parseFloat(Number.parseFloat(value).toFixed(precision));
}

function Step2 ({mode, closeModal, restaurantData={}, loadingRestautantDataAfterCreate, isLoadingRestautantData, formik, handleStep, loading, subscriptionPlans}){
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;
    const {restaurantId} = useParams();
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [nextPaymentDate, setNextPaymentDate] = useState(false);
    // const [subscriptionDetail, setSubscriptionDetail] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(false);
    const [discountCodeError, setDiscountCodeError] = useState("");
    const [discountCodeDescription, setDiscountCodeDescription] = useState(false);
    const [discountCode, setDiscountCode] = useState(false);
    const [isFree, setIsFree] = useState(false);
    const [isFreeRestaurantEdit, setIsFreeRestaurantEdit] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isEditCreditCardDialogOpen, setIsEditCreditCardDialogOpen] = useState(false);
    const closeEditDialog = useCallback(()=>setIsEditDialogOpen(false),[]);
    const closeEditCreditCardDialog = useCallback(()=>setIsEditCreditCardDialogOpen(false),[]);
    const [dialogNotification, setDialogNotification] = useState([]);
    const closeDialog = useCallback(()=>setIsDialogOpen(false),[]);
    const [updateCounter, setUpdateCounter] = useState(0);
    const updatePageInfo = _ => setUpdateCounter(x=>x+1);
    const [agreeTerms, setAgreeTerms] = useState(false);

    const [cardInfo, setCardInfo] = useState(null);
    const [upcoming, setUpcoming] = useState(null);
    const [billing, setBilling] = useState(null);
    const toggleAgreeTerms = () => setAgreeTerms(origin=>!origin);
    const {errors, values, setTouched, handleChange, handleBlur, submitForm, isSubmitting, setFieldValue, isValid, dirty, touched} = formik;
    const cardStyle = {
        "chosen": {backgroundColor: 'teal'},
    };
    const history = useHistory();
    const location = useLocation();
    const stripe = useStripe();
    const elements = useElements();
    const cardElement = elements.getElement(CardElement);

    // let currentURL = "http://localhost:5000/foodorderingsystem-5bcdb/us-central1/api";
    // const [isValidCard, setIsValidCard] = useState(false);
    const [cardComplete, setCardComplete] = useState(false);
    const [cardInputError, setCardInputError] = useState(false);
    // const subscriptionDetail = restaurantData.subscriptionDetail;
    useEffect(async()=>{
        try{
            if(mode==="CREATE") setIsLoadingPage(false);
            if(mode==="EDIT"){
                const cardResponse = await axios.post(currentURL+'/getSubscriptionCardInfo', {restaurantId: restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                const billingHistoryResponse = await axios.post(currentURL+'/getBillingHistory', {restaurantId: restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                const upcomingBillingResponse = await axios.post(currentURL+'/getUpcomingBilling', {restaurantId: restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        
                setCardInfo(cardResponse.data.card);
                setBilling(billingHistoryResponse.data.billings.find(obj=>obj.usage_type==="licensed"));
                setUpcoming(upcomingBillingResponse.data);
                setIsLoadingPage(false);
            }
        }catch(error){
            alert(error);
        }
    }, []);
    useEffect(async()=>{
        try{
            setIsLoadingPage(true);
            if(mode==="CREATE") setIsLoadingPage(false);
            if(mode==="EDIT"){
                const cardResponse = await axios.post(currentURL+'/getSubscriptionCardInfo', {restaurantId: restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                const billingHistoryResponse = await axios.post(currentURL+'/getBillingHistory', {restaurantId: restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                const upcomingBillingResponse = await axios.post(currentURL+'/getUpcomingBilling', {restaurantId: restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        
                setCardInfo(cardResponse.data.card);
                setBilling(billingHistoryResponse.data.billings.find(obj=>obj.usage_type==="licensed"));
                setUpcoming(upcomingBillingResponse.data);
                setIsLoadingPage(false);
            }
        }catch(error){
            alert(error);
        }
    }, [updateCounter]);
    useEffect(async ()=>{
        if(mode==="CREATE"){
            if(values.selectedPlanId){
                const matchedPlan = subscriptionPlans.find(obj=>obj.id===values.selectedPlanId);
                setSelectedPlan(matchedPlan);

                // const today = new Date();
                // const year = today.getFullYear();
                // const month = today.getMonth();
                // const day = today.getDate();

                const now = new Date();
                if(matchedPlan.interval === "year"){
                    // const paymentDate = new Date(year+1, month, day);
                    const nextYear = date.addYears(now, 1);
                    setNextPaymentDate(date.format(nextYear, 'YYYY-MM-DD'));
                }
                if(matchedPlan.interval === "month"){
                    // const paymentDate = new Date(year+1, month, day);
                    const nextMonth = date.addMonths(now, 1);
                    setNextPaymentDate(date.format(nextMonth, 'YYYY-MM-DD'));
                }
            }
        }
        
    },[values]);

    const handleSaveChanges = () => {
        setIsDialogOpen();
    }

    const handleChosenPlan = (planId) => {
        if(planId === values.selectedPlanId){
            setFieldValue("selectedPlanId", "");
        }else{
            setFieldValue("selectedPlanId", planId);
        }
    };

    const handleDiscountCode = async (e) => {
        try{
            if(e.target.value !== ''){
                const discountCodeResponse = await axios.post(currentURL + '/validateDiscountCode', {
                    discountCode: e.target.value,
                });
    
                if(discountCodeResponse.status === 200){
                    if(discountCodeResponse.data.failure){
                        setDiscountCodeError({discountCode: "無效優惠碼"});
                        setDiscountCodeDescription(false);
                        setDiscountCode(false);
                    }
                    if(discountCodeResponse.data.success){
                        if(discountCodeResponse.data.isFree) setIsFree(true);
                        if(!discountCodeResponse.data.isFree) setIsFree(false);
                        setDiscountCodeDescription(discountCodeResponse.data.description);
                        setDiscountCode(e.target.value);
                        setDiscountCodeError({});
                    }
                }
            }else{
                setDiscountCodeError({});
                setDiscountCodeDescription(false);
                setDiscountCode(false);
            }
            
        }catch(err){

            // setDiscountCodeError({discountCode: err});
            setDiscountCodeDescription(false);
            setDiscountCode(false);
        }
    };
    const handleCancelSubscription = async () => {
        const cancelSubscriptionResponse = await axios.post(currentURL + '/cancelSubscriptionPlan', {
            restaurantId: restaurantId,
            subscriptionId: restaurantData.subscriptionId,
            meteredSubscriptionId: restaurantData?.meteredSubscriptionId,
        }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});
        return new Promise((resolve, reject)=>{
            if(cancelSubscriptionResponse.status === 200){
                // updateSubscriptionInfo(user.uid, restaurantData.restaurantId);
                updatePageInfo();
                resolve(true);
            }else{
                reject(cancelSubscriptionResponse.data.error);
            }
        });
    }


    const handleCreate = async () => {
        try{
            if (!stripe || !elements) {
                return;
            }
    
            // console.log(cardElement);
            cardElement.update({disabled: true});
            if (cardComplete) {
                loading(true);
            }
            
            // Use your card Element with other Stripe.js APIs
            const payload = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (payload.error) {
                // console.log(payload.error);
                setCardInputError(payload.error);
                loading(false);
                cardElement.update({disabled: false});
                return;
            } else {
                setCardInputError(false);
            }

            // const intent_secret = await axios.post(currentURL + '/createCustomerAndSubscription', {
            //     restaurantName: values.restaurantName,
            //     contactEmail: user.email,
            //     adminId: user.uid,
            //     planId: values.selectedPlanId,
            //     discountCode: values.discountCode
            // });
            const intent_secret = await axios.post(currentURL + '/createRestaurantIntent', {
                restaurantName: values.restaurantName,
                contactEmail: user.email,
                planId: values.selectedPlanId,
                discountCode: values.discountCode,
            }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});
            const clientSecret = intent_secret.data.clientSecret;
            const restaurantId = intent_secret.data.restaurantId;
            const subscription = intent_secret.data.subscription;
            // const meteredSubscriptionId = intent_secret.data.meteredSubscriptionId;
            // const meteredItemId = intent_secret.data.meteredItemId;
            const product = intent_secret.data.product;
            const customer = intent_secret.data.customer;
            const price = intent_secret.data.price;
            const promotionCode = intent_secret.data.promotionCode;
            const interval = intent_secret.data.interval;
            // const invoiceId = intent_secret.data.invoiceId;
            const paymentIntentId = intent_secret.data.paymentIntentId;
            if(intent_secret.data.clientSecretType === "paymentIntent"){
                const confirmPaymentRes = await stripe.confirmCardPayment(clientSecret,{
                    receipt_email: values.contactEmail,
                    payment_method: {card: cardElement}
                });
                if (confirmPaymentRes.error) {
                    setCardInputError(confirmPaymentRes.error);
                    cardElement.update({disabled: false});
                    await axios.post(currentURL + '/handleUnsuccessPayment', {
                        subscriptionId: null,
                        restaurantId
                    }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});
                    throw confirmPaymentRes.error;
                }
                if (confirmPaymentRes.paymentIntent.status === 'succeeded') {
                    const createRestaurantResponse = await axios.post(currentURL + '/createRestaurant', {
                        values: values,
                        isSetupIntent: false,
                        paymentMethodId: confirmPaymentRes.paymentIntent.payment_method,
                        contactEmail: user.email,
                        promotionCode,
                        restaurantId,
                        price,
                        customer,
                        product,
                        subscription,
                        paymentIntentId,
                        interval
                    }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});
                    // console.log("called createRestaurant");
                    if(createRestaurantResponse.status===200){
                        alert("餐廳創建成功！");
                        loadingRestautantDataAfterCreate(restaurantId);
                        history.replace({
                            pathname: `/home/createRestaurant/${restaurantId}`,
                            state: {background: { pathname: "/home" }, restaurant:{isDemo:false, isFree: false}}
                        });
                    }
                }
            }
            if(intent_secret.data.clientSecretType === "setupIntent"){
                //if intent_secret.data.clientSecret is false, meaning that customer credit card no need to be charge immediately, 
                //use setupIntent to confirm his card htmlFor future use.
                const confirmCardSetUpRes = await stripe.confirmCardSetup(clientSecret,{
                    receipt_email: values.contactEmail,
                    payment_method: {
                        card: cardElement,
                        billing_details: {
                            email: values.contactEmail
                        }
                    }
                });
                if (confirmCardSetUpRes.error) {
                    setCardInputError(confirmCardSetUpRes.error);
                    cardElement.update({disabled: false});
                    await axios.post(currentURL + '/handleUnsuccessPayment', {
                        subscriptionId: subscription.id,
                        restaurantId
                    }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});
                    throw confirmCardSetUpRes.error;
                }
                if (confirmCardSetUpRes.setupIntent.status === 'succeeded') {
                    const createRestaurantResponse = await axios.post(currentURL + '/createRestaurant', {
                        values: values,
                        isSetupIntent: true,
                        paymentMethodId: confirmCardSetUpRes.setupIntent.payment_method,
                        contactEmail: user.email,
                        promotionCode,
                        restaurantId,
                        price,
                        customer,
                        product,
                        subscription,
                        paymentIntentId,
                        interval
                    }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});
                    // console.log("called createRestaurant");
                    if(createRestaurantResponse.status===200){
                        alert("餐廳創建成功！");
                        loadingRestautantDataAfterCreate(restaurantId);
                        history.replace({
                            pathname: `/home/createRestaurant/${restaurantId}`,
                            state: {background: { pathname: "/home" }, restaurant:{isDemo:false, isFree: false}}
                        });
                    }
                }
                
            }
            //if intent_secret.data.clientSecretType is paymentIntent, meaning that customer credit card need to be charged immediately
            // if(intent_secret.data.clientSecretType === "paymentIntent"){
            //     const confirmPaymentRes = await stripe.confirmCardPayment(intent_secret.data.clientSecret,{
            //         receipt_email: values.contactEmail,
            //         payment_method: {card: cardElement}
            //     });
                
            //     // console.log(`email ${user.email}`);
            //     if (confirmPaymentRes.error) {
            //         await axios.post(currentURL + '/cancelMeteredSubscription', {meteredSubscriptionId, adminId: user.uid, restaurantId});
            //         setCardInputError(confirmPaymentRes.error);
            //         loading(false);
            //         cardElement.update({disabled: false});
            //         throw confirmPaymentRes.error;
            //     } else {
            //         if (confirmPaymentRes.paymentIntent.status === 'succeeded') {
            //             console.log(`email ${user.email}`);
            //             const createRestaurantResponse = await axios.post(currentURL + '/createRestaurant', {
            //                 values: values,
            //                 adminId: user.uid,
            //                 price: price,
            //                 isSetupIntent: false,
            //                 paymentMethodId: confirmPaymentRes.paymentIntent.payment_method,
            //                 restaurantId: restaurantId,
            //                 customerId: customerId,
            //                 product: product,
            //                 subscriptionId: subscriptionId,
            //                 meteredSubscriptionId,
            //                 meteredItemId,
            //                 contactEmail: user.email,
            //                 invoiceId: invoiceId,
            //                 paymentIntentId: paymentIntentId
            //             });
            //             // console.log("called createRestaurant");
            //             if(createRestaurantResponse.status===200){
            //                 alert("餐廳創建成功！");
            //                 loadingRestautantDataAfterCreate(restaurantId);
            //                 history.replace({
            //                     pathname: `/home/createRestaurant/${restaurantId}`,
            //                     state: {background: { pathname: "/home" }, restaurant:{isDemo:false, isFree: false}}
            //                 });
            //             }
            //         }
            //     }
            // }else if(intent_secret.data.clientSecretType === "setupIntent"){
            //     //if intent_secret.data.clientSecret is false, meaning that customer credit card no need to be charge immediately, 
            //     //use setupIntent to confirm his card htmlFor future use.
            //     const confirmCardSetUpRes = await stripe.confirmCardSetup(intent_secret.data.clientSecret,{
            //         receipt_email: values.contactEmail,
            //         payment_method: {
            //             card: cardElement,
            //             billing_details: {
            //                 email: values.contactEmail
            //             }
            //         }
            //     });
            //     if (confirmCardSetUpRes.error) {
            //         await axios.post(currentURL + '/cancelMeteredSubscription', {meteredSubscriptionId, adminId: user.uid, restaurantId});
            //         setCardInputError(confirmCardSetUpRes.error);
            //         loading(false);
            //         cardElement.update({disabled: false});
            //         throw confirmCardSetUpRes.error;
            //     } else {
            //         const createRestaurantResponse = await axios.post(currentURL + '/createRestaurant', {
            //             values: values,
            //             adminId: user.uid,
            //             price: price,
            //             isSetupIntent: true,
            //             paymentMethodId: confirmCardSetUpRes.setupIntent.payment_method,
            //             restaurantId: restaurantId,
            //             customerId: customerId,
            //             product: product,
            //             subscriptionId: subscriptionId,
            //             meteredSubscriptionId,
            //             meteredItemId,
            //             contactEmail: user.email,
            //             invoiceId: invoiceId,
            //             paymentIntentId: paymentIntentId
            //         });
            //         // console.log("called createRestaurant");
            //         if(createRestaurantResponse.status===200){
            //             alert("餐廳創建成功！");
            //             loadingRestautantDataAfterCreate(restaurantId);
            //             history.replace({
            //                 pathname: `/home/createRestaurant/${restaurantId}`,
            //                 state: {background: { pathname: "/home" }, restaurant: {isDemo:false, isFree: false}}
            //             });
            //         }
                    
            //     }
            // }

            loading(false);
            // submitForm();
        }catch(error){
            console.log(error);
            if(error.response?.data){
                alert(JSON.stringify(error.response.data.error));
            }else if(error.message){
                alert(JSON.stringify(error.message));
            }else{
                alert(JSON.stringify(error));
            }
            loading(false);
        }

    };
    const handleFreeCreate = async () => {
        try{
            loading(true);
            const createFreeRestaurantResponse = await axios.post(currentURL + '/createFreeRestaurant', {
                values: values,
                contactEmail: user.email,
                discountCode
            }, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
            if(createFreeRestaurantResponse.status===200){
                alert("餐廳創建成功！");
                loadingRestautantDataAfterCreate(createFreeRestaurantResponse.data.restaurantId);
                history.replace({
                    pathname: `/home/createRestaurant/${createFreeRestaurantResponse.data.restaurantId}`,
                    state: {background: { pathname: "/home" }, restaurant:{isDemo:false, isFree: true}}
                });
            }
            loading(false);
        }catch(error){
            if(error.message){
                alert(JSON.stringify(error.message));
            }else{
                alert(JSON.stringify(error));
            }
            loading(false);
        }

    };
    // console.log(`errors ${JSON.stringify(errors, null, 2)}`);
    // console.log(`step2: ${JSON.stringify(values, null,2)}`);
    return (
        <div className="step2 h:full">
            
            {isDialogOpen&&<ConfirmChangesDialog closeDialog={closeDialog} dialogNotification={[`取消訂閱 - ${upcoming.upcomingStandard.name}`]} updateFunction={handleCancelSubscription}/>}
            {isEditCreditCardDialogOpen&&
            <EditCreditCard 
                closeModal={closeModal}
                closeEditCreditCardDialog={closeEditCreditCardDialog} 
                dialogNotification={dialogNotification} 
                subscriptionPlans={subscriptionPlans}
                options={options}
                selectedPlanId={restaurantData.selectedPlanId}
                cardStyle={cardStyle}
                upcomingStandard={upcoming?.upcomingStandard}
                updatePageInfo={updatePageInfo}
                restaurantData={restaurantData}
                cardElement={cardElement}
                stripe={stripe}
                elements={elements}
                loading={loading}
                adminId={user.uid}
                // updateSubscriptionInfo={updateSubscriptionInfo}
                />}
            {isEditDialogOpen&&
            <EditSubscription 
                closeModal={closeModal}
                closeEditDialog={closeEditDialog} 
                dialogNotification={dialogNotification} 
                subscriptionPlans={subscriptionPlans}
                options={options}
                selectedPlanId={restaurantData.selectedPlanId}
                cardStyle={cardStyle}
                upcomingStandard={upcoming?.upcomingStandard}
                updatePageInfo={updatePageInfo}
                restaurantData={restaurantData}
                cardElement={cardElement}
                stripe={stripe}
                elements={elements}
                loading={loading}
                adminId={user.uid}
                // updateSubscriptionInfo={updateSubscriptionInfo}
                />}
            {isLoadingPage? <Loading/>:
            <div className="create-restaurant-form-body c:row h:full">
                <div className="c:col c:between c:middle">
                    <h3 className="f:black f:400">訂閱計劃</h3>
                </div>
                <div className="c:row py:md flex:1">
                    {(isFreeRestaurantEdit||mode==="CREATE")&&
                    <div className="c:row c:middle">
                        <div className="show-plan-wrap c:col">
                            {
                                
                                subscriptionPlans&&subscriptionPlans.map((plan, index)=>{
                                    return (
                                    <div 
                                    key={`plan-card-${index}`} 
                                    className={`plan-card ${values.selectedPlanId===plan.id?"chosen":null}`}
                                    onClick={()=>{
                                        handleChosenPlan(plan.id)
                                    }}
                                    >
                                        <h6 className="title">{plan.priName}</h6>
                                        <i className="material-icons-outlined">
                                        {plan.interval.toUpperCase()==="MONTH"? "tapas":"brunch_dining"}
                                        </i>
                                        <span className="plan-card-price">
                                            <span className="dollar-sign">HKD$&nbsp;</span>
                                            <span className="currency">{plan.price/100}</span>
                                            <span className="interval">{`   /${plan.interval.toUpperCase()==="MONTH"? "月":"年"}`}</span>
                                        </span>
                                        {plan.descriptions.map((description, index2)=>
                                            <p key={`description-${index2}`} className={`${plan.interval.toUpperCase()==="YEAR"&&"highlight"}`}>{description}<i className="material-icons-outlined">verified</i></p>
                                        )}
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    }
                    {(isFreeRestaurantEdit||mode==="CREATE")&&
                    <div>
                        <div className="form-info-wrap">
                            <label htmlFor="restaurantName">選擇訂閱</label>
                            <h6>{selectedPlan? `${selectedPlan.priName}`:isFreeRestaurantEdit? "免費帳號":"無"}</h6>
                        </div>  
                        <div className="form-info-wrap">
                            <label htmlFor="restaurantName">價錢</label>
                            <h6>{selectedPlan? `$${selectedPlan.price/100}`:"$0"}</h6>
                        </div>  
                        <div className="form-info-wrap">
                            <label htmlFor="restaurantName">下個繳費日期</label>
                            <h6>{nextPaymentDate? `${nextPaymentDate}`:isFreeRestaurantEdit? date.format(new Date(restaurantData.current_period_end * 1000), 'YYYY-MM-DD'):"無"}</h6>
                        </div>  
                    </div>}
                    {mode==="EDIT"&&billing&&
                    <div>
                        <div className="form-info-wrap">
                            <label htmlFor="restaurantName">選擇訂閱</label>
                            {/* <h6>{subscriptionDetail.billing.name}{subscriptionDetail.plan.isCancelled&&" (已取消訂閱)"}</h6> */}
                            <h6>{billing.name}</h6>
                        </div>  
                        {upcoming&&upcoming.upcomingStandard&&<div className="form-info-wrap">
                            <label htmlFor="restaurantName">下個繳費金額</label>
                            <h6>${fixed(upcoming.upcomingStandard.amount_due - upcoming.upcomingStandard.discount_amount, 1)>0? fixed(upcoming.upcomingStandard.amount_due - upcoming.upcomingStandard.discount_amount, 1):0}&nbsp;&nbsp;{upcoming.upcomingStandard.coupon&&`(${upcoming.upcomingStandard.coupon})`}</h6>
                        </div>  }
                        <div className="form-info-wrap">
                            <label htmlFor="restaurantName">有效至</label>
                            <h6>{billing.period_end}</h6>
                        </div>  
                        {upcoming&&upcoming.upcomingStandard&&<div className="form-info-wrap">
                            <label htmlFor="restaurantName">下個繳費日期</label>
                            <h6>{upcoming.upcomingStandard? upcoming.upcomingStandard.due_date:"無"}</h6>
                        </div> } 
                        <div className="form-info-wrap">
                            <label htmlFor="restaurantName">上個繳費日期</label>
                            <h6>{billing.paid_at}</h6>
                        </div>
                        <div className="form-info-wrap">
                            <label htmlFor="restaurantName">上個繳費金額</label>
                            <h6>${billing.amount_paid}&nbsp;&nbsp;{billing.coupon&&`(${billing.coupon})`}</h6>
                        </div>
                    
                        <div className="form-info-wrap">
                            <label htmlFor="restaurantName">信用卡資料</label>
                            <div className="credit-card-element c:col c:middle">
                                <FontAwesomeIcon className="f:2xl" icon={['fab', `cc-${cardInfo.brand}`]}/>
                                <h6>&nbsp;&nbsp;{cardInfo.last4}&nbsp;&nbsp;</h6>
                                <h6>{`${cardInfo.exp_month}/${cardInfo.exp_year}`}</h6>
                            </div>
                            <div onClick={()=>{setIsEditCreditCardDialogOpen(true)}} className="button touch restaurant-edit-btn">
                            <i className="restaurant-card-icon material-icons-outlined">
                            edit
                            </i>
                        </div>
                        </div>
                    </div>}
                    
                    {mode==="EDIT"&&!isFreeRestaurantEdit&&<Button
                        type="button"
                        className="select-plan-btn"
                        color="primary" 
                        variant="contained"
                        style={{marginBottom: "25px", marginTop: "25px"}}
                        onClick={()=>{setIsEditDialogOpen(true)}}
                        >
                        更改訂閱計劃
                    </Button>}

                    {/* {mode==="EDIT"&&<button onClick={handleUpdatePlan} type="button">update plan</button>} */}

                    {(isFreeRestaurantEdit||mode==="CREATE")&&<div className="form-input-wrap">
                        <label htmlFor="discountCode">優惠碼</label>
                        <div className="normal-input-field-wrap">
                            <input 
                                className="normal-input-field"
                                type="text"
                                onChange={handleChange}
                                onBlur={(e)=>{
                                    handleBlur(e);
                                    handleDiscountCode(e);
                                }}
                                value={values.discountCode}
                                id="discountCode" 
                                name="discountCode"
                            />
                            <div className="normal-input-error">
                            {touched.discountCode && discountCodeError.discountCode && <ErrorText msg={discountCodeError.discountCode}/>}
                            {touched.discountCode && discountCodeDescription && <SuccessText msg={`${discountCodeDescription}`}/>}
                            </div>
                        </div>
                    </div>}
                    {(isFreeRestaurantEdit||mode==="CREATE")&&<div className="form-input-wrap">
                        <label htmlFor="discountCode">信用卡</label>
                        <div className="normal-input-field-wrap">
                            <div className="credit-card-input-wrap c:row bg:white gap:sm">
                                <CardElement options={options} onChange={(e) => {
                                            setCardInputError(e.error);
                                            setCardComplete(e.complete);
                                }}/>
                            </div>
                            <div className="normal-input-error">
                            {cardInputError&& <ErrorText msg={cardInputError.message}/>}
                            </div>
                        </div>
                    </div> } 
                    {(isFreeRestaurantEdit||mode==="CREATE")&&
                    <div className="c:col c:center mt:md">
                        <input 
                        type="radio" 
                        id="web-terms-confirmation" 
                        name="terms-confirmation"
                        onClick={toggleAgreeTerms}
                        checked={agreeTerms}
                        ></input>
                        <p>本人已詳細閱讀及瞭解本網站<a href="/terms" target="_blank">使用條款</a>，亦同意受有關條款及細則所約束</p>
                    </div> } 
                </div>

                {/* {mode==="EDIT"&&<button onClick={handleUpdateCard} type="button">update card</button>} */}
                <div className="c:col c:between">   
                    <Button
                    className="step-btn my-outlined-btn"
                    // disabled={mode==="CREATE"&&!(isValid&&dirty)}
                    color="primary" 
                    variant="outlined" 
                    onClick={closeModal}
                    // disabled={!(isValid && dirty)}
                    >{mode==="CREATE"? "取消":"返回"}</Button>
                    
                    {mode==="CREATE"&&!isFree&&
                    <Button
                        type="button"
                        className="step-btn"
                        disabled={(!selectedPlan||!agreeTerms||discountCodeError.discountCode||cardInputError||!cardComplete||!(values.selectedPlanId!=='')||!(isValid&&dirty))}
                        color="primary" 
                        variant="contained" 
                        onClick={handleCreate}
                        // disabled={!(isValid && dirty)}
                        >創建餐廳</Button>
                    }
                    {mode==="CREATE"&&isFree&&
                    <Button
                        type="button"
                        className="step-btn"
                        disabled={!agreeTerms||discountCodeError.discountCode||!(isValid&&dirty)}
                        color="primary" 
                        variant="contained" 
                        onClick={handleFreeCreate}
                        // disabled={!(isValid && dirty)}
                        >創建餐廳</Button>
                    }
                    {mode==="EDIT"&&isFreeRestaurantEdit&&
                    <Button
                        type="button"
                        className="step-btn"
                        disabled={(!selectedPlan||!agreeTerms||discountCodeError.discountCode||cardInputError||!cardComplete||!(values.selectedPlanId!=='')||!(isValid&&dirty))}
                        color="primary" 
                        variant="contained" 
                        // onClick={handleFreeCreate}
                        >付費</Button>
                    }
                    {mode==="EDIT"&&upcoming&&upcoming.upcomingStandard&&
                    <Button
                        className="save-btn my-outlined-btn"
                        color="secondary" 
                        variant="outlined" 
                        disabled={!!!upcoming.upcomingStandard}
                        onClick={()=>{setIsDialogOpen(true)}}
                        >
                        <i className="f:xl material-icons-outlined mr:xs">
                        disabled_by_default
                        </i>
                        取消訂閱
                    </Button>
                    }
                    {/* <Button
                    className="save-btn"
                    color="primary" 
                    variant="contained" 
                    disabled={!dialogNotification.length}
                    onClick={()=>{setIsDialogOpen(true)}}
                    >
                    <i className="f:xl material-icons-outlined mr:xs">
                    save
                    </i>
                    儲存
                    </Button> */}
                </div>
                {/* <Button  
                    onClick={()=>{
                        history.replace({
                            pathname: `/home/createRestaurant/${uuidv4()}`,
                            state: {background: { pathname: "/home" }}
                        });
                        nextStep();
                    }}>
                    Next
                </Button> */}
            </div>}

        </div>
    );
}


const options = {
    hidePostalCode: true,
    style: {
    base: {
        // borderRadius: "unset",
        fontSize: "14px",
        color: "#424770",
        letterSpacing: "0.05rem",
        wordSpacing: "1rem",
        // fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
        color: "#A5A5A5"
        },
    },
    invalid: {
        color: "#fa755a"
    }
    }
};
export default Step2;