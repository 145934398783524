import React, {useState, useCallback, useEffect, useContext} from 'react';
import { Redirect, useLocation } from 'react-router';
import { useHistory, Link, Route } from "react-router-dom";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import date from 'date-and-time';
import { v4 as uuidv4} from 'uuid';
import '../home/Home.css';
import './ContactUs.css';
import { AppBar } from '../home/Home';
import {currentURL} from '../../Config';
// import CreatProj from '../creatProj/CreateProj';
import useOverlay, {OverlayContext} from '../../hooks/useOverlay';
import Loading from '../Loading';
import CreateRestaurant from '../createRestaurant/CreateRestaurant';
import RestaurantCard from '../restaurantCard.js/RestaurantCard';
import { FirebaseUserContext } from '../../hooks/Firebase';
import { useAxios } from '../../hooks/useAxios';
import ConfirmChangesDialog from '../createRestaurant/ConfirmChangesDialog';
import ChatModal from './ChatModal';
import {validateContactUsCase} from '../../validators/validator';
import { ErrorText } from '../Error';



function ContactUs({isUpdated, toggleIsUpdated}){
    const history = useHistory();
    const location = useLocation();
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [contactCases, setContactCases] = useState([]);
    const [errors, setErrors] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogNotification, setDialogNotification] = useState([]);
    const [dialogAction, setDialogAction] = useState("");
    const [selectedCaseId, setSelectedCaseId] = useState();

    const updateContactCases = (newCase)=>setContactCases(origin=>[newCase, ...origin]);
    const filterContactCases = (caseId)=>setContactCases(origin=>origin.filter(obj=>obj.caseId !== caseId));
    const closeDialog = useCallback(()=>setIsDialogOpen(false),[]);
    const handleTitleInput = e => {
        const [isValid, inputErrors] = validateContactUsCase({title: e.target.value});
        if(!isValid) setErrors(inputErrors);
        if(isValid) {
            setTitle(e.target.value);
            setErrors(origin=>{
                let copy = {...origin};
                delete copy.title;
                return copy;
            });
        }
    };
    const handleContentInput = e => {
        const [isValid, inputErrors] = validateContactUsCase({content: e.target.value});
        if(!isValid) setErrors(inputErrors);
        if(isValid) {
            setContent(e.target.value);
            setErrors(origin=>{
                let copy = {...origin};
                delete copy.content;
                return copy;
            });
        }
    };

    const resetAllField = () => {
        setTitle('');
        setContent('');
    }
    const resetContent = () => {
        setContent('');
    }
    useEffect(async () => {
        if (userStatus.loggedIn) {
        setIsLoading(true);
        const allContactCasesResponse = await axios.get(currentURL + '/getAllContactCasesByAdminId', {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        if(allContactCasesResponse.status === 200) setContactCases(allContactCasesResponse.data.cases);
        setIsLoading(false);
        }
    }, [isUpdated, userStatus]);


    // useEffect(()=>{
    //     if(){

    //     }
    // },[content, title]);
    
    // console.log(contactCases);
    const handleConfirmDialog = (action) => {
        switch (action){
            case "submit":
                setDialogNotification(["確認提交問題?"]);
                break;
            case "cancel":
                setDialogNotification(["確認取消問題?"]);
                break;
        }
        setIsDialogOpen(true);
    }

    const submitContactCase = async () => {
        const userSubmitCaseResponse = await axios.post(currentURL + '/userSubmitCase', {
            title: title,
            content: content,
        }, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        return new Promise((resolve, reject)=>{
            if(userSubmitCaseResponse.status === 200){
                resetAllField();
                updateContactCases(userSubmitCaseResponse.data.case);
                resolve(true);
            }else{
                reject(userSubmitCaseResponse.data.error);
            }
        });
    };
    const cancelContactCase = async () => {
        const cancelCaseResponse = await axios.post(currentURL + '/userCancelCase', {
            caseId: selectedCaseId
        }, {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
        return new Promise((resolve, reject)=>{
            if(cancelCaseResponse.status === 200){
                filterContactCases(selectedCaseId);
                resolve(true);
            }else{
                reject(cancelCaseResponse.data.error);
            }
        });
    };
    const openChat = (caseId, contactCase)=>{
        setSelectedCaseId(caseId);
        // console.log(contactCase);
        history.push({
          pathname: `/contactUs/${caseId}`,
          state: {background: location, contactCase: contactCase}
        });
    };
    const directToHome = () => history.push(`/home`);

    if (!userStatus.loggedIn||isLoading) {
        return(
            <Loading/>
        );
    }

    return (
    <div>
        <div className="app-root bg:grey">
            {/* <div className="app-bg-img"/> */}
            <AppBar setIsLoading={setIsLoading}/>
            {isDialogOpen&&
            <ConfirmChangesDialog 
                closeDialog={closeDialog} 
                dialogNotification={dialogNotification} 
                showConfirmSentence={false} 
                updateFunction={dialogAction==="submit"?submitContactCase:cancelContactCase} 
                style={{top: "unset"}}
            />}
            {/* {isDialogOpen&&
            <ConfirmChangesDialog 
                closeDialog={closeDialog} 
                dialogNotification={dialogNotification} 
                showConfirmSentence={false} 
                updateFunction={dialogAction==="submit"?submitContactCase:cancelContactCase} 
                style={{top: "unset"}}
            />} */}
            <div className="app">
                <div style={{height: "50px"}}></div>
                <div className="app-body bg:trans">
                    <div>
                        <div className="c:col c:between c:middle mb:md">
                            <h3 className="f:black f:400">聯絡我們</h3>
                        </div>
                        <div className="form-input-wrap">
                            <label for="contactUsTitle">標題</label>
                            <div className="normal-input-field-wrap">
                                <input 
                                    className="normal-input-field"
                                    type="text"
                                    onChange={handleTitleInput}
                                    value={title}
                                    id="contactUsTitle" 
                                    name="contactUsTitle"
                                    placeholder=""
                                />
                                <div className="normal-input-error">
                                {errors.title && <ErrorText msg={errors.title}/>}
                                </div>
                            </div>
                        </div>  
                        <div className="form-input-wrap">
                            <label for="contactUsContent">內容</label>
                            <div className="normal-input-field-wrap">
                                <textarea 
                                    className="normal-input-field"
                                    type="text"
                                    onChange={handleContentInput}
                                    value={content}
                                    id="contactUsContent" 
                                    name="contactUsContent"
                                    placeholder=""
                                    rows="20"
                                />
                                <div className="normal-input-error">
                                {errors.content && <ErrorText msg={errors.content}/>}
                                </div>
                            </div>
                        </div>  
                        <div className="c:col c:between" style={{paddingLeft: "120px"}}>
                            {/* <Button
                            disabled={!content}
                            className="btn step-btn my-outlined-btn"
                            color="Primary"
                            variant="contained" 
                            onClick={resetContent}>
                                清除
                            </Button> */}
                            <Button  
                                className="btn step-btn my-outlined-btn"
                                onClick={directToHome}
                                color="primary"
                                variant="outlined">
                            返回
                            </Button>
                            <Button
                            disabled={!title||!content||Object.keys(errors).length}
                            className="btn step-btn my-outlined-btn"
                            color="Primary"
                            variant="contained" 
                            onClick={()=>{
                                setDialogAction("submit");
                                handleConfirmDialog("submit");
                                }}>
                                提交問題
                            </Button>
                        </div>
                    </div>
                    <div className="contact-case-wrap c:row gap:xs">
                        {contactCases.map((contactCase, index) => {
                            const createdDate = new Date (contactCase.createdAt._seconds * 1000);
                            const date2string = date.format(createdDate, 'YYYY-MM-DD HH:mm');
                            return (
                            <div key={`contact-case-${index}`} className="contact-case-row c:col">
                                <div className="contact-case-card" onClick={()=>openChat(contactCase.caseId, contactCase)}>
                                    <h6>{contactCase.title}</h6>
                                    <h6 className="date">{date2string}</h6>
                                </div>
                                <Button
                                    className="my-outlined-btn"
                                    color="primary"
                                    variant="outlined" 
                                    onClick={()=>{
                                        setDialogAction("cancel");
                                        setSelectedCaseId(contactCase.caseId);
                                        handleConfirmDialog("cancel");
                                        }}>
                                    取消
                                </Button>
                            </div>
                            );
                        })}
                        

                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default ContactUs;