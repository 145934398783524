import React, {useState, useCallback, useEffect, useContext} from 'react';
import Button from '@material-ui/core/Button';
import '../root.css';
import './CreateRestaurant.css';
import './ConfirmChangesDialog.css';
import Loading from '../Loading';

function ConfirmChangesDialog({closeModal, closeDialog, dialogNotification, showConfirmSentence=true, updateFunction, style}){
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const confirm = async (e) => {
        setIsLoading(true);
        try{
            const isValid = await updateFunction();
            closeDialog(e);
            if(closeModal) closeModal(e);
        }catch(err){
            setErrorMsg(`${err}`);
        }
        setIsLoading(false);
    };

    return(
    <div>
        
        <div id="confirm-backdrop" className="modal-backdrop confirm-backdrop" style={{...style, display: "block"}}/>
        <div id="confirm-changes" className="modal-container" style={{display: "block",}}>
            <div className="modal-wrap c:row ">
                {isLoading&&<Loading style={{display: "block", positive: "relative"}}/>}
                {!isLoading&&errorMsg&&
                <div className="c:row c:middle c:between p:lg flex:1">
                    <h6>
                        {errorMsg}
                    </h6>
                    <h6>
                        Please try again later
                    </h6>
                </div>
                }
                {!isLoading&&!errorMsg&&<div id="confirm-changes-content" className="modal-content c:row c:between c:middle">
                    <div className="flex:1 c:row c:center">
                    {
                        dialogNotification.length>0&&dialogNotification.map((sentence, index)=>{
                            return (<h5 key={`sentence-${index}`}>{sentence}</h5>);
                        })
                    }
                    </div>
                    {showConfirmSentence&&<h5>確認要作出以上更改 ?</h5>}
                </div>}
                {!isLoading&&<div id="confirm-changes-foot" className="modal-foot c:col c:around p:xs">
                    <Button
                        className="btn step-btn"
                        color="primary"
                        variant="outlined" 
                        onClick={closeDialog}>
                    取消
                    </Button>
                    <Button
                        className="btn step-btn"
                        color="primary"
                        variant="contained" 
                        onClick={confirm}>
                    確認
                    </Button>
                </div>}
            </div>
        </div>
    </div>
    );
}

export default ConfirmChangesDialog;