import React, {useState, useCallback, useEffect, useContext} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import {currentURL} from '../../Config';
import { useAxios } from '../../hooks/useAxios';
import NoIcon from '../../assets/outline_storefront_black_24dp.png';
import './RestaurantCard.css';
import Loading from '../Loading';
import date from 'date-and-time';
import { FirebaseUserContext, getIdToken } from '../../hooks/Firebase';
import { getRestaurantSubscriptionInfo, getDemoRestaurantSubscriptionInfo } from '../../apis/restaurantAPI';
// const top5 = [
//     { name: "刀削麵", sales: 340},
//     { name: "凍檸茶", sales: 312},
//     { name: "凍利賓納米超迷你士多啤梨味黑糖晶球及原子級熱量朱古力粉數粒加少許文具金粉", sales: 102},
//     { name: "黑森林蛋糕", sales: 100},
//     { name: "熱黑糖珍珠巧克力", sales: 89},
//   ]
const top5 = [];
function RestaurantCard ({restaurant, deleteRestaurantInLocal, index, isUpdated}){
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;
    const history = useHistory();
    const location = useLocation();
    const [invoice, setInvoice] = useState(false);
    const [nextPayment, setNextPayment] = useState(undefined);
    const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
    const [subscriptionDetail, setSubscriptionDetail] = useState({});

    // const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const dateOptions = {year: 'numeric', month: 'long', day: 'numeric' };
    const createdAt = new Date(restaurant.createdAt._seconds * 1000).toLocaleDateString('zh-HK', dateOptions);
    const today = new Date();
    // const currentYear = today.getFullYear();
    // const currentMonth = today.getMonth();
    // const currentDay = today.getDate();
    // const [year, setYear] = useState(currentYear);
    // const [month, setMonth] = useState(currentMonth);
    // const [day, setDay] = useState(currentDay);
    const [selectedDate, setSelectedDate] = useState(today); //date obj
    const [year, setYear] = useState();
    const [month, setMonth] = useState();
    const [day, setDay] = useState();
    const [selectedDateType, setSelectedDateType] = useState("day");
    const [billing, setBilling] = useState(null);
    const [lastMeteredBilling, setLastMeteredBilling] = useState(null);
    const [nextBillingDate, setNextBillingDate] = useState(null);
    const [upcoming, setUpcoming] = useState(null);
    const [cardInfo, setCardInfo] = useState(null);
    const [numberOfWebTakeaway, setNumberOfWebTakeaway] = useState(null);
    const [loadingCsv, setLoadingCsv] = useState(false);

    //load data from server
    useEffect(async ()=>{
        setIsLoadingSubscription(true);
        if(!restaurant.isDemo&&!restaurant.isFree){
            try{
                const cardResponse = await axios.post(currentURL+'/getSubscriptionCardInfo', {restaurantId: restaurant.restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                setCardInfo(cardResponse.data.card);
                const billingHistoryResponse = await axios.post(currentURL+'/getBillingHistory', {restaurantId: restaurant.restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                const upcomingBillingResponse = await axios.post(currentURL+'/getUpcomingBilling', {restaurantId: restaurant.restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                const latestMeteredBillingResponse = await axios.post(currentURL+'/getLastMeteredBilling', {restaurantId: restaurant.restaurantId},  {headers: { Authorization: `Bearer ${userStatus.idToken}`}});
                setBilling(billingHistoryResponse.data.billings.find(obj=>obj.usage_type==="licensed"));
                setUpcoming(upcomingBillingResponse.data);
                setLastMeteredBilling(latestMeteredBillingResponse.data.billing);
                    
                setIsLoadingSubscription(false);
            }catch(error){
                console.log(error);
            }
        }
    }, [user, isUpdated]);

    //handle day selection
    useEffect(()=>{
        if(selectedDate.setHours(0,0,0,0) <= today.setHours(0,0,0,0)){
            const selectedYear = selectedDate.getFullYear();
            const selectedMonth = selectedDate.getMonth();
            const selectedDay = selectedDate.getDate();
            setYear(selectedYear);
            setMonth(selectedMonth);
            setDay(selectedDay);
        }else{
            setYear(today.getFullYear());
            setMonth(today.getMonth());
            setDay(today.getDate());
        }
        
    },[selectedDate]);

    const getTargetDate = (targetDateType, targetYear, targetMonth, targetDay) => {
        const correctMonth = targetMonth + 1;
        const month2string = correctMonth < 10 ? `0${correctMonth}`: `${correctMonth}`;
        const day2string = targetDay < 10 ? `0${targetDay}`: `${targetDay}`;
        switch (targetDateType){
            case "year":
                return `year-${targetYear}`;
            case "month":
                return `month-${targetYear}-${month2string}`
            case "day":
                return `day-${targetYear}-${month2string}-${day2string}`
        }
    }

    //custom hook for getting today's data
    const {response: todayResponse, isLoadingRequest: isLoadingTodayRequest, requestError: todayRequestError} = useAxios({
        method: 'POST',
        url: restaurant.isDemo?'/getDemoRestaurantAllStats':'/getRestaurantAllStats',
        data: {  
            targetDate: getTargetDate("month", today.getFullYear(), today.getMonth(), today.getDay()),
            ...!restaurant.isDemo&&{restaurantId: restaurant.restaurantId}
        },
    });
    //custom hook for getting selected day's data
    const {response, isLoadingRequest, requestError} = useAxios({
        method: 'POST',
        url: restaurant.isDemo?'/getDemoRestaurantAllStats':'/getRestaurantAllStats',
        data: {  
            targetDate: getTargetDate(selectedDateType, year, month, day),
            ...!restaurant.isDemo&&{restaurantId: restaurant.restaurantId}
        },
    }, getTargetDate(selectedDateType, year, month, day));

    //to handle day selection
    const handleSelectDateType = e => setSelectedDateType(e.target.value);
    const handleDateTypeIncrement = () => {
        switch (selectedDateType){
            case "year":
                if(year+1 <= today.getFullYear()) setSelectedDate(new Date(year+1, month, day));
                break;
            case "month":
                if(month+1 <= 13) {
                    const tempDate = new Date(year, month+1, day);
                    if(tempDate.getMonth() - month >= 2) {
                        setSelectedDate(new Date(year, month+2, 0));
                    }else{
                        setSelectedDate(new Date(year, month+1, day));
                    }
                };
                break;
            case "day":
                if(day+1 <= 32) setSelectedDate(new Date(year, month, day+1));
                break;
        }
    };
    const handleDateTypeDecrement = () => {
        switch (selectedDateType){
            case "year":
                if(year-1 >= 1971) setSelectedDate(new Date(year-1, month, day));
                break;
            case "month":
                if(month-1 >= -1) {
                    const tempDate = new Date(year, month-1, day);
                    if(tempDate.getMonth() === month) {
                        setSelectedDate(new Date(year, month, 0));
                    }else{
                        setSelectedDate(tempDate);
                    }
                };
                break;
            case "day":
                if(day+1 >= 0) setSelectedDate(new Date(year, month, day-1));
                break;
        }
    };
    //end of handling day selection

    const sortedMap = (map) => [null, '', {}, undefined].includes(map)? {}:Object.entries(map).sort(([,a], [,b]) => b-a).reduce((object, [key, value]) => ({...object, [key]: value}), {});
    const filteredAmount = (amount) => [null, '', {}, undefined].includes(amount)? 0 : Math.round((amount)*10)/10;

    const stats = sortedMap(response?.results);
    const top5 = sortedMap(stats?.meal);
    const averageOrderSize = stats?.totalRevenue||stats?.numberOfOrders? Math.round(stats.totalRevenue/stats.numberOfOrders*10)/10: 0;
    const totalRevenue = filteredAmount(stats?.totalRevenue);
    const numberOfOrders = filteredAmount(stats?.numberOfOrders);
    const currentMonthNumberOfOrders = filteredAmount(todayResponse?.results?.numberOfOrders);
    const currentMonth = today.getMonth()+1;
    
    const iconUrl = restaurant.restaurantIcon? restaurant.restaurantIcon.remotePath: null;

    const metered_period_end_str = upcoming&&date.format(date.addDays(new Date(upcoming.current_period_end * 1000), -1), 'DD/MM/YYYY');
    const metered_period_start_str = upcoming&&date.format(new Date(upcoming.current_period_start * 1000), 'DD/MM/YYYY');
    const metered_paidAt = upcoming&&date.format(new Date(upcoming.created * 1000), 'DD/MM/YYYY');
    const metered_cancelAt = upcoming&&upcoming.isCancelled&&date.format(new Date(upcoming.cancelAt * 1000), 'DD/MM/YYYY');

    const billing_period_start_str = billing&&date.format(new Date(billing.current_period_start * 1000), 'DD/MM/YYYY');
    const billing_period_end_str = billing&&date.format(new Date(billing.current_period_end * 1000), 'DD/MM/YYYY');
    const billing_paidAt = billing&&date.format(new Date(billing.created * 1000), 'DD/MM/YYYY');

    const directToSetting = ()=>{
        history.push({
          pathname: `/home/restaurantSetting/${restaurant.restaurantId}`,
          state: {background: location, restaurant: restaurant, isEditMode: true}
        });
    };
    const downloadCsv = async ()=>{
        setLoadingCsv(true);
        try{
            
            const response = await axios.post(currentURL + "/generateCsv", {
                restaurantId: restaurant.restaurantId
            },{
                headers: { Authorization: `Bearer ${userStatus.idToken}`},
                responseType: 'blob',
            });
            if(response.status === 200){

                console.log("cool");
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${restaurant.restaurantCode}.csv`); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
            if(response.status === 204){
                alert("沒有任何訂單數據");
            }
            setLoadingCsv(false);
        }catch(error){
            alert(error);
            setLoadingCsv(false);
        }

    };
    const directToWebOrder = ()=> window.open(`https://order.caterat.com/restaurant/webOrder/${restaurant.restaurantCode}`, "_blank");
    return (
        isLoadingRequest? 
            <div className="restaurant-card">
                <Loading/>
            </div>
            :
            <div className="restaurant-card c:row h:full p:xs gap:xs">
                <div className="card-info-row">
                    <div className="c:row flex:1 c:between">
                        <div className=" c:row pl:xs">
                            <h2 className="card-restaurant-name">{restaurant.restaurantName} </h2>
                            <div className="c:col">
                                <div onClick={directToWebOrder} className="button touch earth-btn">
                                    <i className="restaurant-card-icon material-icons-outlined">
                                        public
                                    </i>
                                </div>
                                <h4>{restaurant.restaurantCode}</h4>
                            </div>
                            <h6 className="createdAt">創建於{createdAt}</h6>
                        </div>
                        <div className="date-picker align-self-center">
                            <div className="date-picker-btn button center" onClick={handleDateTypeDecrement} >
                                <i className="restaurant-card-icon material-icons-outlined">
                                arrow_back_ios
                                </i>
                            </div>
                            <input id={`year-btn-${index}`} type="radio" name={`day-type-${index}`} className="date-type-btn" value={'year'} onClick={handleSelectDateType}/>
                            <label id={`year-label-${index}`} className="date-type-label button" htmlFor={`year-btn-${index}`}>
                                <h6>年份</h6>
                                <h6 style={{fontSize: "1rem"}}>{year}年</h6>
                            </label>

                            <input id={`month-btn-${index}`} type="radio" name={`day-type-${index}`} className="date-type-btn" value={'month'} onClick={handleSelectDateType}/>
                            <label id={`month-label-${index}`} className="date-type-label button" htmlFor={`month-btn-${index}`}>
                                <h6>月份</h6>
                                <div className="date-label">
                                    <h6>{year}年</h6>
                                    <h6>{month+1}月</h6>
                                </div>
                                
                            </label>

                            <input id={`day-btn-${index}`} type="radio" name={`day-type-${index}`} className="date-type-btn" value={'day'} onClick={handleSelectDateType} defaultChecked/>
                            <label id={`day-label-${index}`} className="date-type-label button" htmlFor={`day-btn-${index}`}>
                                <h6>日子</h6>
                                <div className="date-label">
                                    <h6>{year}年{month+1}月</h6>
                                    <h6>{day}日</h6>
                                </div>
                            </label>
                            <div className="date-picker-btn button center" onClick={handleDateTypeIncrement} >
                                <i  className="restaurant-card-icon material-icons-outlined">
                                arrow_forward_ios
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="c:row flex:1">
                        {iconUrl? 
                        <div className="icon" style={{backgroundImage: `url("${iconUrl}")`}}/>
                        :<div className="default-icon">
                            <img src={NoIcon} alt="Logo" />
                        </div>}
                    </div>
                    <div className="c:row">
                        <div onClick={directToSetting} className="button touch restaurant-edit-btn">
                            <i className="restaurant-card-icon material-icons-outlined">
                            edit
                            </i>
                        </div>

                        
                        {/* <div onClick={downloadCsv} className="button touch restaurant-edit-btn">
                            <i className="restaurant-card-icon material-icons-outlined">
                            download
                            </i>
                        </div> */}
                    </div>

                </div>
                <div className={`stats-info-row ${(restaurant.isDemo||restaurant.isFree)&&"isDemo"}`}>
                    <div className="stats-card">
                        <div id="top5" className="top5-title">
                            <h6>排名</h6>
                            <h6 className="justify-self-start">餐點</h6>
                            <h6 className="justify-self-center">總銷量</h6>
                        </div>
                        <div className="stats-card-content">
                            <div className="c:row h:full w:full px:xs">
                                {Object.entries(top5).slice(0, 5).map(([key, value], index)=>{
                                    return (
                                    <div key={`top5-sales-${index}`} className="top5-sales-row mt:xs">
                                        <h6>{index+1}</h6>
                                        <h6 className="justify-self-start" style={{textAlign: "unset"}}>{key}</h6>
                                        <h5 className="justify-self-center numeric">{value}</h5>
                                    </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="c:row gap:xs">
                        <div className="stats-card ">
                            <h6 id="total-revenue" className="stats-card-title">
                                營業總額
                                {/* {
                            loadingCsv? <div className="icon-loading"/>
                            :<div onClick={downloadCsv} className="button touch restaurant-edit-btn">
                                <i className="restaurant-card-icon material-icons-outlined">
                                download
                                </i>
                            </div>
                        } */}
                            </h6>
                            <div className="stats-card-content numeric">
                                <span className="dollar-sign">$</span>
                                <h2>
                                    {totalRevenue}
                                </h2>
                            </div>
                        </div>
                        <div className="stats-card">
                            <h6 id="number-of-takeaway" className="stats-card-title">
                                訂單總數
                            </h6>
                            <div className="stats-card-content numeric">
                                <h2>
                                    {numberOfOrders}
                                </h2>
                            </div>
                        </div>
                        <div className="stats-card flex:1">
                            <h6 id="average-order-size" className="stats-card-title">
                                平均訂單金額
                            </h6>
                            <div className="stats-card-content numeric">
                                <span className="dollar-sign">$</span>
                                <h2>
                                    {averageOrderSize}
                                </h2>
                            </div>
                        </div>
                    </div>
                    {!restaurant.isDemo&&!restaurant.isFree&&
                    <div className="stats-card flex:1">
                        <h6 id="subscription-detail" className="stats-card-title">
                            訂閱資料
                        </h6>
                        {isLoadingSubscription? <Loading/>
                        :<div id="show-subscription-detail" className="stats-card-content c:row h:full w:full">
                            
                            <div className="plan-name first">信用卡資料</div>
                            <div className="plan-info-row first">
                                {/* <p className="card title" >信用卡資料</p> */}
                                <FontAwesomeIcon className="f:xl card-icon" style={{verticalAlign: "middle"}} icon={['fab', `cc-${cardInfo.brand}`]}/>
                                <h6>
                                    {cardInfo.last4}&nbsp;&nbsp;
                                    {cardInfo.exp_month}/
                                    {cardInfo.exp_year}
                                </h6>
                            </div>
                            {billing&&<div> 
                                <div className="plan-name second">{billing.description}</div>
                                <div className="plan-info-row first">
                                    <p className="title">服務計劃週期：</p>
                                    <p>{`${billing.period_start} - ${billing.period_end}`}</p>
                                </div>
                                <div className="plan-info-row">
                                    <p className="title">已繳付金額：</p>
                                    <p>${billing.amount_paid}</p>
                                </div>
                                {upcoming&&upcoming.upcomingStandard&&<div className="plan-info-row">
                                    <p className="title">下次繳費日期：</p>
                                    <p>{upcoming.upcomingStandard.due_date}</p>
                                </div>}
                            </div>}
                            {upcoming&&upcoming.upcomingMetered?
                            <div> 
                                <div className="plan-name">{upcoming.upcomingMetered.description}</div>
                                <div className="plan-info-row first">
                                    <p className="title">服務計劃週期：</p>
                                    <p>{`${upcoming.upcomingMetered.period_start} - ${upcoming.upcomingMetered.period_end}`}</p>
                                </div>
                                <div className="plan-info-row">
                                    <p className="title">目前收款次數：</p>
                                    <p>{upcoming.upcomingMetered.current_usage}次</p>
                                </div>
                                <div className="plan-info-row">
                                    <p className="title">應付金額：</p>
                                    <p>${upcoming.upcomingMetered.amount_due}</p>
                                </div>
                                <div className="plan-info-row">
                                    <p className="title">繳費日期：</p>
                                    <p>{upcoming.upcomingMetered.due_date}</p>
                                </div>
                            </div>
                            :<div> 
                                <div className="plan-name">{lastMeteredBilling.description}</div>
                                <div className="plan-info-row first">
                                    <p className="title">服務計劃週期：</p>
                                    <p>{`${lastMeteredBilling.period_start} - ${lastMeteredBilling.period_end}`}</p>
                                </div>
                                <div className="plan-info-row">
                                    <p className="title">目前收款次數：</p>
                                    <p>{lastMeteredBilling.current_usage}次</p>
                                </div>
                                <div className="plan-info-row">
                                    <p className="title">應付金額：</p>
                                    <p>${lastMeteredBilling.amount_due}</p>
                                </div>
                                <div className="plan-info-row">
                                    <p className="title">繳費日期：</p>
                                    <p>{lastMeteredBilling.due_date}</p>
                                </div>
                            </div>
                            }
                            {/* <div className="c:col gap:xs">
                                <h6>
                                    上個繳費金額: 
                                </h6>
                                {subscriptionDetail&&Object.keys(subscriptionDetail).length > 0&&<h6>
                                    ${subscriptionDetail.latestPaymentIntent.amount}
                                </h6>}
                            </div>
                            <div className="c:col gap:xs">
                                <h6>
                                    下次繳費日期: 
                                </h6>
                                {subscriptionDetail&&Object.keys(subscriptionDetail).length > 0&&<h6>
                                    {subscriptionDetail.upcomingInvoice.date}
                                </h6>}
                            </div>
                            <div className="c:col gap:xs">
                                <h6>
                                    {currentMonth}月網上點餐總數:  
                                </h6>
                                {subscriptionDetail&&Object.keys(subscriptionDetail).length > 0&&<h6>
                                    {currentMonthNumberOfOrders}
                                </h6>}
                            </div>
                            <div className="c:col gap:xs">
                                <h6>
                                    {currentMonth}月手續費:  
                                </h6>
                                {subscriptionDetail&&Object.keys(subscriptionDetail).length > 0&&<h6>
                                    ${currentMonthNumberOfOrders}
                                </h6>}
                            </div> */}
                        </div>
                        }
                    </div>}
                </div>
                <div className="show-address-wrap c:col">
                    {restaurant.address1&&
                        <h6>
                        {restaurant.address1}
                        </h6>
                    }
                    {restaurant.address2&&
                        <h6>
                        , {restaurant.address2}
                        </h6>
                    }
                    {restaurant.address3&&
                        <h6>
                        , {restaurant.address3}
                        </h6>
                    }
                </div>
            </div>
    );
}
export default RestaurantCard;