import {useHistory, useLocation, useParams} from 'react-router-dom';
import React, {useState, useCallback, useEffect, useContext} from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import date from 'date-and-time';
import { FirebaseUserContext } from '../../hooks/Firebase';
import '../root.css';
import './ChatModal.css'
import {currentURL} from '../../Config';
import person from '../../assets/person.png';
import support_agent from '../../assets/support_agent.png';
import {validateContactUsCase} from '../../validators/validator';
import { ErrorText } from '../Error';

function ChatModal({toggleIsUpdated}){
    const history = useHistory();
    const location = useLocation();
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;
    const [isLoading, setIsloading] = useState(false);
    const [contactCase, setContactCase] = useState();
    const [reply, setReply] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(()=>{
        if(location.state.contactCase){
            setContactCase(location.state.contactCase);
        }
    }, [location]);


    // const handleReply = (e) => setReply(e.target.value);
    const handleReply = e => {
        const [isValid, inputErrors] = validateContactUsCase({content: e.target.value});
        if(!isValid) setErrors(inputErrors);
        if(isValid) {
            setReply(e.target.value);
            setErrors(origin=>{
                let copy = {...origin};
                delete copy.content;
                return copy;
            });
        }
    };
    
    const replyCase = async () => {
        setIsloading(true);
        const userReplyCaseResponse = await axios.post(currentURL + '/userReplyCase', {
            caseId: contactCase.caseId,
            content: reply,
            adminId: user.uid
        });

        if(userReplyCaseResponse.status === 200){
            const now = new Date();
            // const second = now.getTime() / 1000;

            setReply('');
            setContactCase(origin=>({...origin, messages: [...origin.messages, {
                content:  reply,
                sentBy: "user",
                createdAt: now,
            }]}));
        }
        setIsloading(false);
    };
    const closeModal = e => {
        e.stopPropagation();
        e.preventDefault();
        history.goBack();
        toggleIsUpdated();
    };

    const chatBoxStyle = {
        user: {
            backgroundColor: "rgb(173, 226, 226)",
            justifySelf: "right"
        },
        caterat: {
            backgroundColor: "rgb(231, 231, 231)",
            justifySelf: "left"
        }
    }
    const createdDate = contactCase? date.format(new Date(contactCase.createdAt._seconds * 1000),'MMM DD, YYYY'):'';
    // console.log(createdDate);
    return(
    <div>
        <div className="loading" style={{display: "none", zIndex: "1000"}} />
        <div className="dialog-backdrop" style={{display: "none", zIndex: "900"}}/>
        <div onClick={closeModal} className="modal-backdrop" style={{display: "block"}}/>
        <div className="modal-container" style={{display: "block"}}>
            <div className="chat-modal modal-wrap c:row">
                <div className="chat-title">
                    {contactCase&&contactCase.title}
                </div>
                <div className="chat-convo-wrap c:row p:xs gap:sm">
                    <h6 className="chat-date">{createdDate}</h6>
                    {
                        contactCase&&contactCase.messages.map((msg, index)=>{
                            return (
                                <div key={`convo-${index}`} className="chat-row">
                                    {msg.sentBy==="user"?
                                    <div/>
                                    :<div className="c:row c:middle mt:xs">
                                        <img src={support_agent} alt="Avatar" className="speaker-img"></img>
                                    </div>
                                    }
                                    <div className="chat-content" style={chatBoxStyle[msg.sentBy]}>{msg.content}</div>
                                    {msg.sentBy==="user"?
                                    <div className="c:row c:middle mt:xs">
                                        <img src={person} alt="Avatar" className="speaker-img"></img>
                                    </div>
                                    :<div/>
                                    }
                                </div>
                            );
                        })
                    }
                    {/* <div className="chat-row">
                        <div></div>
                        <div className="chat-content" style={chatBoxStyle.user}>hiii</div>
                        <div className="c:row c:middle mt:xs">
                            <img src={person} alt="Avatar" className="speaker-img"></img>
                        </div>
                    </div>
                    <div className="chat-row">
                        <div className="c:row c:middle mt:xs">
                            <img src={support_agent} alt="Avatar" className="speaker-img"></img>
                        </div>
                        <div className="chat-content" style={chatBoxStyle.caterat}>hellooo</div>
                        <div></div>
                    </div>
                    <div className="chat-row">
                        <div></div>
                        <div className="chat-content" 
                        style={chatBoxStyle.user}
                        >fk</div>
                        <div className="chat-speaker"></div>
                    </div> */}
            
                    <div>
                        <div className="sized-chat-box"/>
                    </div>
                </div>
                <div className="chat-reply-wrap px:xs pt:xs c:row">
                    <div className="c:row px:xs pt:xs">
                        {/* <label for="replyContent" className="f:white">回覆</label> */}
                        <textarea 
                            className="chat-input-field"
                            type="text"
                            // disabled={isLoading}
                            onChange={handleReply}
                            // onBlur={handleBlur}
                            value={reply}
                            id="replyContent" 
                            name="replyContent"
                            placeholder="訊息"
                            rows="10"
                        />
                        <div className="normal-input-error">
                            {errors.content && <ErrorText msg={errors.content}/>}
                        </div>
                    </div>  
                    <div className="c:col c:between px:sm">
                        <Button
                        // disabled={isLoading}
                        className="btn step-btn my-outlined-btn"
                        color="default"
                        variant="contained" 
                        onClick={closeModal}>
                            取消
                        </Button>
                        <Button
                        disabled={!reply||isLoading}
                        className="btn step-btn my-outlined-btn"
                        color="default"
                        variant="contained" 
                        onClick={replyCase}>
                            回覆
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default ChatModal;