import {useHistory} from 'react-router-dom';
import React, {useState, useCallback, useEffect, useContext} from 'react';
import { Route, useParams} from "react-router-dom";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import {
    CardNumberElement, 
    CardExpiryElement, 
    CardCvcElement,
    CardElement,
    useStripe, 
    useElements,
    Elements
} from '@stripe/react-stripe-js';
import {OverlayContext} from '../../hooks/useOverlay';
import { FirebaseUserContext, getIdToken } from '../../hooks/Firebase';
import {currentURL} from '../../Config';
import {ErrorText} from '../Error';
import '../root.css';
import './CreateRestaurant.css';
import './EditSubscription.css';
import Loading from '../Loading';

function EditSubscription({closeModal, closeEditDialog, upcomingStandard, updatePageInfo, dialogNotification, adminId, loading, restaurantData, subscriptionPlans, selectedPlanId, options, cardStyle}){
    const userStatus = useContext(FirebaseUserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [cardComplete, setCardComplete] = useState(false);
    const [cardInputError, setCardInputError] = useState(false);
    const [changedPlanId, setChangedPlanId] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const cardElement = elements.getElement(CardElement);
    const isCancelled = !!!upcomingStandard;
    const [agreeTerms, setAgreeTerms] = useState(false);
    const toggleAgreeTerms = () => setAgreeTerms(origin=>!origin);

    const confirm = async () => {
        try{
            if(changedPlanId){
                await handleUpdatePlan();
                
            }
        }catch(err){
            setErrorMsg(`${err}`);
        }
    };
    const handleChosenPlan = (planId) => {
        if(planId === changedPlanId){
            setChangedPlanId("");
        }else{
            setChangedPlanId(planId);
        }
    };
    const handleUpdatePlan = async (e) => {
        try{
            if (!stripe || !elements) {
                return;
            }
            cardElement.update({disabled: true});
            if (cardComplete) {
                loading(true);
            }
            // Use your card Element with other Stripe.js APIs
            const payload = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (payload.error) {
                // console.log(payload.error);
                setCardInputError(payload.error);
                loading(false);
                cardElement.update({disabled: false});
                return;
            } else {
                setCardInputError(false);
            }

            const intentSecretRes = await axios.post(currentURL + '/createUpdateSubscriptionIntent', {
                customerId: restaurantData.customerId,
                planId: changedPlanId,
                subscriptionId: restaurantData.subscriptionId,
                restaurantId: restaurantData.restaurantId,
                customerId: restaurantData.customerId,
            }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});

            const confirmPaymentRes = await stripe.confirmCardPayment(intentSecretRes.data.clientSecret,{
                // By Ding
                receipt_email: userStatus.user.email,
                // receipt_email: restaurantData.contactEmail,
                payment_method: {card: cardElement}
            });
            if (confirmPaymentRes.error) {
                // await axios.post(currentURL + '/cancelMeteredSubscription', {meteredSubscriptionId, adminId: adminId, restaurantId: restaurantData.restaurantId});
                cardElement.update({disabled: false});
                throw confirmPaymentRes.error;
            }
            if (confirmPaymentRes.paymentIntent.status === 'succeeded') {
                const updateSubscriptionRes = await axios.post(currentURL + '/updateSubscriptionPlan', {
                    contactEmail: userStatus.user.email,
                    customerId: restaurantData.customerId,
                    planId: changedPlanId,
                    subscriptionId: restaurantData.subscriptionId,
                    restaurantId: restaurantData.restaurantId,
                    customerId: restaurantData.customerId,
                    newSubscriptionId: intentSecretRes.data.newSubscriptionId,
                    paymentMethodId: confirmPaymentRes.paymentIntent.payment_method,
                }, {headers: { Authorization: `Bearer ${await getIdToken()}`}});
                updatePageInfo()
                // await updateSubscriptionInfo(adminId, restaurantData.restaurantId);
                alert("你的訂閱已經更新完成！");
            }
            loading(false);
            closeEditDialog();
            // submitForm();
        }catch(error){
            if(error.response){
                if(error.response.data.error === "The maximum number of trial period days is 730 (2 years)."||error.response.data.error.param==="trial_end"){
                    alert("你的訂閱將會超過兩年，因此交易未能完成。");
                }else{
                    setCardInputError(error.response.data.error.raw);
                }
                updatePageInfo();
                // await updateSubscriptionInfo(adminId, restaurantData.restaurantId);
            }else{
                // alert(`error: ${JSON.stringify(error)}`);
                // console.log(error);
                // await updateSubscriptionInfo(adminId, restaurantData.restaurantId);
                updatePageInfo();
                setCardInputError(error);
            }
            loading(false);
        }
    }
    // console.log(restaurantData);
    return(
    <div>
        
        <div id="edit-subscription-backdrop" className="modal-backdrop confirm-backdrop" style={{display: "block"}}/>
        <div id="edit-subscription-container" className="modal-container" style={{display: "block",}}>
            <div className="modal-wrap edit-subscription-wrap c:row gap:xs">
                {/* {isLoading&&<Loading style={{display: "block", positive: "relative"}}/>} */}
                {/* {errorMsg&&
                <div className="c:row c:middle c:between p:lg flex:1">
                    <h6>
                        {errorMsg}
                    </h6>
                    <h6>
                        Please try again later
                    </h6>
                </div>
                } */}
                {/* {!isLoading&&!errorMsg&&<div id="confirm-changes-content" className="modal-content c:row c:between c:middle">
                    <div className="flex:1">
                    {
                        dialogNotification.length>0&&dialogNotification.map((sentence)=>{
                            return (<h5>{sentence}</h5>);
                        })
                    }
                    </div>
                    <h5>確認要作出以上更改 ?</h5>
                </div>} */}
                <div className="c:col c:between c:middle mb:xs">
                    <h3 class="f:black f:400">更改訂閱計劃</h3>
                </div>

                <div className="c:row c:middle">
                    <div className="show-plan-wrap c:col">
                    {   subscriptionPlans&&subscriptionPlans.map((plan, index)=>{
                            return (
                            <div 
                            key={`plan-card-${index}`} 
                            className={`plan-card ${plan.id===selectedPlanId&&!isCancelled&&"disabled-card"} ${changedPlanId===plan.id&&"chosen"}`}
                            onClick={()=>{
                                handleChosenPlan(plan.id)
                            }}
                            >
                                <h6 className="title">{plan.priName}</h6>
                                <i className="material-icons-outlined">
                                {plan.interval.toUpperCase()==="MONTH"? "tapas":"brunch_dining"}
                                </i>
                                <span className="plan-card-price">
                                    <span className="dollar-sign">HKD$&nbsp;</span>
                                    <span className="currency">{plan.price/100}</span>
                                    <span className="interval">{`   /${plan.interval.toUpperCase()==="MONTH"? "月":"年"}`}</span>
                                </span>
                                {plan.descriptions.map(description=>
                                    <p className={`${plan.interval.toUpperCase()==="YEAR"&&"highlight"}`}>{description}<i className="material-icons-outlined">verified</i></p>
                                )}
                            </div>
                            )
                        })

                    }
                    </div>

                </div>
                <div className="form-input-wrap">
                        <label for="discountCode" style={{width: "80px"}}>信用卡</label>
                        <div className="normal-input-field-wrap">
                            <div class="credit-card-input-wrap c:row bg:white gap:sm">
                                <CardElement options={options} onChange={(e) => {
                                            setCardInputError(e.error);
                                            setCardComplete(e.complete);
                                }}/>
                            </div>
                            <div className="normal-input-error">
                            {cardInputError&& <ErrorText msg={cardInputError.message}/>}
                            </div>
                        </div>
                </div>  
                <div className="c:col c:center flex:1">
                        <input 
                        type="radio" 
                        id="web-terms-confirmation" 
                        name="terms-confirmation"
                        onClick={toggleAgreeTerms}
                        checked={agreeTerms}
                        ></input>
                        <p>本人已詳細閱讀及瞭解本網站<a href="/terms" target="_blank">使用條款</a>，亦同意受有關條款及細則所約束</p>
                </div>
                {!isLoading&&<div id="confirm-changes-foot" className="modal-foot c:col c:around p:xs">
                    <Button
                        className="btn step-btn"
                        color="primary"
                        variant="outlined" 
                        onClick={(e)=>closeEditDialog(e)}>
                    取消
                    </Button>
                    <Button
                        disabled={!changedPlanId||!agreeTerms}
                        className="btn step-btn"
                        color="primary"
                        variant="contained" 
                        onClick={confirm}>
                    確認
                    </Button>
                </div>}
            </div>
        </div>
    </div>
    );
}

export default EditSubscription;