import React, {useState, useCallback, useEffect, useContext} from 'react';
import { Redirect, useLocation } from 'react-router';
import { useHistory, Link, Route } from "react-router-dom";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import date from 'date-and-time';
import { v4 as uuidv4} from 'uuid';
import { Formik, Form, Field} from 'formik';
import '../home/Home.css';
import './UserProfile.css';
import { AppBar } from '../home/Home';
import {currentURL} from '../../Config';
// import CreatProj from '../creatProj/CreateProj';
import useOverlay, {OverlayContext} from '../../hooks/useOverlay';
import Loading from '../Loading';
import { FirebaseUserContext } from '../../hooks/Firebase';
import { useAxios } from '../../hooks/useAxios';
import ConfirmChangesDialog from '../createRestaurant/ConfirmChangesDialog';
import {validatePassword} from '../../validators/validator';
import {useSignInWithEmailAndPassword, signOut} from '../../hooks/Firebase';
import { ErrorText } from '../Error';
import ProfileForm from './ProfileForm';



function UserProfile({isUpdated, toggleIsUpdated}){
    const history = useHistory();
    const location = useLocation();
    const userStatus = useContext(FirebaseUserContext);
    const user = userStatus.user;
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogNotification, setDialogNotification] = useState([]);
    const [modal, setModal] = useState(false);
    const toggleModal = useCallback(()=>{setModal((modal)=>!modal)}, []);

    const closeDialog = () => setIsDialogOpen(false);
    const openDialog = () => setIsDialogOpen(true);
    // useEffect(async () => {
    //     if (userStatus.loggedIn) {
    //     setIsLoading(true);
    //     const allContactCasesResponse = await axios.post(currentURL + '/getAllContactCasesByAdminId', {
    //         adminId: user.uid
    //     });
    //     if(allContactCasesResponse.status === 200) setContactCases(allContactCasesResponse.data.cases);
    //     setIsLoading(false);
    //     }
    // }, [isUpdated, userStatus]);
    const [
        signInWithEmailAndPassword,
        reauthUser,
        loading,
        error,
      ] = useSignInWithEmailAndPassword();

    if (!userStatus.loggedIn||isLoading) {
        return(
            <Loading/>
        );
    }
    const style = (editMode) => ({
        editBtn: {
            color: editMode? "#858585": "#555555"
        }
    })
    return (
    <div>
        {/* <Modal 
        signInWithEmailAndPassword={signInWithEmailAndPassword} 
        email={user.email} 
        isOpen={modal} 
        toggleModal={toggleModal}></Modal> */}
        {isDialogOpen&&
        <ConfirmLoginDialog 
        closeDialog={closeDialog} 
        signInWithEmailAndPassword={signInWithEmailAndPassword}
        email={user.email} 
        />}
        <div className="app-root bg:grey">
            <AppBar setIsLoading={setIsLoading}/>
            <div className="app">
                <div style={{height: "50px"}}></div>
                <div className="app-body bg:trans">
                    <div className="mat-card px:sm pt:xs pb:sm">
                            <ProfileForm style={style} user={user} toggleModal={toggleModal} openDialog={openDialog}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

const Modal = ({signInWithEmailAndPassword, email, isOpen, toggleModal}) => {
    const style = {
        display: isOpen?'block':'none'
    };
    return(
        <Formik
        initialValues={{
            email: email,
            password: '',
        }}
        enableReinitialize="true"
        onSubmit= {async (values, { setSubmitting, resetForm }) => {
            setTimeout(async () => {
                
                setSubmitting(true);
                const [user, error] = await signInWithEmailAndPassword(values.email, values.password);
                if(error){
                    alert(error.message);
                }else{
                    alert("You are now reauthorized, please press the save button again!");
                }
                
                setSubmitting(false);
                toggleModal();
                resetForm();
            }, 400)
        }}
        >
        {({errors, touched, values, handleChange, submitForm, isSubmitting, resetForm})=>(
        <Form>      
            <div id="myModal" className="modal" style={style} >
            <div className="modal-content c:row gap:sm">
                <div className="mat-card-head c:col c:between c:middle border:bottom pb:xs">
                        <h5>請輸入你現有的密碼以作確認</h5>
                        <div onClick={()=>{
                            toggleModal();
                            resetForm();
                            }}>
                            <i className="close f:xl material-icons-outlined">
                            close
                            </i>
                        </div>
                </div>
                <div className="form-input-wrap">
                    <label for="password">密碼:</label>
                    <div className="normal-input-field-wrap">
                        <input 
                            className="normal-input-field"
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            id="password" 
                            name="password"
                        />
                        
                    </div>
                </div> 
                <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick = {submitForm}
                >Login</Button>
            </div>
            </div>

        </Form>
        )}
        </Formik>
    );
}

function ConfirmLoginDialog ({signInWithEmailAndPassword, email, closeDialog, style}) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});

    const handlePassword = (e) => {
        setPassword(e.target.value);
        
    };
    const handleBlur = () => {
        const [, passwordError] = validatePassword({password});
        setErrors(passwordError);
    }
    const resetForm = () => setPassword("");
    const confirmLogin = async (e) => {
        setIsLoading(true);
        const [user, error] = await signInWithEmailAndPassword(email, password);
        if(error){
            alert(error.message);
        }else{
            alert("You are now reauthorized, please press the save button again!");
        }
        setIsLoading(false);
    };

    const close = () => {
        closeDialog();
        resetForm();
    }
    return (
    <div>
        
        <div id="confirm-backdrop" className="modal-backdrop confirm-backdrop" style={{...style, display: "block", top: "unset"}}/>
        <div id="confirm-changes" className="modal-container" style={{display: "block", width: "700px"}}>
            <div className="confirm-login-wrap c:row p:sm">
                <h5 className="">請輸入重新登入以作確認</h5>
                <div className="c:row  c:center flex:1">
                    <div className="form-input-wrap">
                        <label for="password">密碼:</label>
                        <div className="normal-input-field-wrap">
                            <input 
                                className="normal-input-field"
                                type="password"
                                disabled={isLoading}
                                value={password}
                                onChange={handlePassword}
                                onBlur={handleBlur}
                                id="password" 
                                name="password"
                            />
                            <div className="normal-input-error">
                            {errors.password && <ErrorText msg={errors.password}/>}
                            </div>
                        </div>
                    </div> 
                </div>
                <div id="confirm-changes-foot" className="modal-foot c:col c:around px:xs">
                    <Button
                        className="btn step-btn"
                        color="primary"
                        variant="outlined" 
                        onClick={close}>
                    取消
                    </Button>
                    <Button
                        className="btn step-btn"
                        color="primary"
                        variant="contained" 
                        disabled={isLoading||Object.keys(errors).length}
                        onClick = {confirmLogin}
                        >
                    登入
                    </Button>
                </div>
            </div>
        </div>
    </div>
    );
}

export default UserProfile;