import './Privacy.css';

function Privacy(){
    return (
        <div className="privacy">
            <h1>隱私權政策</h1>
            <p>
                R Cube App Studio Limited  (以下簡稱 “本公司” 、“我們”) 明白其在《個人資料（隱私）條例》下就個人資料的收集、持有、處理或使用所負有的責任。我們在此說明當您使用本公司其下的網站、軟體及服務時，我們是如何蒐集、使用、處理您的個人資料。
            </p>

            <h2>1. 個人資料的蒐集、使用、處理方式</h2>
            <p>
                當您您使用本公司的網站、軟體及服務時，我們會收集您的個人資料，包括但不限於姓名、聯絡資料、公司、付款資料、瀏覽器資料、IP位址，作以下用途：
                <ol>
                    <li><p>向客戶提供服務；</p></li>
                    <li><p>就您與本公司任何交易直接相關的任何目的而進行使用、披露或轉移；</p></li>
                    <li><p>轉移予任何提供與本公司業務營運及本公司向您提供的產品及服務有關的行政、促銷、電信、電腦、支付、數據處理或其他服務的人士，包括代理商、承包商或第三方服務提供者（無論是位於香港境内或境外的）；</p></li>
                    <li><p>管理和推廣本公司的商業活動；</p></li>
                    <li><p>向香港或其他地方的任何政府部門或其他適當的政府機構或監管機構（例如香港稅務局）進行披露；</p></li>
                    <li><p>協助執法、追討欠款、進行信用報告和評級功能，並保護本公司及其業務合作夥伴和客戶的商業利益；</p></li>
                    <li><p>向本公司所有或部分業務的任何實際或建議購買人提供，或（如進行任何合併、收購或其他公開發售）向本公司的股份購買人或認購人提供；</p></li>
                    <li><p>與上述各項直接相關的其他用途。</p></li>
                </ol>
            </p>
            <h2>2. 資料之保護</h2>
            <p>
                本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。
                <br/><br/>如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
            </p>
            <h2>3. 網站對外的相關連結</h2>
            <p>
                本網站的網頁可能會提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
            </p>
            <h2>4. Cookie之使用</h2>
            <p>
                為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導致網站某些功能無法正常執行 。
            </p>
            <h2>5. 個人資料的查閱</h2>
            <p>
                根據《個人資料（隱私）條例》，您有權查閲本公司持有您的個人資料，獲得該等個人資料的副本，以及改正任何不準確的個人資料。您亦可要求本公司告知您我們所持個人資料的種類。本公司可能會向您收取合理的費用，以抵銷本公司為執行閣下的查閲資料要求而引致的行政和實際費用。
            </p>
            <h2>6. 隱私權政策修正</h2>
            <p>
                本網站隱私權政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。
            </p>
            <h2>聯繫方式</h2>
            <p>
                如果您對本隱私權政策有任何疑問，請聯繫我們：<br/>
                R Cube App Studio Limited<br/>
                電郵：support@rcubestudio.com.hk<br/>
                電話：+852 5662 9356<br/>

                版本日期：2021年10月９日<br/>
            </p>
        </div>
    );
}

export default Privacy;
